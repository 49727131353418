'use strict';

define('vb/private/helpers/restHelperFactory',[
  'vb/private/helpers/rest',
], (RestHelper) => {
  class RestHelperFactory {
    /**
     * This is (currently) a PRIVATE factory for internal use
     *
     * Creates a new Rest helper instance.
     *
     * the 'endpointReference' is ONE of:
     * - a string, the endpoint ID (openapi3 'operationId'), which should be contained in the service
     * - on object with 'url' and 'operationRef'
     *
     * @param endpointReference {string|{url: {string}, operationRef: {string}}}
     * @param {Container|ServiceContext} [serviceContext]
     * @returns {Rest}
     */
    static get(endpointReference, serviceContext) {
      // eslint-disable-next-line no-underscore-dangle
      return RestHelperFactory._createRestHelper(RestHelperFactory.getRestHelperModule(),
        endpointReference, serviceContext);
    }

    /**
     * @param restHelperModule
     * @param endpointReference
     * @param {Container|ServiceContext} [serviceContext]
     * @returns {RestHelper}
     * @private
     */
    static _createRestHelper(restHelperModule, endpointReference, serviceContext) {
      const RestHelperModule = restHelperModule;

      // if we have a url/operation ref from JET, use operationRefEndpointProvider...
      if (endpointReference && endpointReference.url && endpointReference.operationRef) {
        // this is not currently a publicly available RestHelper
        return new RestHelperModule(endpointReference,
          'vb/private/services/operationRefEndpointProvider',
          serviceContext);
      }

      // ...otherwise, return the public helper
      return new RestHelperModule(endpointReference, 'vb/private/services/servicesManager', serviceContext);
    }

    /**
     * Returns the RestHelper module to use.
     * @returns {*}
     */
    static getRestHelperModule() {
      return RestHelper;
    }
  }
  return RestHelperFactory;
});

