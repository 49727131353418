'use strict';

define('vb/private/stateManagement/bridgeFactory',[
  'vb/private/stateManagement/fragmentBridge',
  'vb/private/stateManagement/switcherBridge',
  'vb/private/constants',
], (FragmentBridge, SwitcherBridge, Constants) => {
  /**
   *
   */
  class BridgeFactory {
    constructor(container) {
      this.create = (type, props) => {
        switch (type) {
          case Constants.ComponentBridgeType.FRAGMENT: {
            return FragmentBridge.getOrCreateBridge(container, props);
          }

          case Constants.ComponentBridgeType.SWITCHER: {
            return new SwitcherBridge(container, props);
          }

          case Constants.ComponentBridgeType.FRAGMENT_SLOT: {
            return FragmentBridge.getOrCreateBridge(container, Object.assign({ type: 'fragment-slot' }, props));
          }

          default:
            throw new Error('Missing or invalid bridge type');
        }
      };
    }
  }

  return BridgeFactory;
});

