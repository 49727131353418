'use strict';

define('vb/private/stateManagement/context/pageExtensionContext',[
  'vb/private/constants',
  'vb/private/stateManagement/context/containerExtensionContext',
  'vb/private/stateManagement/context/pageBaseContext',
], (Constants, ContainerExtensionContext, PageBaseContext) => {
  /**
   * Set of properties to expose in page extension container
   */
  class PageExtensionContext extends ContainerExtensionContext {
    /**
     * BaseContextType property: The type of the baseContext object used for $base in expression.
     * This is used by super class ContainerContext to construct the baseContext object.
     * @type {PageBaseContext}
     */
    static get BaseContextType() {
      return PageBaseContext;
    }

    /**
     * see ContainerContext
     *
     * @param {Object} extension
     * @returns {Object}
     */
    static getAvailableContexts(extension) {
      // This part populate $variables, $constants, etc...
      const availableContexts = super.getAvailableContexts(extension);

      // Remove whatever part of the context that we don't want to expose here
      // delete availableContexts.<object>

      Object.defineProperties(availableContexts, {
        $page: {
          enumerable: true,
          configurable: true,
          get: () => extension.expressionContext,
        },
        $flow: {
          enumerable: true,
          configurable: true,
          // Retrieve the flow extension with the same extension id
          get: () => {
            const extensionFlow = extension.base.parent.extensions[extension.extensionId];
            return extensionFlow && extensionFlow.expressionContext;
          },
        },
      });

      return availableContexts;
    }
  }

  return PageExtensionContext;
});

