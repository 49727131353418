'use strict';

define('vb/extensions/dynamic/private/models/dynamicContainerExtension',[
  'vb/extensions/dynamic/private/models/layoutResourceExtension',
], (LayoutResourceExtension) => {
  /**
   * DynamicContainerExtension is used to wrap a page or fragment extension. Its purpose is to
   * load the -template-x.html file and extract view model, functions and descriptor from the
   * wrapped container extension.
   */
  class DynamicContainerExtension extends LayoutResourceExtension {
    /**
     * DynamicContainerExtension constructor
     *
     * @param extension the extension from which to load the resource
     * @param {String} path the absolute path to the layout resource, e.g., vb/extA/dynamicLayouts/self/employee
     * @param base the base layout resource
     * @param resourceDescriptor resource descriptor
     * @param className the class name
     */
    constructor(extension, path, base, resourceDescriptor, className = 'DynamicContainerExtension') {
      super(extension, path, base, className);

      this.resourceDescriptor = resourceDescriptor;
      this.container = resourceDescriptor.container;
    }

    /**
     * Get resourceLoc from the wrapped container.
     *
     * @returns {String}
     */
    get resourceLoc() {
      return this.container.resourceLoc;
    }

    /**
     * Get the view model from the wrapped container and load -template-x.html.
     *
     * @returns {Promise}
     */
    getViewModel() {
      if (!this.viewModelPromise) {
        // only need to load -template-x since everything else is already loaded
        this.viewModelPromise = Promise.all([
          this.container.getViewModel(), this.loadTemplate(),
        ])
          .then(([viewModel]) => {
            this.viewModel = viewModel;
            this.functions = this.container.functions;

            // extract descriptor by stringifying container.definition
            const definition = Object.assign({}, this.container.definition);
            delete definition.interface; // sanitize
            this.descriptor = JSON.stringify(definition);

            return viewModel;
          });
      }
      return this.viewModelPromise;
    }

    dispose() {
      // Since we cannot dispose the view model for the underlying page/fragment container, clear
      // the viewModelPromise before calling super.dispose.
      this.viewModelPromise = null;

      super.dispose();
    }
  }

  return DynamicContainerExtension;
});

