'use strict';

define('vb/private/stateManagement/switcherElement',[
  'vb/helpers/mixin',
  'vb/private/stateManagement/switcherRootMixin',
  'vb/private/stateManagement/navigationContext',
  'vb/private/history',
  'vb/private/stateManagement/routerUtils',
  'vb/private/utils',
  'vb/private/constants',
], (Mixin, SwitcherRootMixin, NavigationContext, History, RouterUtils, Utils, Constants) => {
  /**
   * Name of the variable used to store element info in the browser history
   *
   * @type {string}
   */
  const HISTORY_VARIABLE_NAME = 'switcherElt';

  /**
   * A class representing the info of a switcher element
   *
   */
  class SwitcherElement {
    constructor(data, switcher) {
      if (Utils.isObject(data)) {
        this.id = Utils.cleanString(data.id);
        this.name = Utils.cleanString(data.name);
        this.page = Utils.cleanString(data.page);
        this.flow = Utils.cleanString(data.flow);
        this.application = Utils.cleanString(data.application);
        this.params = data.params;
      }

      if (!this.id && (!this.flow || !this.application)) {
        throw new Error('Switcher element requires id and flow properties to be defined.');
      }

      // The type of page could be a Package page
      const pageType = switcher.parent.getParentFlow().constructor.PageClass;

      // Creates the root page of the switcher element
      this.root = new (Mixin(pageType).with(SwitcherRootMixin))(this, switcher);
    }

    /**
     * Retrieve the switcher element info persisted on the browser history
     *
     * @return {Object}  The switcher element info.
     */
    static getFromHistory() {
      return History.retrieveFromState(HISTORY_VARIABLE_NAME) || {};
    }

    /**
     * Initializes the given switcher element by navigating the root page
     *
     * @param  {boolean} replace
     * @return {Promise} A promise that resolve with the result of the navigate operation
     */
    initialize(replace = false) {
      const navParam = {
        operation: this.application ? Constants.NavigateOperation.APP_UI : Constants.NavigateOperation.FLOW,
        application: this.application,
        flow: this.flow,
        page: this.page,
        params: this.params,
        history: replace ? Constants.HistoryMode.REPLACE : undefined,
      };

      return this.root.navigateOperation(navParam, new NavigationContext(this.root, navParam));
    }

    getCurrentPage() {
      return RouterUtils.getCurrentPage(this.root.rootRouter);
    }

    storeInHistory() {
      if (this.root) {
        History.storeInState(HISTORY_VARIABLE_NAME, {
          flow: this.flow,
          page: this.page,
          id: this.id,
          name: this.name,
          routerName: this.root.rootRouter.name,
        });
      }
    }

    dispose() {
      if (this.root) {
        this.root.dispose();
        this.root = null;
      }
    }
  }

  return SwitcherElement;
});

