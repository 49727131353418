'use strict';

define('vb/private/stateManagement/context/appPackageBaseContext',[
  'vb/private/stateManagement/context/flowBaseContext',
  'vb/private/constants'],
(FlowBaseContext, Constants) => {
  /**
   * set of properties to expose in $base for App UI extension
   */
  class AppPackageBaseContext extends FlowBaseContext {
    constructor(appPackage) {
      super(appPackage);

      const appPackageContext = appPackage.expressionContext;

      const propDescriptors = {};

      /**
       * $application.info and $application.path are not defined here because
       * there are already defined in the super class FlowBaseContext
       */
      [Constants.RESPONSIVE_CONTEXT,
        Constants.APPLICATION_USER_VARIABLE,
        Constants.INIT_PARAM_CONTEXT,
        Constants.CURRENT_PAGE_VARIABLE,
        Constants.DEPLOYMENT_CONSTANT,
      ].forEach((property) => {
        propDescriptors[property] = {
          get() {
            return appPackageContext[property];
          },
          enumerable: true,
          configurable: true,
        };
      });

      Object.defineProperties(this, propDescriptors);
    }
  }

  return AppPackageBaseContext;
});

