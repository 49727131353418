'use strict';

define('vb/extensions/dynamic/private/models/context/layoutResourceExtensionContext',['vb/private/stateManagement/context/containerExtensionContext'],
  (ContainerExtensionContext) => {
    /**
     * Context object for a LayoutResourceExtension.
     */
    class LayoutResourceExtensionContext extends ContainerExtensionContext {
      /**
       * @param layoutResourceExtension
       * @returns {Object}
       */
      static getAvailableContexts(layoutResourceExtension) {
        const availableContexts = super.getAvailableContexts(layoutResourceExtension);
        const filteredContexts = {};

        ['$functions', '$modules', '$translations'].forEach((key) => {
          const propDesc = Object.getOwnPropertyDescriptor(availableContexts, key);

          Object.defineProperty(filteredContexts, key, propDesc);
        });

        return filteredContexts;
      }
    }

    return LayoutResourceExtensionContext;
  });

