/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */

'use strict';

define('vb/private/translations/bundleProxies',[
  'vb/private/log',
], (
  Log,
) => {
  const logger = Log.getLogger('/vb/private/translations/bundleProxies');

  /**
   * Proxy Handler for placeholder properties.
   */
  class BundleHandler {
    constructor(name) {
      this._name = name;
      this._value = {};
      this._loaded = false;
    }

    /**
     * Get a proxied value for the property
     * @param {object} target map of key/value
     * @param {string} property the key whose value to retrieve
     * @param {object} receiver the BundlePropertyHandler
     * @returns
     */
    // eslint-disable-next-line no-unused-vars
    get(target, property, receiver) {
      if (this._loaded) {
        logger.warn(this._name, '.get(', property, ') accessed after bundle has been loaded.',
          'This likely means that a reference to the proxied bundle has been saved.');
      } else {
        logger.info(this._name, '.get(', property, ')');
      }

      const special = this._get(target, property);
      if (special) {
        return special.value;
      }

      // Skip anything already defined
      if (Reflect.has(this._value, property)) {
        logger.info('\tskipping property');
        return Reflect.get(this._value, property);
      }

      // Just pass through the Jasmine 'properties' that are requested on values being tested
      // Also knockout 'properties'
      switch (property) {
        case 'asymmetricMatch': // 'Jasmine requested'
        case 'jasmineToString': // 'Jasmine requested'
        case '__ko_proto__': // 'Knockout requested'
          logger.finer('\tskipping property');
          return Reflect.get(target, property);

        default:
          break;
      }

      // Create a Property Proxy for any property not yet encountered
      if (!this._value[property] && !this._loaded) {
        this._value[property] = this._createPropertyProxy(property);
      }

      // Return the property proxy/value.
      return this._value[property];
    }

    /**
     * Load this proxy with the actual values.
     *
     * @param {Object} value the actual bundle map
     */
    load(value) {
      this._value = value;
      this._loaded = true;
    }

    /**
     * Return Bundle Property special cases
     * @param {object} target map of key/value
     * @param {string} propertyName the key whose value to retrieve
     * @returns {object | undefined} with .value property or undefined
     */
    // eslint-disable-next-line no-unused-vars
    _get(target, propertyName) {
      return undefined;
    }

    /**
     * Create a proxy for the property.
     * @param {string} propertyName the key to proxy
     * @returns
     */
    // eslint-disable-next-line no-unused-vars
    _createPropertyProxy(propertyName) {
      throw Error('_createPropertyProxy is not implemented');
    }
  }

  /**
   * V1 Bundle Handler
   * V1 Bundles are nested maps of key to [string | map]
   */
  class BundleV1Handler extends BundleHandler {
    _createPropertyProxy(propertyName) {
      logger.info('\tcreating v1 property proxy');

      // eslint-disable-next-line no-use-before-define
      const propertyProxy = new Proxy({}, new BundleV1PropertyHandler(propertyName));
      return propertyProxy;
    }
  }

  const PLACEHOLDER_STRING = '';
  // eslint-disable-next-line no-new-wrappers
  const SUBSTITUTE_TARGET = new String(PLACEHOLDER_STRING);

  /**
   * Proxy Handler for a Bundle property to return a placeholder any subproperties.
   */
  class BundleV1PropertyHandler extends BundleV1Handler {
    /**
     * Return Bundle Property special cases
     * @param {object} target map of key/value
     * @param {string} propertyName the key whose value to retrieve
     * @returns {object | undefined} with .value property or undefined
     */
    _get(target, propertyName) {
      if (typeof propertyName === 'symbol') {
        // Intercept toPrimitive, and return the placeholder
        if (propertyName === Symbol.toPrimitive) {
          logger.info('\tintercepting symbol');
          return (hint) => ((hint === 'number') ? NaN : PLACEHOLDER_STRING);
        }

        logger.info('\tskipping symbol');
        return { value: Reflect.get(target, propertyName) };
      }

      // Intercept String methods and properties
      // This is to make sure conversion/coercion works.  e.g.
      //  String(bundle.property)
      //  '' + bundle.property
      //  bundle.property.toString()
      if (Reflect.has(SUBSTITUTE_TARGET, propertyName)) {
        const propertyValue = Reflect.get(SUBSTITUTE_TARGET, propertyName);
        const propertyType = typeof propertyValue;

        logger.info('\tsubstituting String property type', propertyType);
        if (propertyType === 'function') {
          return { value: propertyValue.bind(SUBSTITUTE_TARGET) };
        }
        return { value: propertyValue };
      }

      // Intercept indices
      if (Number.isInteger(Number.parseInt(propertyName, 10))) {
        logger.info('\tintercepting index');
        return { value: PLACEHOLDER_STRING[0] };
      }

      return undefined;
    }
  }

  /**
   * V2 Bundle Handler.
   * V2 Bundles are a flat map of key to function.
   */
  class BundleV2Handler extends BundleHandler {
    // eslint-disable-next-line no-unused-vars
    _createPropertyProxy(propertyName) {
      logger.info('\tcreating v2 property proxy');

      // Function that returns the placeholder string
      const propertyProxy = () => PLACEHOLDER_STRING;
      return propertyProxy;
    }
  }

  function createBundleProxy(handler) {
    const bundleProxy = {
      handler,
      value: new Proxy({}, handler),

      load(value) {
        // Update the Bundle Proxy Handler with the actual value
        this.handler.load(value);
        // Use the actual value from now on
        this.value = value;
        return { value };
      },
    };
    return bundleProxy;
  }

  return {
    PLACEHOLDER_STRING,
    createBundleV1Proxy: (bundleName) => createBundleProxy(new BundleV1Handler(bundleName)),
    createBundleV2Proxy: (bundleName) => createBundleProxy(new BundleV2Handler(bundleName)),
  };
});

