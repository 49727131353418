'use strict';

define('vb/private/stateManagement/context/layoutExtensionContext',[
  'vb/private/stateManagement/context/containerExtensionContext',
  'vb/private/stateManagement/context/layoutBaseContext',
], (ContainerExtensionContext, LayoutBaseContext) => {
  /**
   * Set of properties to expose in layout extension container
   */
  class LayoutExtensionContext extends ContainerExtensionContext {
    /**
     * BaseContextType property: The type of the baseContext object used for $base in expression.
     * This is used by super class ContainerContext to construct the baseContext object.
     */
    static get BaseContextType() {
      return LayoutBaseContext;
    }

    /**
     * see ContainerExtensionContext
     *
     * @param extensionContainer
     * @returns {Object}
     */
    static getAvailableContexts(extensionContainer) {
      const availableContexts = super.getAvailableContexts(extensionContainer);

      // Remove properties added by container context that should not be part of the
      // layout context.
      delete availableContexts.$application;
      delete availableContexts.$metadata;

      Object.defineProperties(availableContexts, {
        // $layout.variables is an alias of $variables
        $layout: {
          enumerable: true,
          configurable: true,
          get() {
            return extensionContainer.expressionContext;
          },
        },
      });

      return availableContexts;
    }
  }

  return LayoutExtensionContext;
});

