'use strict';

define('vb/services/transforms/serviceTransforms',[], () => {
  /**
   * Metadata transforms pertaining to a service or endpoint and not tied to a request. Example, this object can be
   * used to get the capabilities supported by the service. The service implementation can choose which if the
   * capabilities it supports.
   *
   * @type {{capabilities: (function(*): {})}}
   */
  const MetadataTransforms = (() => {
    /**
     * Default capabilities returned by the service specific transforms. The list includes both the JET DataProvider
     * capabilities and the VB specific ones. Service authors must ensure that for each capability, the actual level
     * of support is determined and the appropriate value returned. For the values for each capability and its
     * properties, refer to the VB docs and JET Data Provider API
     * https://jet.oraclecorp.com/trunk/jsdocs/DataProvider.html#getCapability
     */
    const DEFAULT_CAPS = {
      // see JET DedupCapability
      dedup: {
        type: 'none', // ('global'|'none'|'iterator')
      },
      // see JET EventFilteringCapability
      eventFiltering: {
        type: 'none', // ('global'|'none'|'iterator')
      },
      // see JET FetchByKeysCapability
      fetchByKeys: {
        attributeFilter: null, // (nullable|AttributeFilterCapability)
        caching: 'none', // (nullable|'all'|'none'|'visitedByCurrentIterator')
        multiKeyLookup: 'no', // See VB docs.
        implementation: 'lookup', // ('iteration'|'lookup'|'batchLookup')
      },
      // see JET FetchByOffsetCapability
      fetchByOffset: {
        attributeFilter: null, // (nullable|AttributeFilterCapability)
        caching: 'none', // (nullable|'all'|'none'|'visitedByCurrentIterator')
        implementation: 'randomAccess', // ('iteration'|'randomAccess')
        totalFilteredRowCount: null, // (nullable|'exact'|'none')
      },
      // see JET FetchFirstCapability
      fetchFirst: {
        attributeFilter: null, // (nullable|AttributeFilterCapability)
        caching: 'none', // (nullable|'all'|'none'|'visitedByCurrentIterator')
        implementation: 'iteration', // see VB docs
        iterationSpeed: 'delayed', // ('immediate'|'delayed')
        totalFilteredRowCount: null, // (nullable|'exact'|'none')
      },
      // see JET FilterCapability
      filter: {
        attributeExpression: null, // (nullable|Array.<AttributeFilterDef.AttributeExpression>)
        attributes: [], // see BOSS docs
        collationOptions: null, // (nullable|{sensitivity?: Array.<'base' | 'accent' | 'case' | 'variant'>})
        nestedFilter: null, // (nullable|any)
        // eslint-disable-next-line max-len
        operators: null, // (nullable|Array.<(AttributeFilterDef.AttributeOperator|CompoundFilterDef.CompoundOperator|NestedFilterDef.NestedOperator)>)
        textFilter: false, // (nullable|any)
        textFilterMatching: null, // (nullable|{matchBy?: Array.<'phrase' | 'startsWith' | 'contains' | 'fuzzy'>})
      },
      sort: { // see JET SortCapability
        attributes: 'none', // ('none'|'single'|'multiple')
      },
    };

    const DEFAULT_REQS = {
      // transforms implementation requires responses metadata to be loaded
      usesResponsesMetadata: false,
    };

    /**
     * Returns the capabilities of the service. the structure is loosely based on the JET DataProvider API.
     * @param configuration
     * @return {Object}
     * @see https://www.oracle.com/webfolder/technetwork/jet/jsdocs/DataProvider.html#getCapability
     */
    return {
      /**
       * capabilities function returns the list of capabilities supported by the service.
       * @param configuration
       * @returns {object|DEFAULT_CAPS}
       */
      // eslint-disable-next-line no-unused-vars
      capabilities: (configuration) => DEFAULT_CAPS,

      /**
       * requirements function returns information describing what transforms expect from the RT environment
       * @param configuration
       * @returns {object|DEFAULT_REQS}
       */
      // eslint-disable-next-line no-unused-vars
      requirements: (configuration) => DEFAULT_REQS,
    };
  })();

  /**
   * Returns the list of request transform functions that the service specific transforms implements. The supported
   * list of transforms functions are body, fetchByKeys, filter, paginate, query, sort, select and vbPrepare.
   * @type {Object}
   */
  const RequestTransforms = (() => {
    /**
     * Signature for the request transform function. Example, filter, sort etc.
     * @param {Object} configuration for the service endpoint used in the current fetch request. A Map containing
     * the following properties
     * @param {Object} configuration.endpointDefinition: endpoint metadata
     * @param {Object} configuration.fetchConfiguration: the configuration that triggered this fetch call. If fetch
     * was initiated by SDP this includes (fetch) capability, context, externalContext and fetchParameters properties.
     * @param {Object} configuration.initConfig: may include the body property
     * @param {Object} configuration.parameters: parameters appended to url
     * @param {Object} configuration.url: url for the fetch
     * @param {Object|Array|*} options the options specific to the particular transform. Example,
     * - filter transform function will receive the filter criterion used in the current fetch. The structure and
     * properties of the options is loosely defined by TransformsApi.filterCriterion.
     * - sort transform function will receive the sort criteria used in the current fetch. The structure and
     * properties of the options is loosely defined by TransformsApi.sortCriteria.
     * - For the structure of the options provided to the other transforms function refer to the transforms docs.
     * @param transformsContext a transforms context object that can be used by authors of transform
     * functions to store contextual information for the duration of the request.
     */
    // eslint-disable-next-line no-unused-vars
    const transformFunc = (configuration, options, transformsContext) => configuration;
    return {
      body: transformFunc,
      fetchByKeys: transformFunc,
      filter: transformFunc,
      paginate: transformFunc,
      query: transformFunc,
      select: transformFunc,
      sort: transformFunc,
      vbPrepare: transformFunc,
    };
  })();

  /**
   * Returns the list of transforms functions that the service specific transforms implements. The supported list of
   * transforms functions are fetchByKeys, filter, paginate, sort, select.
   * @type {Object} with the body and paginate properties with the related response transform callback.
   */
  const ResponseTransforms = (() => {
    /**
     * Signature for the response transform function. Example, paginate, body.
     * @param {Object} configuration - a Map containing the following properties
     * @param {Object} configuration.headers: response header
     * @param {Object} configuration.body: body of the response
     * @param {Object} configuration.fetchConfiguration: the configuration that triggered this fetch call. If fetch was
     * initiated by SDP this includes fetch capability, context, externalContext and fetchParameters property.
     * @param transformsContext transforms context
     * @return {Object} only the 'body' response transform can return a fixed up response. The paginate
     * response transform function returns an Object with hasMore and totalResults properties.
     */
    // eslint-disable-next-line no-unused-vars
    const transformFunc = (configuration, transformsContext) => configuration;
    return {
      body: transformFunc,
      paginate: transformFunc,
    };
  })();

  /**
   * Mark the functions as a built-in by attaching 'doesQueryEncoding' property to them. Builtin transforms
   * functions must support query encoding
   *
   * @param functionMap an object representing the transformation functions
   */
  function tagFunctionsAsBuiltIn(functionMap) {
    const fnMap = functionMap;
    Object.keys(fnMap || {}).forEach((key) => { fnMap[key].doesQueryEncoding = true; });
  }

  tagFunctionsAsBuiltIn(RequestTransforms);
  tagFunctionsAsBuiltIn(ResponseTransforms);
  tagFunctionsAsBuiltIn(MetadataTransforms);

  /** Defines the interface for a service specific transform implementation. Example, a boss or a business object
   * based service must implement / duck type this interface.
   * @type {{}}
   */
  return {
    request: RequestTransforms,
    response: ResponseTransforms,
    metadata: MetadataTransforms,
  };
});

