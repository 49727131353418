'use strict';

define('vb/extensions/dynamic/private/models/serviceMetadata',[
  'vb/extensions/dynamic/private/models/layoutResource',
  'vb/private/services/serviceConstants',
], (LayoutResource, ServiceConstants) => {
  /**
   * ServiceMetadata is used to process imports in the info.x-vb section of the service metadata.
   */
  class ServiceMetadata extends LayoutResource {
    /**
     * ServiceMetadata constructor
     *
     * @param container the container that contains this layout resource
     * @param extension the extension from which to load the resource
     * @param {String} path the absolute path to the layout resource, e.g., vb/extA/dynamicLayouts/self/employee
     * @param {Object} resourceDescriptor resource descriptor
     * @param {String} className the class name
     */
    constructor(container, extension, path, resourceDescriptor, className = 'ServiceMetadata') {
      super(container, extension, path, resourceDescriptor, className);
    }

    /**
     * Overridden to return a descriptor containing imports statement extracted from the original service
     * metadata.
     *
     * @param resourceLocator
     * @returns {Promise}
     */
    // eslint-disable-next-line no-unused-vars
    descriptorLoader(resourceLocator) {
      return Promise.resolve().then(() => {
        // set descriptor to the original metadata
        this.descriptor = this.resourceDescriptor.metadata;

        // extract imports from info.x-vb
        const info = this.descriptor.info || {};
        const vbExtensions = info[ServiceConstants.VB_EXTENSIONS] || {};

        return { imports: vbExtensions.imports };
      });
    }
  }

  return ServiceMetadata;
});

