'use strict';

define('vb/private/stateManagement/context/containerExtensionContext',[
  'vb/private/stateManagement/context/containerContext',
  'vb/private/constants',
  'vb/private/translations/translationContext',
], (ContainerContext, Constants, TranslationContext) => {
  // A map to keep ContainerExtensionContext properties hidden
  const privates = new WeakMap();

  const builtinProperties = [
    Constants.APPLICATION_USER_VARIABLE,
    'builtinUtils',
    Constants.RESPONSIVE_CONTEXT,
    Constants.PROFILE_CONSTANT,
    Constants.DEPLOYMENT_CONSTANT,
    Constants.INIT_PARAM_CONTEXT,
    Constants.CURRENT_PAGE_VARIABLE,
    Constants.INFO_CONTEXT,
  ];

  /**
   * Set of context properties common for pageExtension and flowExtension
   */
  class ContainerExtensionContext extends ContainerContext {
    /**
     * @param {Object} extensionContainer the extension container
     */
    constructor(extensionContainer) {
      super(extensionContainer);

      privates.set(this, {
        /**
         * extension property
         * The object returned when an expression has $extension (See $extension property in getAvailableContexts)
         * @type {Object}
         */
        get extension() {
          const extensionExpressionContext = {
            base: extensionContainer.base.expressionContext.baseContext,
            // When the expressionContext is created, absoluteUrl can be resolved, so just get the value immediately
            [Constants.PATH_VARIABLE]: extensionContainer.absoluteUrl,
          };

          // $extension.<extId>.translations
          if (extensionContainer.bundles) {
            TranslationContext.addExtensionsContexts(extensionContainer.bundles, extensionExpressionContext);
          }

          // and redefine the property as a value for immediate access on the next get
          Object.defineProperty(this, 'extension', {
            value: extensionExpressionContext,
          });

          return extensionExpressionContext;
        },
        // accessor for the app ui context which is only available if accessed from within an app ui
        get appUi() {
          let appUiExpressionContext = null;

          const appUi = extensionContainer.base.package;

          if (appUi) {
            // need to initialize the context even if there is no app-x so it doesn't switch over to
            // the global context
            appUiExpressionContext = {};

            const extensionAppUi = appUi.extensions[extensionContainer.extensionId];
            if (extensionAppUi) {
              const extensionExpressionContext = extensionAppUi.expressionContext;

              // expose the following properties
              [Constants.VariableNamespace.VARIABLES,
                Constants.VariableNamespace.CONSTANTS,
                'enums',
                'events',
                'functions',
                'modules'].forEach((scope) => {
                Object.defineProperty(appUiExpressionContext, scope, {
                  get() {
                    return extensionExpressionContext[scope];
                  },
                });
              });

              appUiExpressionContext.getVariable = (...args) => extensionExpressionContext.getVariable(...args);

              // $application.path is the path to resources inside the appUi extension
              Object.defineProperty(appUiExpressionContext, Constants.PATH_VARIABLE, {
                value: `${extensionAppUi.extension.baseUrlDef}/${extensionAppUi.resourceLoc}`,
                enumerable: true,
                configurable: true,
              });
            }

            // Propagate all builtins properties from the application base context
            // This need to be done even when the App UI is not extended
            const appUiBaseContext = appUi.expressionContext.baseContext;
            builtinProperties.forEach((name) => {
              Object.defineProperty(appUiExpressionContext, name, {
                get() {
                  // Builtin are not extendable, so directly retrieve the value from the application base object
                  return appUiBaseContext[name];
                },
              });
            });
          }

          // redefine the property as a value for immediate access on the next get
          Object.defineProperty(this, 'appUi', {
            value: appUiExpressionContext,
          });

          return appUiExpressionContext;
        },
      });

      const propDescriptors = {
        // accessor for the base application context
        // or accessor for the app ui context which is only available if accessed from within an app ui
        application: {
          get() {
            // need to initialize the context even if there is no app-x so it doesn't switch over to
            // the global context
            const appExpressionContext = {};

            const extensionApplication = extensionContainer.base.application.extensions[extensionContainer.extensionId];
            if (extensionApplication) {
              // For every extension container, the application object is a combination of the properties
              // in the application extension and the properties exposed in the base application interface.
              // Note that true is passed into createExpressionContextFromExtension to merged in the properties
              // from the base application.
              const extensionAppExpressionContext = extensionApplication.expressionContext;
              const baseExpressionContext = extensionAppExpressionContext.base;

              // variables, constants, enums, and events are the only properties in interface
              [Constants.VariableNamespace.VARIABLES,
                Constants.VariableNamespace.CONSTANTS, 'enums', 'events'].forEach((scope) => {
                let mergedScope;

                // merge in the properties from the base context
                Object.defineProperty(appExpressionContext, scope, {
                  get() {
                    // only create the mergedScope when it's first accessed to avoid accessing
                    // property descriptors too early
                    if (!mergedScope) {
                      mergedScope = {};
                      let ctx = extensionAppExpressionContext[scope] || [];
                      Object.keys(ctx).forEach((name) => {
                        const def = Object.getOwnPropertyDescriptor(ctx, name);
                        Object.defineProperty(mergedScope, name, def);
                      });

                      ctx = baseExpressionContext[scope] || [];
                      Object.keys(ctx).forEach((name) => {
                        const def = Object.getOwnPropertyDescriptor(ctx, name);
                        Object.defineProperty(mergedScope, name, def);
                      });
                    }
                    return mergedScope;
                  },
                });
              });

              // look up variables from extension first and then base extension
              appExpressionContext.getVariable = (...args) => extensionAppExpressionContext.getVariable(...args)
                  || baseExpressionContext.getVariable(...args);

              // expose functions defined in app ui extension
              // RESOLVE: Functions should not be exposed on $global. However, it breaks V1 extension
              // if we don't expose it here.
              Object.defineProperty(appExpressionContext, 'functions', {
                get() {
                  return extensionAppExpressionContext.functions;
                },
              });

              // Propagate all builtins properties from the application context
              builtinProperties.forEach((name) => {
                Object.defineProperty(appExpressionContext, name, {
                  get() {
                    // Builtin are not extendable, so directly retrieve the value from the application object
                    return extensionContainer.application.expressionContext[name];
                  },
                });
              });

              Object.defineProperty(appExpressionContext, Constants.PATH_VARIABLE, {
                value: `${extensionContainer.absoluteUrl}${extensionContainer.application.path}`,
                enumerable: true,
                configurable: true,
              });
            }

            // and redefine the property as a value for immediate access on the next get
            Object.defineProperty(this, 'application', {
              value: appExpressionContext,
              enumerable: true,
              configurable: true,
            });

            return appExpressionContext;
          },
          enumerable: true,
          configurable: true,
        },
        /**
         * base property
         * The object returned when an expression has $base (See $base property in getAvailableContexts)
         */
        base: {
          get() {
            return extensionContainer.base.expressionContext.baseContext;
          },
          enumerable: true,
          configurable: true,
        },
      };

      Object.defineProperties(this, propDescriptors);
    }

    dispose() {
      privates.delete(this);
      super.dispose();
    }

    /**
     * BaseContextType property: The type of the baseContext object used for $base in expression.
     * This is used by super class ContainerContext to construct the baseContext object.
     *
     * Implemented by subclass pageExtensionContext and flowExtensionContext
     */
    static get BaseContextType() {
      throw new Error('Base context constructor undefined');
    }

    /**
     * see ContainerContext
     *
     * @param extensionContainer
     * @returns {Object}
     */
    static getAvailableContexts(extensionContainer) {
      // This part populate $variables, $constants, etc...
      const availableContexts = super.getAvailableContexts(extensionContainer);

      const { expressionContext } = extensionContainer;

      Object.defineProperties(availableContexts, {
        $application: {
          enumerable: true,
          configurable: true,
          get() {
            // appUi is only available if accessed from within an app ui
            return privates.get(expressionContext).appUi || expressionContext.application;
          },
        },
        $extension: {
          enumerable: true,
          configurable: true,
          get() {
            return privates.get(expressionContext).extension;
          },
        },
        $base: {
          enumerable: true,
          configurable: true,
          get() {
            return expressionContext.base;
          },
        },
      });

      return availableContexts;
    }
  }

  return ContainerExtensionContext;
});

