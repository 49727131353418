'use strict';

/**
 * Base class for providing security information for the application.
 */
define('vb/types/securityProvider',['ojs/ojconfig', 'vbsw/private/serviceWorkerManager', 'vb/private/stateManagement/application',
  'vb/helpers/navigate', 'urijs/URI', 'vb/private/configuration', 'vbsw/private/constants', 'vb/helpers/bootstrapRest',
  'vb/private/constants', 'vb/private/utils',
], (ojConfig, ServiceWorkerManager, Application, Navigate, URI, Configuration, SwConstants, BootstrapRest,
  Constants, Utils) => {
  class SecurityProvider {
    constructor() {
      globalThis.vbInitConfig = globalThis.vbInitConfig || {};

      // The name of the query parameter used for the returnPath in the loginUrl
      this.returnPathQueryParam = 'endUri';

      // default the userInfo
      this.userInfo = {
        userId: Constants.User.DEFAULT,
        username: Constants.User.DEFAULT,
        longId: Constants.User.DEFAULT,
        fullName: Constants.User.DEFAULT,
        email: Constants.User.DEFAULT,
        roles: [],
        permissions: [],
        isAdmin: false,
        isAuthenticated: false,
      };

      // install this security provider as a message handler to handle messages posted from the
      // service worker and its plugins
      ServiceWorkerManager.getInstance().installMessageHandler(this);
    }

    /**
     * Populate this instance with security information that matches the type returned from {@link getType} using the
     * given config which is the configuration object specified in the userConfig definition in the application
     * descriptor.
     *
     * @param config the configuration object from the userConfig definition in the application descriptor
     * @returns {Promise}
     */
    initialize(config) {
      this.config = config;

      // set a global property to bypass endpoint proxy which is true by default unless
      // userConfig.configuration.disableTokenRelay is set to true
      // TODO: find a better way to pass this flag or it may not matter once we turn off proxy altogether
      globalThis.vbInitConfig.bypassProxy = config ? !config.disableTokenRelay : true;

      const vbConfig = globalThis.vbInitConfig;
      // get the appPath that will be used for the login return path
      const appPath = vbConfig && vbConfig.APP_PATH;
      if (typeof appPath === 'string') {
        this.appPath = appPath.trim();
      } else {
        // if APP_PATH is not on vbInitConfig, default to the ApplicationUrl
        this.appPath = Configuration.applicationUrl;
      }

      // Always terminate with a '/'
      this.appPath = Utils.addTrailingSlash(this.appPath);

      const { isAuthenticated } = this.userInfo;

      // we need to install the service worker plugins first since the current user request needs to be
      // processed by the offline handler and then the plugins
      return this.getServiceWorkerPlugins(config, !isAuthenticated)
        .then((plugins) => this.installServiceWorkerPlugins(plugins))
        .then(() => this.fetchCurrentUser(config))
        .then(() => {
          // reinstall the plugins if the authenticated status has changed but set reload to false so
          // only those plugins whose metadata has changed are reloaded
          if (isAuthenticated !== this.userInfo.isAuthenticated) {
            return this.getServiceWorkerPlugins(config, isAuthenticated)
              .then((plugins) => this.installServiceWorkerPlugins(plugins, false));
          }
          return Promise.resolve();
        });
    }

    /**
     * Install the given plugins on all the service workers currently registered. The plugins should be an array of
     * urls. For example:
     *
     *  [
     *   'vbsw/plugin1',
     *   {
     *     url: 'vbsw/plugin2',
     *     params:  {
     *       foo: 'bar'
     *     }
     *   }
     * ]
     *
     * @param plugins an array of plugins urls to install
     * @param reload if true, force reload all the plugins, otherwise, only reload plugins whose metadata has changed
     * @returns {Promise}
     */
    installServiceWorkerPlugins(plugins = [], reload = true) {
      return ServiceWorkerManager.getInstance().installServiceWorkerPlugins(plugins, reload);
    }

    /**
     * Process the array of roles or permissions. From the raw value of the roles
     * or permissions property of the security config payload, calculate an array
     * of roles.
     *
     * If merge is true, the override array will be merged into the original array.
     * Otherwise, the override array, if provided, will replace the original array.
     *
     * @param {Array|String} original an array or a comma separated list of items
     * @param {Array} override an array
     * @param {Boolean} merge if true, merge original array with override
     * @return {Array} the processed array.
     */
    processRolesOrPerms(original, override, merge = false) {
      original = original || [];
      const origArray = Array.isArray(original) ? original : original.split(',');

      let processedArray;
      if (merge) {
        // merge the original array with the override
        processedArray = origArray.concat(override || []);
      } else {
        // replace the orignal array with the override
        processedArray = override || origArray;
      }

      // Add a property to the array for each item in the array.
      // This will allow expression like $application.user.roles.foo to be truthy
      processedArray.forEach((elt) => {
        processedArray[elt] = true;
      });

      return processedArray;
    }

    /**
     * Get the user roles from the runtimeEnvironment if any to override the roles in securityConfig.
     *
     * @returns {Promise}
     */
    getUserRolesOverride() {
      return Application.runtimeEnvironment.getUserRolesOverride();
    }

    /**
     * Get the user permissions from the runtimeEnvironment if any to override the permissions in securityConfig.
     *
     * @returns {*}
     */
    getUserPermissionsOverride() {
      return Application.runtimeEnvironment.getUserPermissionsOverride();
    }

    /**
     * Fetch the current user info using the url in the config object.
     *
     * @param config the config object containing the url for fetching the current user
     */
    fetchCurrentUser(config) {
      let responsePromise;

      const vbConfig = globalThis.vbInitConfig;

      if (vbConfig.INJECTED && vbConfig.INJECTED.security && vbConfig.INJECTED.security.user) {
        // return injected user profile
        const result = {
          body: vbConfig.INJECTED.security.user,
          response: { ok: true },
        };
        responsePromise = Promise.resolve(result);
      } else {
        // chose which way to fetch the data, based on 'endpoint'
        responsePromise = config.endpoint
          ? this.fetchCurrentUserFromEndpoint(config.endpoint, config.uriParams)
          : this.fetchCurrentUserRaw(config);
      }

      return responsePromise.then((result) => {
        const response = result.response;

        if (response.ok) {
          // if we use the helper, the json is already resolved (and cannot be re-resolved)
          const jsonPromise = result.body ? Promise.resolve(result.body) : response.json();

          return Promise.all([
            jsonPromise,
            // get the user roles from the runtimeEnvironment if any to override the roles in securityConfig
            this.getUserRolesOverride(),
            // get the user permissions from the runtimeEnvironment if any to override
            // the permissions in securityConfig
            this.getUserPermissionsOverride(),
          ]).then((results) => {
            const securityConfig = results[0];
            const roles = this.processRolesOrPerms(securityConfig.roles, results[1]);
            const permissions = this.processRolesOrPerms(securityConfig.permissions, results[2]);

            if (securityConfig.isAnonymous !== undefined && !securityConfig.isAnonymous) {
              this.userInfo = {
                userId: securityConfig.id,
                username: securityConfig.userName,
                longId: securityConfig.longId,
                fullName: securityConfig.fullName,
                email: securityConfig.email,
                isAdmin: securityConfig.isAdmin,
                isAuthenticated: !securityConfig.isAnonymous,
              };
            }

            this.userInfo.roles = roles;
            this.userInfo.permissions = permissions;

            this.loginUrl = securityConfig.loginUrl;
            this.logoutUrl = securityConfig.logoutUrl;
          });
        }

        return Promise.resolve();
      }).catch((err) => {
        // ignore the error
        console.error(err);
      });
    }

    /**
     * "userConfig" is configured using a "url" (rather than an "endpoint")
     * @param config
     * @return {Promise<{ response: Response }>}
     */
    // eslint-disable-next-line class-methods-use-this
    fetchCurrentUserRaw(config) {
      return Promise.resolve()
        .then(() => {
          const options = {
            credentials: 'same-origin',
            headers: {
              [SwConstants.USE_CACHED_RESPONSE_WHEN_OFFLINE]: true, // use offline toolkit to handle caching
              'Cache-Control': 'no-cache, no-store', // bypass browser cache
              Pragma: 'no-cache', // bypass IE browser cache
              [Constants.Headers.VB_INFO_EXTENSION]: '{}', // keep it from being mapped, but skip auth processing
              [Constants.Headers.USE_OAUTH_ACCESS_TOKEN_WHEN_AVAILABLE]: true,
            },
          };

          let url = config.url;

          // if the current user url is a relative url, e.g., resources/userData.json, make sure it's relative
          // to the application url
          if (!Utils.isAbsolutePath(url)) {
            url = `${Configuration.applicationUrl}${url}`;
          }

          const request = new Request(url, options);

          return fetch(request);
        })
        // make the raw result look like the helper one
        .then((response) => ({
          response,
        }));
    }

    /**
     * "userConfig" is configured using an "endpoint", rather than a "url"
     * @param endpoint
     * @param parameters
     */
    // eslint-disable-next-line class-methods-use-this
    fetchCurrentUserFromEndpoint(endpoint, parameters) {
      return Promise.resolve()
        .then(() => {
          const helper = BootstrapRest.get(endpoint); // no 'container', will use 'base' services only
          if (parameters) {
            helper.parameters(parameters);
          }
          return helper.fetch();
        });
    }

    /**
     * Return a promise that resolves to n array of plugin info.  A plugin info can be either an url string or
     * an object containing url and params properties. The params property will be passed to the constructor
     * of the plugin. For example:
     *
     *  [
     *   'vbsw/plugin1',
     *   {
     *     url: 'vbsw/plugin2',
     *     params:  {
     *       foo: 'bar'
     *     }
     *   }
     * ]
     *
     * @param config the userConfig.configuration.serviceWorkerConfig object
     * @param isAnonymous true if the user is not logged in
     * @returns {Promise<Array>}
     */
    getServiceWorkerPlugins(config, isAnonymous = false) {
      // first get the plugins defined in the config object
      const plugins = (config && config.plugins) ? config.plugins.slice() : [];

      plugins.push({
        url: 'vbsw/private/plugins/generalHeadersHandlerPlugin',
        params: {
          'Accept-Language': ojConfig.getLocale(),
        },
      });

      return Promise.resolve(plugins);
    }

    /**
     * This method will return an array of plugin urls that are necessary for FA applications to make
     * REST requests when running in a VB environment. It will return an any array otherwise.
     *
     * Note that this method will only be called by FA's own implementation of the security provider.
     *
     * @returns {Promise<Array>}
     * @deprecated since version 2010.0.0-rc.3 to be replaced by
     * SecuriytHelpers.getDefaultServiceWorkerPluginsForVbEnvironment
     */
    static getServiceWorkerPluginsForVbEnvironment() {
      return Promise.resolve().then(() => {
        const vbConfig = globalThis.vbInitConfig || {};
        const vbServer = vbConfig.VB_SERVER;
        const serverRoot = Utils.addTrailingSlash(Utils.cleanUpExtraSlashes(vbConfig.INGRESS_PATH || ''));
        const orgId = vbConfig.ORGANIZATION_ID;

        // determine if we are running in a VB environment by checking if the VB_SERVER property
        // is injected into vbInitConfig
        if (vbServer) {
          return [
            // if orgId is not undefined, install multiTenantCsrfTokenHandlerPlugin instead
            (orgId === undefined) ? 'vbsw/private/plugins/csrfTokenHandlerPlugin'
              : {
                url: 'vbsw/private/plugins/multiTenantCsrfTokenHandlerPlugin',
                params: {
                  orgId,
                  vbServer,
                  serverRoot,
                },
              },
          ];
        }

        return [];
      });
    }

    /**
     * Return an object describing the type of the user info
     * @return {Object} the type of the user info
     */
    static getUserInfoType() {
      return {
        userId: 'string',
        username: 'string',
        fullName: 'string',
        email: 'string',
        roles: 'string[]',
        permissions: 'string[]',
        isAuthenticated: 'boolean',
      };
    }

    /**
     * This function is called by the client when an error occurs while loading a page. It attempts
     * to handle the load error for a VB artifact and returns true if it does.
     * Depending on the type of error provided, a security provider can either:
     *    1) handle the error which possibly mean redirecting to a login page then return true.
     *    2) do nothing and return false, indicating the error was not handled
     * @param {Error} error load error
     * @param {String} returnPath the path of the page we are trying to load
     * @return {boolean} true if the error is handled, false otherwise. If the error is handled, the
     * client cancel the current operation, otherwise the client should display an error message.
     */
    handleLoadError(error, returnPath) {
      // Babel doesn't transpile class extending error, so for now, we need to be using
      // the statusCode property.
      if (error.statusCode === 401 || (error.statusCode === 403 && !this.userInfo.isAuthenticated)) {
        return this.handleLogin(returnPath);
      }

      return false;
    }

    /**
     * Handle the user login process.
     * Redirect to the login page using the login URL given by the security provider configuration.
     * If defined, the returnPath is added to the login URL using the query parameter name defined in the
     * 'returnPathQueryParam' property of the SecurityProvider class.
     *
     * @param  {string} returnPath the path of the page to go to when login is successfull
     * @return {boolean} true if the login process is handled
     */
    handleLogin(returnPath) {
      if (this.loginUrl) {
        // Let the router calculate the correct URL from the page path
        let endUri = Navigate.getUrlFromPath(returnPath);
        // Prefix the return path with the appPath
        endUri = `${this.appPath}${endUri}`;

        Navigate.toUrl({
          url: this.loginUrl,
          // Add the return URL as a request parameter
          params: { [this.returnPathQueryParam]: endUri },
          // Use replace mode so the login page doesn't appear in the history
          history: Constants.HistoryMode.REPLACE,
        });

        return true;
      }

      return false;
    }

    getLoginUrl() {
      if (this.loginUrl) {
        // Let the router calculate the correct URL from the page path
        let endUri = Navigate.getUrlFromPath('/');
        // Prefix the return path with the appPath
        endUri = `${this.appPath}${endUri}`;

        return `${this.loginUrl}&${this.returnPathQueryParam}=${endUri}`;
      }

      return null;
    }

    /**
     * Handle the user logout process.
     * The default implementation navigate to the URL defined by the logoutUrl argument.
     * If the logoutUrl argument is not defined, it uses the logoutUrl of the SecurityProvider
     * configuration.
     *
     * @param  {String} logoutUrl  the home URL of the application
     */
    handleLogout(logoutUrl) {
      const url = logoutUrl || this.logoutUrl;

      if (url) {
        Navigate.toUrl({
          url,
          // Add the return URL as a request parameter. The return URL is the home page
          params: { [this.returnPathQueryParam]: this.appPath },
          // Use replace mode so the logout page doesn't appear in the history
          history: Constants.HistoryMode.REPLACE,
        });
      }
    }

    /**
     * Check if the current user can access a resource with the given access info.
     * If the user is not authenticated, this method return false.
     * Otherwise if the user role is one of the roles in accessInfo or if the user
     * permission is one of the permissions in accessInfo then the method return true.
     *
     * @param  {String}  type the resource. Either "Application", "Flow" or "Page"
     * @param  {String}  path the resource path like "app/flow1/myPage"
     * @param  {Object}  accessInfo an object describing the access restrictions for this resource
     * @return {Boolean} true if the current user satisfies the restrictions in accessInfo.
     */
    isAccessAllowed(type, path, accessInfo = { requiresAuthentication: true, roles: [], permissions: [] }) {
      // type and path are only needed for potential subclass
      const roles = accessInfo.roles || [];
      const permissions = accessInfo.permissions || [];
      const requiresAuthentication = (accessInfo.requiresAuthentication !== undefined)
        ? accessInfo.requiresAuthentication : true;
      let result = false;

      if (requiresAuthentication) {
        if (!this.userInfo.isAuthenticated) {
          return false;
        }

        // true if at least one of the user roles match the access role or
        // if at least one of the user permissions match the access permissions
        result = roles.some((role) => this.userInfo.roles.includes(role))
          || permissions.some((perm) => this.userInfo.permissions.includes(perm));
      }

      // also return true if there are no required roles or permissions
      return result || (roles.length === 0 && permissions.length === 0);
    }

    securityCallback() {
      return Promise.resolve();
    }

    /**
     * This method is used to handle the 'vbGetCookie' message from the FetchHandlerPlugin class
     *
     * @param name the of the cookie to get information on
     * @returns {Promise} containing the cookie value
     */
    vbGetCookie(name) {
      return Promise.resolve().then(() => {
        const cookie = document.cookie.split(';').find((item) => item.includes(`${name}=`));

        if (cookie) {
          return this.decodeCookie(cookie);
        }

        return null;
      });
    }

    /**
     * This method is used to handle the 'vbDeleteCookie' message from the FetchHandlerPlugin class
     *
     * @param name The cookie to remove to set on the docment.cookie API which is rendered as per
     *    https://wicg.github.io/cookie-store/#set-cookie-algorithm
     * @returns {Promise} containing the cookie value
     */
    vbDeleteCookie(name) {
      return Promise.resolve().then(() => {
        const cookieString = `${name}=; Expires=Thu, 01 Jan 1970 00:00:00 GMT`;

        document.cookie = cookieString;
      });
    }

    /**
     * This method is used to handle the 'vbSetCookie' message from the FetchHandlerPlugin class
     *
     * @param name the name of the cookie
     * @param cookie The value to set on the docment.cookie API which is rendered as per
     *    https://wicg.github.io/cookie-store/#set-cookie-algorithm
     * @returns {Promise} containing the cookie value
     */
    vbSetCookie(name, cookie) {
      return Promise.resolve().then(() => {
        const encodedCookie = this.encodeCookie(name, cookie);

        document.cookie = encodedCookie;
      });
    }

    /**
     * Decode the url encoded cookie and parse it into a JSON object.
     *
     * @param cookie the cookie to decode
     * @returns {*}
     */
    decodeCookie(cookie) {
      const value = cookie.substring(cookie.indexOf('=') + 1);

      // decode the uri encoded cookie and parse it into a JSON object
      if (value) {
        return JSON.parse(decodeURIComponent(value));
      }

      return null;
    }

    /**
     * JSON stringify the given cookie and url encode it. Also, append additional directives to the cookie based
     * on information provided by the cookie.
     *
     * @param name the name of the cookie
     * @param cookie the cookie object to encode
     * @returns {string} The object encoded as a string
     */
    encodeCookie(name, cookie) {
      let cookieString = `${name}=${encodeURIComponent(JSON.stringify(cookie))}`;
      const append = (...args) => {
        cookieString = cookieString.concat('; ', ...args);
      };

      // append cookie directives based on information provided by the cookie

      let host;
      if (cookie.url) {
        host = new URI(cookie.url).host();
      }

      if (cookie.expires) {
        append('Expires=', new Date(cookie.expires).toUTCString());
      }

      if (cookie.domain) {
        append('Domain=', cookie.domain);
      } else if (host) {
        append('Domain=', host);
      }

      if (cookie.path) {
        append('Path=', cookie.path);
      }

      if (cookie.secure) {
        append('Secure');
      }

      if (cookie.sameSite) {
        switch (cookie.sameSite) {
          case 'unrestricted':
            break;
          case 'strict':
            append('SameSite=', 'Strict');
            break;
          case 'lax':
            append('SameSite=', 'Lax');
            break;
          default:
            break;
        }
      }

      return cookieString;
    }
  }

  return SecurityProvider;
});

