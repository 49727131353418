'use strict';

define('vb/private/types/utils/cryptoUtils',[], () => {
  /**
   * This class contains crypto-related utility methods.
   */
  class CryptoUtils {
    /**
     * Base64Url encodes a string that is URL-safe. This is a format where the trailing '=' characters
     * of a base64-encoded string is removed, '+' replaced with '-' and '/' replaced with '_'.
     * This format does not require url encoding which can make the resulting string longer with
     * %xx character replacements.
     *
     * @param value string to encode
     * @returns {String|*}
     */
    static base64UrlEncode(value) {
      let encoded = btoa(value);

      // remove trailing '='
      encoded = encoded.split('=')[0];

      // replace + with - and / with _
      encoded = encoded.replaceAll('+', '-');
      encoded = encoded.replaceAll('/', '_');

      return encoded;
    }

    /**
     * Generates a random string that can be used as a code verifier in the Proof Key for Code Exchange (PKCE) scheme.
     *
     * @link https://datatracker.ietf.org/doc/html/rfc7636#section-4.1
     *
     * @param length
     * @returns {string}
     */
    static generateCodeVerifier(length = 32) {
      // characters allowed by standard
      const charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';

      let codeVerifier = '';
      window.crypto.getRandomValues(new Uint8Array(length))
        .forEach((value) => {
          codeVerifier += charSet.charAt(value % charSet.length);
        });

      // base64url encode it
      codeVerifier = this.base64UrlEncode(codeVerifier);

      return codeVerifier;
    }

    /**
     * Generates a code challenge from the given code verifier.
     *
     * @link https://datatracker.ietf.org/doc/html/rfc7636#section-4.2
     *
     * @param codeVerifier used to generate the code challenge
     * @param method digest method used to generate a hash value from the code verifier
     * @returns {Promise<T>}
     */
    static generateCodeChallenge(codeVerifier, method = 'SHA-256') {
      return Promise.resolve().then(() => {
        if (method === 'plain') {
          return codeVerifier;
        }

        return crypto.subtle.digest(method, new TextEncoder().encode(codeVerifier))
          .then((hashBuffer) => {
            const codeChallenge = this.base64UrlEncode(String.fromCharCode(...new Uint8Array(hashBuffer)));
            return codeChallenge;
          });
      });
    }
  }

  return CryptoUtils;
});

