'use strict';

define('vb/extensions/dynamic/private/helpers/metadataHelperFactory',[
  'vb/private/utils',
],
(Utils) => {
  class MetadataHelperFactory {
    /**
     * creates the right helper, based on options. exposed to JET via a method on the (original) vbHelper
     * passed to the provider.
     *
     * This is used for the heterogeneous use-case, where each stamped list item needs a helper
     * for its own set of (form) data.
     *
     * During prototyping, this supported options that contained an 'endpoint' property;
     * this returns a ServiceMetadataProviderHelper for that case
     *
     * This support was expanded to support a 'discriminator' property, which can contain the various
     * properties which match for the DynamicLayoutMetadataProviderDescriptor.
     *
     * Properties of the descriptor are interpreted as:
     *  - path: include operationId, metadataPath, return a DataDescriptionMetadataProviderHelper
     *  - endpoint: include metadataPath, return a ServiceMetadataProviderHelper
     *  - ref: an object that includes businessObject and extensionId (optional), return a
     *  ReferenceMetadataProviderHelper
     *
     * @param options {object} one of 'endpoint' or 'path' must be provided.
     * @param options.endpoint {string} optional, unless there is no 'path'. uses service-based metadata.
     * @param options.path {string} optional, unless there is no 'endpoint'. uses JSON-based metadata.
     * @param options.ref {object} optional, used for fetchin metadata reference.
     *
     * @param container {Container} the owning container of the helper that is creating a new helper.
     * @param parentId {string} id of the parent helper
     * @returns {Promise<ServiceMetadataProviderHelper>}
     * @throws {Error}
     */
    static createHelper(options, container, parentId) {
      // @todo: deprecate 'vbAppContext' option property; no need to pass this to JET any more
      // because we pass the 'viewmodels as part of the array objects from getLayoutResources().
      // (this _was_ how we passed minimal binding context, like user, etc.)
      const vbAppContext = {};

      let id;

      return Promise.resolve()
        .then(() => {
          if (!options) {
            throw new Error('Invalid options argument to MetadataHelperFactory.createHelper');
          }

          if (options.endpoint) {
            id = `${parentId}/${options.endpoint}`;
            return Utils.getResource('vb/extensions/dynamic/private/helpers/serviceMetadataProviderHelper');
          }

          if (options.path) {
            id = `${parentId}/${options.path}`;
            return Utils.getResource('vb/extensions/dynamic/private/helpers/dataDescriptionMetadataProviderHelper');
          }

          throw new Error(`MetadataHelperFactory was unable to create helper: ${JSON.stringify(options)}`);
        })
        .then((HelperClass) => {
          if (HelperClass) {
            const helperOptions = Object.assign({ id }, options);
            return HelperClass.get(helperOptions, vbAppContext, container);
          }
          throw new Error('MetadataHelperFactory is unable to find the ServiceMetadataProviderHelper class');
        });
    }
  }

  return MetadataHelperFactory;
});

