'use strict';

define('vb/private/stateManagement/switcherPageMixin',[
], () => {
  /**
   * Mixin to be used with pages running in a switcher
   * It modifies the original behavior of the page to run in a oj-vb-switcher component
   */
  const SwitcherPageMixin = (superclass) => class extends superclass {
    get rootPage() {
      return this.parent.rootPage;
    }

    get switcher() {
      return this.rootPage.switcher;
    }

    get rootRouter() {
      return this.rootPage.rootRouter;
    }

    /**
     * Return true if this page can be embedded in a switcher component
     * @return {Boolean}
     */
    isEmbeddable() {
      const { navigation } = this.definition;
      if (navigation && navigation.embeddable === 'enabled') {
        return true;
      }
      if (this.isDefault() && this.parent.isEmbeddable()) {
        return true;
      }

      return false;
    }

    /**
     * Check if there is any restriction to navigate to this page
     * @param {Page} sourcePage
     * @throws an error id the page is not navigable
     */
    checkNavigable(sourcePage) {
      super.checkNavigable(sourcePage);
      // For any container that can be embedded in a switcher flow,
      // check the leaf page can be embedded.
      if (!this.definition.routerFlow && !this.rootPage.isInitialized()) {
        if (!this.isEmbeddable()) {
          throw new Error(`Page ${this.getNavPath()} is not allowed to be embedded.`);
        }
      }
    }

    /**
     * Retrieve the leaf page instance when navigating between 2 App UIs.
     * For a page in a switcher, delegate to the root Page of the swticher element
     * instead of the Application
     *
     * Called by navigateOperation
     *
     * @param  {String}  appUiId the id of the App UI to navigate to
     * @param  {Object}   navContext including options with navigate action parameters
     * @return {Promise}  a Promise resolving to the leaf page instance
     */
    getLeafPageInstanceFromAppUiId(appUiId, navContext) {
      return this.rootPage.getLeafPageInstanceFromAppUiId(appUiId, navContext);
    }

    /**
     * For pages in a switcher it is the combination of the navPath of the page with the switcher
     * plus the navpath of this page to the current root router
     * This path is used for the value of the currentPage builtin variable
     *
     * @return {String}  The navigation path from top
     */
    getNavPathFromTopRouter() {
      return `${this.rootPage.parent.getNavPath()}/${this.rootPage.urlId}/${this.getNavPath()}`;
    }
  };

  return SwitcherPageMixin;
});

