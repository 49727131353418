'use strict';

define('vb/action/actionUtils',[], () => {
  /**
   * API for invoking variables actions in a code-based action chain.
   */
  class ActionUtils {
    /**
     * This utility method executes the given async callback for each element of an array.
     *
     * If mode is 'serial', the callback function will be invoked serially after each iteration
     * when the returned promise resolves. If mode is 'parallel', all invocations of the callback
     * will happen in parallel.
     *
     * The return value of this method is an array containing the result of each iteration.
     *
     * @param arr the array to iterate over
     * @param callback the async callback function to invoke. The first argument to the callback
     * is the current element of the array and the second argument is the current index.
     * @param {Object} invocationMode Customize how the callbacks should be called
     * @param [string] invocationMode.mode 'serial' (default) or 'parallel'. If mode is 'serial', the callback will be
     * invoked serially. Otherwise, all invocations of the callback will happen in parallel.
     * @returns {Promise<any[]>}
     */
    static async forEach(arr, callback, invocationMode = {}) {
      // Babel doesn't like { mode = 'serial' } = {}
      // or { mode } = { mode: 'serial' }
      const { mode = 'serial' } = invocationMode; // Keep existing contract
      if (mode === 'serial') {
        const results = [];
        for (let i = 0; i < arr.length; i += 1) {
          // eslint-disable-next-line no-await-in-loop
          results.push(await callback(arr[i], i));
        }
        return results;
      }

      if (mode === 'parallel') {
        const promises = arr.map((item, i) => callback(item, i));
        return Promise.all(promises);
      }

      throw new Error(`Unsupported mode ${mode}`);
    }
  }

  return ActionUtils;
});

