'use strict';

define('vb/private/types/capabilities/fetchByKeys',[
  'vb/private/log',
  'vb/private/constants',
  'vb/private/types/dataProviderConstants',
  'vb/private/utils',
  'vb/private/types/capabilities/fetchContext',
  'vb/private/types/capabilities/fetchByKeysUtils',
  'vbc/private/monitorOptions'],
(Log, Constants, DPConstants, Utils, FetchContext, FetchByKeysUtils, MonitorOptions) => {
  const FETCH_BY_KEYS_CAPABILITY = 'fetchByKeys';

  /**
   * fetchByKeys data provider capability types supported
   */
  const FetchByKeysCapability = {
    FETCH_BY_KEYS_LOOKUP: 'lookup',
    FETCH_BY_KEYS_ITERATION: 'iteration',
  };

  /**
   * Object that implements the FetchByKeys contract, both making a fetch call and building
   * results expected by callers of fetchByKeys. See JET oj.DataProvider for details.
   */
  /* eslint class-methods-use-this: ["error", { "exceptMethods": ["getFetchCapability"] }] */
  class FetchByKeys extends FetchContext {
    /**
     * constructor
     *
     * @param sdp
     * @param params {Object} see oj.FetchByKeysParameters. Contains the following properties
     *  - keys: Set<any> keys of the rows to fetch
     */

    /**
     *  prune options to just what supported on oj.FetchListParameters; also when callers
     *  provides more than one key in via 'keys' parameter use just the first.
     *
     * @param {object=} options Options to control fetch
     * @property {number} options.keys Set of keys to fetch
     * @property {AbortSignal=} options.signal The AbortSignal from AbortController.
     * A signal associated with fetchByKeys so that this request can be aborted later.
     * @return {Promise} Promise object resolves to a compound object which contains an array of
     * row data objects, an array of ids, and the startIndex triggering done when complete.<p>
     *
     * @param options
     */
    whiteListFetchOptions(options) {
      return FetchByKeysUtils.whiteListFetchOptions(this.sdp, options);
    }

    /**
     * The fetch capability this class implements by default. Subclasses must override this
     * method.
     * @returns {string}
     */
    getFetchCapability() {
      return DPConstants.CapabilityType.FETCH_BY_KEYS;
    }

    /**
     * overridden so the request can be spliced if needed based on capability set on the
     * SDP, and the response can be packed up in a form FetchByKeys contract imposes.
     * @returns {Promise}
     */
    fetch() {
      const uniqueId = `${this.sdp.id} [${this.id}]`;
      const cap = this.sdp.getCapability(FETCH_BY_KEYS_CAPABILITY);

      const isImplementationLookup = function () {
        return cap && cap.implementation === FetchByKeysCapability.FETCH_BY_KEYS_LOOKUP;
      };

      if (isImplementationLookup()) {
        if (cap.multiKeyLookup
          && cap.multiKeyLookup === DPConstants.CapabilityValues.FETCH_BY_KEYS_MULTI_KEY_LOOKUP_YES) {
          return this.fetchByKeysLookup(); // FetchByKeysUtils.fetch(this);
        }

        // we should never get here because SDP will never call this class for iteration based
        // lookups
        const multiKeyErr = `ServiceDataProvider ${uniqueId}: FetchByKeys implementation called`
          + ' with multiple keys when the capability only supports fetching by a single key at a'
          + ' time';
        this.log.error(multiKeyErr);
        return Promise.reject(multiKeyErr);
      }

      // we should never get here because SDP will never call this class for iteration based
      // lookups
      const err = `ServiceDataProvider ${uniqueId}: FetchByKeys lookup based implementation `
        + 'cannot be used for iteration based implementation! Check your configuration!';
      this.log.error(err);
      return Promise.reject(err);
    }

    /**
     * Returns the transforms options Map with the fetchByKeys options added.
     * @returns {Object}
     */
    getRequestTransformOptions() {
      const finalTO = super.getRequestTransformOptions();
      const fetchOpts = this.fetchOptions || {};

      // tack on fetchByKeys options
      finalTO[FETCH_BY_KEYS_CAPABILITY] = fetchOpts.keys;
      return finalTO;
    }

    /**
     * Starts a fetch call for keys provided using the lookup endpoint and returns the result
     * expected.
     * @returns {Promise} resolves with oj.FetchByKeysResult
     */
    fetchByKeysLookup() {
      return Promise.resolve().then(() => {
        const { sdp } = this;
        const { fetchOptions } = this;
        const uniqueId = `${this.sdp.id} [${this.id}]`;

        sdp.log.startFetch('ServiceDataProvider', uniqueId,
          'fetchByKeys called with options', JSON.stringify(fetchOptions),
          'and state:', this.sdpState);
        const mo = new MonitorOptions(MonitorOptions.SPAN_NAMES.SDP_FETCH_BY_KEYS, uniqueId);
        return sdp.log.monitor(mo, (fetchTime) => this.fetchForReal()
          .then((result) => {
            const fetchByKeysResult = FetchByKeysUtils.buildFetchResult(this, result);
            sdp.log.endFetch('ServiceDataProvider', uniqueId,
              'fetchByKeys succeeded with result:', fetchByKeysResult, fetchTime());

            return (fetchByKeysResult);
          })
          .catch((err) => {
            FetchContext.logFetchError(this.getFetchCapability(), sdp, err, uniqueId, fetchTime(err));
            // fire a dataProviderNotification event so authors can handle error appropriately
            this.invokeNotificationEvent(Constants.MessageType.ERROR, err);
            throw (err);
          }));
      });
    }

    /**
     * the real call to fetch
     * @returns {Promise}
     */
    fetchForReal() {
      return super.fetch();
    }

    /**
     * Pagination options on super class is always set. Here we ensure that it's only set if it's
     * configured on the SDP. Even if it's provided by caller it's no part of the contract for
     * the fetchByKeys API. But we want to respect what page author has set on the configuration.
     * For paginate options that might be coming from an externalized RestAction see
     * reconcileTransformOptions
     * @return {{offset: *, size: *}}
     */
    getPaginateOptions() {
      return FetchByKeysUtils.getPaginateOptions(this);
    }

    /**
     * Reconcile transform options determined so far with options from other sources such as
     * RestAction. For externalized fetch case both options via fetch call and anything
     * configured on the SDP are ignored. So really we only need to ensure that options are
     * configured on the RestAction itself are used.
     *
     * @param transformOptions transform options as determined from fetch call and SDP defaults.
     * @param restTransformOptions transform options from rest
     *
     * @return {*} reconciled options
     */
    reconcileTransformOptions(transformOptions, restTransformOptions) {
      const superRO = super.reconcileTransformOptions(transformOptions, restTransformOptions);
      return FetchByKeysUtils.reconcileTransformOptions(transformOptions, restTransformOptions, superRO);
    }
  }

  return FetchByKeys;
});

