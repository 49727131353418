'use strict';

define('vb/private/types/serviceDataProvider',['ojs/ojdataprovider',
  'vb/private/constants',
  'vb/private/log',
  'vbc/private/logConfig',
  'vb/private/types/disconnectedServiceDataProvider',
  'vb/helpers/mixin',
  'vb/private/types/builtinExtendedTypeMixin',
  'vb/types/eventTargetMixin',
  'vb/private/utils',
  'vb/private/types/dataProviders/serviceDataProviderUtils',
  'vb/private/types/dataProviderConstants',
  'vb/private/types/utils/dataProviderUtils',
  'vb/private/stateManagement/stateUtils',
  'vb/private/types/capabilities/fetchContext',
  'vb/private/types/capabilities/fetchByKeys',
  'vb/private/types/capabilities/fetchByKeysIteration',
  'vb/private/types/capabilities/fetchBySingleKey',
  'vb/private/types/capabilities/fetchFirst',
  'vb/private/types/capabilities/fetchByKeysUtils',
  'vb/private/types/capabilities/containsKeys',
  'vb/private/types/capabilities/fetchByOffset',
  'vb/private/types/capabilities/fetchByOffsetIteration',
  'signals'],
(ojDataProvider, Constants, Log, LogConfig, DisconnectedServiceDataProvider, Mixin, BuiltinExtendedTypeMixin,
  EventTargetMixin, Utils, SDPUtils, DPConstants, DPUtils, StateUtils, FetchContext, FetchByKeys, FetchByKeysIteration,
  FetchBySingleKey, FetchFirst, FetchByKeysUtils, ContainsKeys, FetchByOffset, FetchByOffsetIteration, signals) => {
  const LOGGER = Log.getLogger('/vb/types/ServiceDataProvider', [
    // Register custom loggers
    {
      name: 'startFetch',
      severity: Constants.Severity.INFO,
      style: LogConfig.FancyStyleByFeature.serviceDataProviderStart,
    },
    {
      name: 'endFetch',
      severity: Constants.Severity.INFO,
      style: LogConfig.FancyStyleByFeature.serviceDataProviderEnd,
    },
  ]);

  /**
   * An iterating data provider that is stateless and implements the JET
   * oj.DataProvider. Does not implement the fetchByKeys() or fetchByOffset() implementations
   * because SDP cannot make any assumptions about these capabilities. Although it's assumed
   * that most services (RAMP based, external REST endpoints etc.) would support the offset based
   * fetch mechanism this information needs to come from the service layer. TBD: bug
   *
   * This data provider can be bound to listView, table and any component that takes a data
   * provider.
   *
   * There is builtin support for fetching data from REST endpoint, that typically serves up
   * collections, in addition to various other features like sort, filter, and paging using custom
   * paging options (token e.g.).
   *
   * Each feature supports one or more capabilities, so at runtime based on how the variable
   * is configured the correct capability is chosen. The capabilities for each feature is
   * determined as follows:
   *
   * - sort:    supports 'multiple' sort capability by default. See oj.SortCapability
   * - fetchByOffset:  defaults to 'iteration'. See oj.FetchByOffsetCapability
   * - fetchByKeys:  defaults to 'iteration'. See oj.FetchByKeysCapability
   * - filter: supports all operators at the moment.
   *
   * A variable based on this type has the following configurable properties that the data
   * source needs to support its features and capabilities.
   *  - endpoint:       for specifying collection endpoint information like service/endpointId
   *  - idAttribute:    the property in the response that is the 'id' attribute
   *  - itemsPath:      the root of the collection response
   *  - uriParameters:  an object, names of all uri parameters used by the endpoint
   *  - sortCriteria:   an array of objects, where each object has the properties - name and
   *                    direction. this is passed to the sort transform function.
   *  - pagingCriteria: as explained above.
   *  - filterCriteria (deprecated): an array of objects, where each object has the properties -
   *                    attribute, op and value. this is passed to the filter transform
   *                    function.
   *  - filterCriterion: an object, with properties - op, attribute, value tuple or,
   *                     op, criteria tuple. Refer to oj.FilterCriterion for details.
   *                     This is passed to the filter transform function.
   *
   *  - transforms:       has 2 properties for request and response transform functions. request
   *                      functions are primarily used to transform the url or Request
   *                      configuration. response functions can be used to process the
   *                      response and return any additional state along with the response.
   *                      Additional state is saved as internal state on the data source variable.
   *    - request:        properties under this are really types of the transform functions -
   *                      paginate, sort, filter and query are transform functions page author
   *                      defines. The pagingCriteria, sortCriteria and filterCriterion are
   *                      passed in using options to the transform functions resp.. query is just
   *                      passed the default.
   *
   *    - response:       properties under this are same as those for request. The response
   *                      transform functions are primarily used by page authors to process
   *                      the raw response from a fetch call and return an object that
   *                      holds some state relevant for that function. The final response from
   *                      the service layer includes a transformResults object with the state
   *                      keyed in by the response transform function name. State returned by
   *                      the all transform functions are stored in the internal state for the
   *                      variable and then later passed to the subsequent request transform
   *                      functions.
   *      - paginate:     the paging response transform function should at least return the
   *                      following properties.
   *        - totalSize:  response transform functions can use this property to set the
   *                      totalSize if this information is available.
   *        - hasMore:    boolean whether there are more results to fetch
   *        - pagingState:used to store any paging information specific to the paging
   *                      style supported by the endpoint. E.g.,
   *                      {first, prev, next, last} for link based paging, or
   *                      {prev_token, next_token} for token based paging.
   *                      This information is used between requests for the service's paging
   *                      solution.
   *
   * @see stateUtils.buildTypeStructure for how the instance is created.
   * @see rest.fetch for how request and response transform functions executed
   *
   * @constructor
   * @export
   * @class ServiceDataProvider
   * @implements { import("ojs/ojdataprovider").DataProvider }
   */
  /* eslint class-methods-use-this: ["error", { "exceptMethods": ["getCapability","getIdAttributeProperties"] }] */
  class ServiceDataProvider extends Mixin().with(EventTargetMixin, BuiltinExtendedTypeMixin) {
    /**
     * @constructor
     */
    constructor() {
      super();
      this.log = LOGGER;
      this.variableLifecycleStage = undefined;
      /**
       * a signal that allows interested parties to be notified of variable lifecycle stage changes
       * @type {signals.Signal}
       * @private
       */
      this.variableLifecycleStageChanged = new signals.Signal();
    }

    /**
     * returns the signal that callers can use to register their listeners, which will be notified of variable lifecycle
     * stage changes
     * @returns {signals.Signal}
     */
    getLifecycleStageChangedSignal() {
      return this.variableLifecycleStageChanged;
    }

    /**
     * Initializer.
     * @param id
     * @param variableDef the declarative definition of this variable
     * @param value default value as determined at the time of this call; some expressions may not have been
     * evaluated at this point and so this is not the final value
     * @param container {Container}
     */
    init(id, variableDef, value, container) {
      super.init(id, variableDef, value);

      /**
       * the variable definition
       * @private
       */
      this.definition = variableDef;

      this.variableLifecycleStage = Constants.VariableLifecycleStage.INIT;
      this.variableLifecycleStageChanged.dispatch(Constants.VariableLifecycleStage.INIT);

      this.container = container; // passed to Rest helper implementations for namespacing
    }

    activate() {
      // if variable has already been activated return right away. This can happen when a variable reference of this
      // type is passed around (example an already active page passes an SDP reference to a fragment)
      if (this.variableLifecycleStage === Constants.VariableLifecycleStage.ACTIVE) {
        this.log.fine('skipping activation for variable', this.id, 'because it is already active');
        return;
      }

      const value = this.getValue();
      // TODO: test for entire default value being a constant
      const capabilitiesDef = (value && value.capabilities) || {};
      const fetchCapsDef = SDPUtils.getConfiguredFetchCapabilities(capabilitiesDef, value) || {};

      // (1) first check to make sure multiple fetch capabilities are not configured on the same SDP variable.
      if (SDPUtils.hasMultipleFetchCapabilities(fetchCapsDef)) {
        this.log.error('ServiceDataProvider', this.getId(),
          'is configured with multiple fetch capabilities when only one should be used!');
        return;
      }

      // (2) define fetchByKeys and fetchByOffset implementations on SDP based on configured capabilities.
      // see BUFP-30444 and 30334
      // during init calling getValue() is too early. Moreover the only capabilities we want to
      // know about are fetchByKeys and fetchByOffset*, which are usually either literally
      // inlined on the SDP configuration, or most likely a $constants expr. If it's a $variables
      // reference we are out of luck, unless we have a fix for the above bugs.
      const fetchCaps = SDPUtils.getResolvedFetchCapabilities(fetchCapsDef);
      SDPUtils.initFetchByKeysMethods(this, fetchCaps);
      SDPUtils.initFetchByOffsetMethods(this, fetchCaps);

      // (3) log warning for deprecated attributes
      // when keyAttributes is set that always wins over idAttribute!
      // when both are set, idAttribute is ignored and a warning logged
      // when idAttribute alone is set, a warning that it is deprecated will be logged.
      // Additionally the value set for idAttribute is used for keyAttributes
      const idAttrDef = value.idAttribute;
      const keyAttributesDef = value.keyAttributes;
      this.useKeyAttributes = true;
      if (keyAttributesDef && idAttrDef) {
        this.log.warn('The property \'idAttribute\' with value \'', idAttrDef,
          'is ignored as the property \'keyAttributes\'', 'is also configured for the ServiceDataProvider',
          this.id, '. \'idAttribute\' is deprecated and will be removed in future releases.',
          'Use \'keyAttributes\' instead.');
      } else if (idAttrDef) {
        this.log.warn('The property \'idAttribute\' is deprecated and will be removed in',
          'future releases. Use the \'keyAttributes\' property instead in the configuration',
          'for ServiceDataProvider', this.id);
        this.useKeyAttributes = false;
      }

      // signal listeners of this dataProvider that variable has been fully initialized
      this.variableLifecycleStage = Constants.VariableLifecycleStage.ACTIVE;
      this.variableLifecycleStageChanged.dispatch(Constants.VariableLifecycleStage.ACTIVE);
    }

    dispose() {
      // signal listeners of this dataProvider that state (variable) is being disposed
      this.variableLifecycleStage = Constants.VariableLifecycleStage.DISPOSE;

      this.variableLifecycleStageChanged.dispatch(Constants.VariableLifecycleStage.DISPOSE,
        new DisconnectedServiceDataProvider(this));

      // when this variable is disposed unregister all listeners attached to this instance.
      // Removing all listeners means other parties that registered listeners are no longer getting notified of
      // changes to this state.
      this.removeAllEventListeners();

      this.variableLifecycleStageChanged.removeAll();

      super.dispose();
    }

    /**
     * true if the instance has been disconnected from the variable
     * @return {boolean}
     */
    isDisconnected() {
      return this.variableLifecycleStage === Constants.VariableLifecycleStage.DISPOSE;
    }

    /**
     * Returns the configured value in the variable definition.
     */
    getDefinitionValue() {
      return this.definition.defaultValue;
    }

    /**
     * keyAttributes is the only supported idAttribute property.
     * @return {string}
     */
    getIdAttributeProperty() {
      return this.useKeyAttributes ? DPConstants.DataProviderIdAttributeProperty.KEY_ATTRIBUTES
        : DPConstants.DataProviderIdAttributeProperty.ID_ATTRIBUTE;
    }

    /**
     * Fetches dataKEY_ATT by iteration, always starting from the first block of data.
     *
     * @param {import("ojs/ojdataprovider").FetchListParameters|*} params fetch parameters
     * @return {AsyncIterable} FetchFirst.FetchListAsyncIterable an AsyncIterable
     * @method
     * @name fetchFirst
     */
    fetchFirst(params) {
      // TODO: <bug> Iterator should hold internalState not SDP and this should have a snapshot of
      // properties. This will be merged with properties passed in.
      const fetchFirst = new FetchFirst(this, params);
      this.log.finer('iterator', fetchFirst.id, 'created for fetchFirst() on SDP:', this.getId());
      return fetchFirst.fetch();
    }

    /**
     * Fires a DataProvider event. This method can be called externally to force SDP to fire
     * an event.
     *
     * @param {string} eventType - supported events are refresh
     * @private
     */
    _fireEvent(eventType) {
      switch (eventType) {
        case DPConstants.DataProviderEvent.REFRESH: {
          try {
            this.dispatchEvent(new ojDataProvider.DataProviderRefreshEvent());
          } catch (e) {
            const err = `unable to refresh data provider due to error ${e}`;
            this.log.error(err);
            throw (err);
          }
          break;
        }
        case DPConstants.DataProviderMutationEvent.ADD:
        case DPConstants.DataProviderMutationEvent.UPDATE:
        case DPConstants.DataProviderMutationEvent.REMOVE:
        default: {
          // unusual for this class to call add/remove/update events. usually these are
          // dispatched by external actions
          const err = `unable to trigger event of type ${eventType}`;
          this.log.error(err);
          throw (err);
        }
      }
    }

    /**
     * For a feature this method returns the capability supported. This is called by components
     * and consumers of DP implementation.
     *
     * @param {String} feature the capability name - includes 'sort', 'filter', 'fetchByKeys',
     * 'fetchByOffset' and 'fetchFirst'. fetchFirst is defined by VB and by default returns
     * { implementation: 'iteration'} if not set. This must be set if SDP supports other fetch
     * capabilities on the same endpoint as the fetchFirst.
     * @see oj.DataProvider
     * @return {Object} or null if feature is not recognized
     */
    getCapability(feature) {
      const valueCap = (this.getValue() && this.getValue().capabilities) || {};
      const defaultValueCap = (this.definition.defaultValue && this.definition.defaultValue.capabilities) || {};
      const sdpCapabilities = Object.assign({}, defaultValueCap, valueCap);
      const featureCapability = sdpCapabilities[feature];

      return SDPUtils.getCapabilityByFeature(feature, featureCapability);
    }

    /**
     * Return the total size of data available, including server side if not local. If a
     * positive number is not set by the fetch -1 is returned.
     * Note: this is part of the DataProvider API
     *
     * @returns {Promise.<number>} total size of data
     * @instance
     */
    getTotalSize() {
      return Promise.resolve(SDPUtils.getTotalSize(this.getValue().totalSize));
    }

    /**
     * Returns a string that indicates if this data provider (canonical size) is empty. Valid values are:
     * "yes": this data provider is empty.
     * "no": this data provider is not empty.
     * "unknown": it is not known if this data provider is empty until a fetch is made.
     */
    isEmpty() {
      const ts = this.getValue().totalSize;
      if (ts === undefined || ts === -1) {
        return DPConstants.DataProviderIsEmptyValues.UNKNOWN;
      }
      if (ts === 0) {
        return DPConstants.DataProviderIsEmptyValues.YES;
      }
      return DPConstants.DataProviderIsEmptyValues.NO;
    }

    /**
     * Sets the total size on the SDP variable instance. This mutates the actual variable
     * value, which is fine, because there can be only one canonical totalSize per SDP instance.
     *
     * @param {number} ts total size of data
     * @instance
     * @private
     */
    setTotalSize(ts) {
      this.getValue().totalSize = ts;
    }

    /**
     * Called when a property of the SDP has changed. Top level properties of the SDP are set up
     * to be variables and when its properties change this event is fired.
     *
     * Properties can change in the following ways:
     * 1. UI field is bound to a variable-property and the UI writes to the property
     * 2. an assign variable action writes to this variable property or indirectly to a
     * referenced variable
     * 3. method implemented by SDP writes to a variable implicitly. This is an internal
     * change and such writes are tracked as internal (using internalState.variableChanged for
     * example).
     *
     * Generally a property change fires a REFRESH event. The only case when an event is not fired
     * is when the 'internalState' property changes.
     *
     * @private
     * @param e Event payload holding the name, oldValue, value and diff properties. See
     * variable.js for details.
     */
    handlePropertyVariableChangeEvent(e) {
      const { oldValue } = e;
      const { value } = e;
      const { id } = this;

      if (e.name.endsWith('internalState')) {
        // ignore state changes to 'internalState' property
        this.log.finer('ServiceDataProvider', id,
          'change event ignored for internalState change:', JSON.stringify(e.diff, null, 2));
      } else if (e.diff) {
        const diffProps = Object.keys(e.diff);
        if (diffProps.length === 1 && diffProps[0] === 'totalSize') {
          // if totalSize is the only change no need to queue a refresh. totalSize is updated
          // by VB but can also be set by page author.
          return;
        }

        diffProps.forEach((prop) => {
          if (prop) {
            this.log.finer('ServiceDataProvider', id, 'change event dispatches \'refresh\' event for property',
              prop, 'from old:', oldValue[prop], 'to new', value[prop]);
          }
        }, this);
        this._fireEvent(DPConstants.DataProviderEvent.REFRESH);
      }
    }

    /**
     * Returns the type definition for the value of this extended type. This should match the type definition
     * syntax of the page model, and is essentially the values of the 'type' and 'definition' of a variable.
     *
     * For example, to define a string:
     *
     * <code>
     *   return (
     *     {
     *       type: 'string'
     *     }
     *   );
     * </code>
     *
     * And an object:
     *
     * <code>
     *   return (
     *     {
     *       type: 'object',
     *       definition: {
     *         someValue: {
     *           type: 'string'
     *         }
     *       }
     *     }
     *   );
     * </code>
     *
     * @param variableDef the declarative definition of this variable
     * @param {Object} scopeResolver the resolver object (application, page, ...)
     * @returns {Object} A structure that defines the type of the value or null if no type exists
     * for the value
     */
    getTypeDefinition(variableDef, scopeResolver) {
      let responseTypeDef = 'any';
      if (variableDef.defaultValue && variableDef.defaultValue.responseType) {
        // responseType is specified in the defaultValue
        const { responseType } = variableDef.defaultValue;

        if (typeof responseType === 'string') {
          responseTypeDef = `${responseType}`;
        }
      }

      const fCriteria = variableDef.defaultValue && variableDef.defaultValue.filterCriteria;
      if (fCriteria && (typeof fCriteria === 'string'
        || (Array.isArray(fCriteria) && fCriteria.length > 0))) {
        // log a deprecated warning
        this.log.warn('ServiceDataProvider property filterCriteria has been deprecated. Use',
          'filterCriterion instead.');
      }

      return {
        type: {
          body: 'any',
          endpoint: 'string',
          headers: 'object',
          itemsPath: 'string',
          idAttribute: 'any',
          keyAttributes: 'any',
          transformsContext: 'object',
          uriParameters: 'object',
          fetchChainId: 'string',
          fetchChain: 'string',
          responseType: StateUtils.getType(`${this.getId()}:${responseTypeDef}`,
            { type: responseTypeDef }, scopeResolver),
          pagingCriteria: {
            offset: 'number',
            size: 'number',
            maxSize: 'number',
            iterationLimit: 'number',
          },
          sortCriteria: [
            {
              attribute: 'string',
              direction: 'string',
            },
          ],
          filterCriteria: [
            {
              attribute: 'string',
              op: 'string',
              value: 'string',
            },
          ],
          filterCriterion: 'any',
          capabilities: {
            sort: {
              attributes: 'string',
            },
            filter: {
              operators: 'string[]',
              textFilter: 'any',
            },
            fetchByKeys: {
              implementation: 'string',
              multiKeyLookup: 'string',
            },
            fetchByOffset: {
              implementation: 'string',
            },
            fetchFirst: {
              implementation: 'string',
            },
          },
          mergeTransformOptions: 'string',
          totalSize: 'number',
          transforms: {
            request: {
              paginate: 'string',
              query: 'string',
              filter: 'string',
              sort: 'string',
              select: 'string',
              body: 'string',
            },
            response: {
              paginate: 'string',
              body: 'string',
            },
          },
        },
        resolved: true,
      };
    }
  }

  return ServiceDataProvider;
});

