'use strict';

define('vb/extensions/dynamic/private/models/dynamicContainer',[
  'vb/extensions/dynamic/private/models/layoutResource',
  'vb/extensions/dynamic/private/models/dynamicContainerExtension',
  'vb/private/stateManagement/pageExtension',
  'vb/private/stateManagement/fragmentExtension',
], (LayoutResource, DynamicContainerExtension, PageExtension, FragmentExtension) => {
  /**
   * DynamicContainer is used to wrap a page or fragment container. Its purpose is to load the
   * -template.html file and extract view model, functions and descriptor from the wrapped
   * container.
   */
  class DynamicContainer extends LayoutResource {
    /**
     * DynamicContainer constructor
     *
     * @param container the container that contains this layout resource
     * @param extension the extension from which to load the resource
     * @param {String} path the absolute path to the layout resource, e.g., vb/extA/dynamicLayouts/self/employee
     * @param {Object} resourceDescriptor resource descriptor
     * @param {String} className the class name
     */
    constructor(container, extension, path, resourceDescriptor, className = 'DynamicContainer') {
      super(container, extension, path, resourceDescriptor, className);

      this.container = resourceDescriptor.container;

      // If the container is an extension, e.g., PageExtension or FragmentExtension, we need to wrap
      // the container in DynamicContainerExtension instead.
      if ((this.container instanceof PageExtension) || (this.container instanceof FragmentExtension)) {
        this.dynamicContainer = new DynamicContainerExtension(
          this.container.extension,
          this.container.path,
          this,
          resourceDescriptor,
        );
      } else {
        this.dynamicContainer = this;
      }

      DynamicContainer.createDynamicContainerExtensions(this.dynamicContainer);
    }

    /**
     * Recursively wrap all container extensions using DynamicContainerExtension.
     *
     * @param baseDynamicContainer
     */
    static createDynamicContainerExtensions(baseDynamicContainer) {
      // eslint-disable-next-line no-param-reassign
      baseDynamicContainer.extensionsArray = [];

      baseDynamicContainer.container.extensionsArray.forEach((containerExtension) => {
        // clone resourceDescriptor and set the container to the container extension
        const resDesc = Object.assign({}, baseDynamicContainer.resourceDescriptor);
        resDesc.container = containerExtension;

        // wrap containerExtensin in DynamicContainerExtension
        const dynamicContainerExtension = new DynamicContainerExtension(
          containerExtension.extension,
          containerExtension.path,
          baseDynamicContainer,
          resDesc,
        );
        baseDynamicContainer.extensionsArray.push(dynamicContainerExtension);

        DynamicContainer.createDynamicContainerExtensions(dynamicContainerExtension);
      });
    }

    /**
     * Get resourceLoc from the wrapped container.
     *
     * @returns {String}
     */
    get resourceLoc() {
      return this.container.resourceLoc;
    }

    /**
     * If the wrapped container is PageExtension or FragmentExtension, get layoutNamespace from
     * DynamicContainerExtension. Otherwise, get it from super.layoutNamespace
     *
     * @returns {String}
     */
    get layoutNamespace() {
      return this.dynamicContainer === this ? super.layoutNamespace : this.dynamicContainer.layoutNamespace;
    }

    /**
     * Get the view model from the wrapped container and load -template.html or -template-x.html.
     *
     * @returns {Promise}
     */
    getViewModel() {
      if (!this.viewModelPromise) {
        // only need to load -template file since everything else is already loaded
        this.viewModelPromise = Promise.all([
          this.container.getViewModel(), this.dynamicContainer.loadTemplate(),
        ])
          .then(([viewModel]) => {
            this.viewModel = viewModel;
            this.functions = this.container.functions;
            this.template = this.dynamicContainer.template;

            // extract descriptor by stringifying container.definition
            const definition = Object.assign({}, this.container.definition);
            delete definition.interface; // sanitize
            this.descriptor = JSON.stringify(definition);

            return viewModel;
          });
      }
      return this.viewModelPromise;
    }

    dispose() {
      // Since we cannot dispose the view model for the underlying page/fragment container, clear
      // the viewModelPromise before calling super.dispose.
      this.viewModelPromise = null;

      super.dispose();
    }
  }

  return DynamicContainer;
});

