'use strict';

define('vb/private/stateManagement/packagePage',[
  'vb/helpers/mixin',
  'vb/private/stateManagement/packageContainerMixin',
  'vb/private/stateManagement/packageAndExtensionContainerMixin',
  'vb/private/stateManagement/pageInExtension',
  'vb/private/stateManagement/fragment',
  'vb/private/stateManagement/packageFragment',
  'vb/private/constants',
], (Mixin, PackageContainerMixin, PackageAndExtensionContainerMixin, PageInExtension, Fragment,
  PackageFragment) => {
  /**
   * PackagePage class
   * A class for page object defined in an App UI
   */
  class PackagePage extends Mixin(PageInExtension).with(PackageContainerMixin, PackageAndExtensionContainerMixin) {
    constructor(id, parent, path, className = 'PackagePage') {
      super({ id, extension: parent.package.extension }, parent, path, className);
    }

    static get FragmentClass() {
      return PackageFragment;
    }

    static get FragmentBaseClass() {
      return Fragment;
    }

    isDefaultFlowId(id) {
      return this.definition.routerFlow === id || this.definition.defaultFlow === id;
    }

    invokeBeforeEvent(eventName, eventPayload) {
      // Make sure the App UI is initialized before executing the beforeEvent
      return this.package.initialize().then(() => super.invokeBeforeEvent(eventName, eventPayload));
    }

    isNavigationRestrictedToPublicPage(destinationPage) {
      // If the destination is in an other App UI, check if the page is navigable
      const startingPackageId = this.package && this.package.id;
      const destinationPackageId = destinationPage.package && destinationPage.package.id;
      // Compare the id instead of the package object because in the switcher case the package object is
      // a different instance.
      return (startingPackageId !== destinationPackageId);
    }
  }

  return PackagePage;
});

