'use strict';

define('vb/private/stateManagement/flowExtension',[
  'vb/private/stateManagement/containerExtension',
  'vb/private/stateManagement/context/flowExtensionContext',
  'vb/private/constants',
], (ContainerExtension, FlowExtensionContext, Constants) => {
  /**
   * FlowExtension class
   */
  class FlowExtension extends ContainerExtension {
    constructor(extension, path, base, className = 'FlowExtension') {
      // depending on the container, the parent extension container could be:
      // a page/package page extension, an app package extension or null
      // the following will ensure a proper extended parent container is created if needed
      let parentContainer = base.parent;
      let parentExtContainer = null;
      if (parentContainer) {
        parentExtContainer = parentContainer.getParentExtensionContainer(extension);
      }

      super(extension, parentExtContainer, path, base, className);

      this.services = null; // Initialized in createServices
    }

    // The same class is used to extend a flow extension
    static get extensionClass() {
      return FlowExtension;
    }

    /**
     *
     * @type {String}
     */
    static get resourceSuffix() {
      return '-flow-x.json';
    }

    /**
     * The default event prefix is the lowercase class name (see container.js) but for
     * flow extension we want to use the same event prefix as flow
     *
     * @type {String}
     */
    // eslint-disable-next-line class-methods-use-this
    get eventPrefix() {
      return 'flow';
    }

    /**
     * The name of the runtime environment function to be used to load the descriptor.
     *
     * @type {String} the descriptor loader function name
     */
    static get descriptorLoaderName() {
      return 'getFlowExtensionDescriptor';
    }

    /**
     * The name of the runtime environment function to be used to load the module functions.
     *
     * @type {String} the module loader function name
     */
    static get functionsLoaderName() {
      return 'getFlowExtensionFunctions';
    }

    /**
     * Returns the ContainerExtensionContext constructor used to create the '$' expression context.
     * @type {FlowExtensionContext}
     */
    static get ContextType() {
      return FlowExtensionContext;
    }

    /**
     * The name of the resource to be used to load the flow extension.
     * @type {string}
     */
    get fullName() {
      return `${this.id}-flow`;
    }

    /**
     * Returns a scope resolver map where keys are scope name ("page", "flow" or "application")
     * and value the matching objects. This is used to build the scopeResolver object.
     *
     * @private
     * @return {Object} an object which properties are scope
     */
    getScopeResolverMap() {
      const map = {
        [Constants.FLOW_PREFIX]: this,
        [Constants.GLOBAL_PREFIX]: this.base.application.extensions[this.extensionId],
      };

      if (this.package) {
        map[Constants.APP_UI_PREFIX] = this.package;
      } else {
        map[Constants.APPLICATION_PREFIX] = this.base.owningApp.extensions[this.extensionId];
      }

      return map;
    }
  }

  return FlowExtension;
});

