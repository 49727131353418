'use strict';

define('vbsw/private/plugins/oAuthAccessTokenHandlerPlugin',['vbsw/api/fetchHandlerPlugin', 'vbc/private/constants', 'vbsw/private/utils', 'vbc/private/log'],
  (FetchHandlerPlugin, CommonConstants, Utils, Log) => {
    const logger = Log.getLogger('/vbsw/private/plugins/accessTokenHandlerPlugin');

    /**
     * Handler plugin for attaching access token to a request when available.
     */
    class OAuthAccessTokenHandlerPlugin extends FetchHandlerPlugin {
      constructor(context, params = {}) {
        super(context, params);

        this.useAccessToken = params.useAccessToken;
        this.refreshAccessTokenPromise = null;
      }

      /**
       *
       * @param client
       * @returns {any}
       */
      getAccessToken(client) {
        // return refreshAccessTokenPromise if we are in the middle of refreshing the access token
        if (this.refreshAccessTokenPromise) {
          return this.refreshAccessTokenPromise;
        }

        this.getAccessTokenPromise = this.getAccessTokenPromise || Promise.resolve()
          .then(() => {
            if (!this.accessToken) {
              // post a message to the main application to get the access token
              const msg = {
                method: 'vbGetOAuthAccessToken',
              };
              return Utils.postMessage(client, msg)
                .then((token) => {
                  this.accessToken = token;
                  return token;
                })
                .catch((err) => {
                  logger.error(err);
                  return null;
                });
            }

            return this.accessToken;
          });

        return this.getAccessTokenPromise;
      }

      /**
       *
       * @param client
       * @returns {any}
       */
      refreshAccessToken(client) {
        // protect the call in a promise so we don't have multiple requests trying to refresh the access token
        this.refreshAccessTokenPromise = this.refreshAccessTokenPromise || Promise.resolve()
          .then(() => {
            // clear the promise that contains the stale token
            this.getAccessTokenPromise = null;

            // post a message to the main application to get the access token
            const msg = {
              method: 'vbRefreshOAuthAccessToken',
            };
            return Utils.postMessage(client, msg)
              .then((token) => {
                this.accessToken = token;
                return token;
              })
              .catch((err) => {
                logger.error(err);
                return null;
              })
              .finally(() => {
                // clear the promises so we can refresh the token again next time
                this.refreshAccessTokenPromise = null;
              });
          });

        return this.refreshAccessTokenPromise;
      }

      /**
       * Append the Authorization:Session|Public header.
       *
       * @param request the request to which to append the header
       */
      handleRequestHook(request, client) {
        return Promise.resolve().then(() => {
          if (this.useAccessToken
            && request.headers.get(CommonConstants.Headers.USE_OAUTH_ACCESS_TOKEN_WHEN_AVAILABLE)) {
            return this.getAccessToken(client).then((accessToken) => {
              if (accessToken) {
                return FetchHandlerPlugin.getRequestConfig(request, this.fetchHandler).then((config) => {
                  config.headers.authorization = `Bearer ${accessToken}`;
                  config.credentials = 'omit'; // make sure we omit all the cookies

                  return this.fetchHandler.createRequest(request.url, config);
                });
              }

              return undefined;
            });
          }

          return undefined;
        });
      }

      /**
       *
       * @param response
       * @param origRequest
       * @param request
       * @param client
       * @returns {Promise<Boolean>}
       */
      handleResponseHook(response, origRequest, request, client) {
        return Promise.resolve().then(() => {
          if (this.useAccessToken && Utils.shouldRefreshAccessToken(response)) {
            const authHeader = request.headers.get('Authorization');

            logger.info('401 response detected for', origRequest.url, 'with authorization header', authHeader);

            return this.refreshAccessToken(client).then((token) => {
              if (!token) {
                logger.error('Failed to refresh access token');
              }
              return !!token; // retry the request if we have a new access token
            });
          }

          return false;
        });
      }
    }

    return OAuthAccessTokenHandlerPlugin;
  });

