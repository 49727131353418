'use strict';

define('vb/extensions/dynamic/private/models/context/layoutResourceContext',['vb/private/stateManagement/context/containerContext'], (ContainerContext) => {
  /**
   * Context object for a LayoutResource.
   */
  class LayoutResourceContext extends ContainerContext {
    static get BaseContextType() {
      return LayoutResourceContext;
    }

    /**
     * @param layoutResource
     * @returns {Object}
     */
    static getAvailableContexts(layoutResource) {
      const availableContexts = super.getAvailableContexts(layoutResource);
      const filteredContexts = {};

      ['$functions', '$modules', '$translations'].forEach((key) => {
        const propDesc = Object.getOwnPropertyDescriptor(availableContexts, key);

        Object.defineProperty(filteredContexts, key, propDesc);
      });

      return filteredContexts;
    }
  }

  return LayoutResourceContext;
});

