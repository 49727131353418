'use strict';

define('vbsw/private/plugins/sessionExpirePlugin',['vbsw/api/fetchHandlerPlugin', 'vbc/private/log', 'vbsw/private/utils'],
  (FetchHandlerPlugin, Log, Utils) => {
    const S_BE_ROOT_REQUEST = Symbol('beRootRequest');
    const logger = Log.getLogger('/vbsw/private/plugins/sessionExpirePlugin');

    /**
     * Notes:
     * SSO login e.g. https://login.dc1.c9dev1.oraclecorp.com/oam/server/obrareq.cgi
     * does not allow CORS requests (missing Access-Control-Allow-Origin: * header)
     *
     * So if a request is redirected to SSO login by cloud gate the request end up trigger
     * #handleErrorHook. In its context it is not possibly to detect why the error happened
     * (i.e. it is a consequence of a resource being redirected to non-cors page)
     * so we need to use some more tricks to figure out the cause of the error.
     */
    class SessionExpirePlugin extends FetchHandlerPlugin {
      constructor(context, params = {}) {
        super(context);
        this.contextRoot = params.contextRoot || '/';
        this.idcsHost = params.idcsHost;

        // used by unit tests
        this.backendRootUrl = params.backendRootUrl;

        // strip off the port number, 443, if any so we can match requests without the port number
        if (this.idcsHost) {
          const match = this.idcsHost.match(/^(.+):\d+/);
          if (match && match.length >= 2) {
            this.idcsHost = match[1];
          }
        }
      }

      handleResponseHook(response, origRequest, request, client) {
        // ignore backendRoot request
        if (origRequest[S_BE_ROOT_REQUEST] === true) {
          return Promise.resolve(false);
        }

        // If the actual status from the proxied request is 401 then the request to the backend has succeeded so
        // the session is fine. Retrying won't help here as it is up to the token relay plugin to refresh the token
        // in this case.
        const actualStatus = response.headers.get('vb-proxy-status-actual');
        if (actualStatus && actualStatus.startsWith(401)) {
          return Promise.resolve(false);
        }

        const vbcsBackendUrl = this._getBackendRootURL();
        // X-AppBuilder-Unauthorized is for internal compute only
        if (response.status === 401 && request.url.startsWith(vbcsBackendUrl)
            && (response.headers.get('X-AppBuilder-Unauthorized') || SessionExpirePlugin.isInvalidSession(response))) {
          // got a 401 from a request to the VBCS backend which is likely due to session expiration
          return this._fireSessionExpiredEvent(request.url, client);
        }

        if (
          response.type === 'opaque'
          && response.ok === false
          && response.status === 0
          && request.url.startsWith(vbcsBackendUrl)
          && request.mode === 'no-cors'
        ) {
          // redirected response to IDCS refresh session resource in no-cors mode
          return this._fireSessionExpiredEvent(request.url, client);
        }

        if (
          response.type === 'cors'
          && response.redirected === true
          && response.status === 200
          && response.ok === true
          && response.url.startsWith(this.idcsHost)
          && request.url.startsWith(vbcsBackendUrl)
          && request.mode === 'cors'
        ) {
          // redirected response to IDCS refresh session resource in cors mode
          return this._fireSessionExpiredEvent(origRequest.url, client);
        }

        return Promise.resolve(false);
      }

      _getBackendRootURL() {
        return this.backendRootUrl ? this.backendRootUrl : `${self.location.origin}${this.contextRoot}`;
      }

      handleErrorHook(error, origRequest, modifiedRequest, client) {
        // an error has occurred - there's no detail whatsoever so we need to figure out
        // if this could be caused by the expired session. In this case cloud gate responses
        // with 302 and redirects to SSO login or Session refresh resource.
        // At least in the first case the server does not return CORS headers so browser
        // does not allow the request to go through. To check if this is the case lets initiate
        // fetch with redirect = manual so we can then detect if the error was caused by redirect that has failed

        // for now, we're only interested in detecting session expire on requests to our own (VBCS) resources
        const rootResource = this._getBackendRootURL();
        if (
          !modifiedRequest.url.startsWith(rootResource)
          // ignore backendRoot request
          || origRequest[S_BE_ROOT_REQUEST] === true
        ) {
          return Promise.resolve(false);
        }

        const request = new Request(rootResource, {
          redirect: 'manual', // manual mode so we don't get through the redirects
        });
        // mark backendRoot request
        request[S_BE_ROOT_REQUEST] = true;

        // XXX for testing only !!!! this should cause the code to think the root resource has redirected as well and
        // hence initiate the refresh flow const
        // rootResource = 'http://localhost:8080/resources/application/redirectmedifferentdomain';

        // request the root resource of the domain to test if it returns something or is redirected again
        return fetch(request).then((response) => {
          if (response.status === 200 && !response.redirected) {
            // main page responded properly so the error has likely a different cause than session expiry
            // let the erroneous response fall through
          } else if (response.type === 'opaqueredirect') {
            // well we got opaque redirect response for request with manual redirect ->
            // that means the root resource has been redirected. We can't really figure out where
            // but it's likely this is session expiry so lets initiate the refresh flow.
            return this._fireSessionExpiredEvent(origRequest.url, client);
          } else {
            // some other state we don't understand or expect so just
            // let the erroneous response fall through
            // it also ends here in runtime when user doesn't have VB designer privilege - backend fetch to
            // `rootResource` returns status code 403 without redirect
          }
          return false;
        }).catch((err) => {
          // the test request to root domain resource has failed, this is suspicious
          // let the erroneous response fall through
          logger.error('A testing request to domain root resource', rootResource,
            'has failed even if redirect mode had been set to manual', err);
          // let the erroneous response fall through
          return false;
        });
      }

      /**
       * Fires a "session expired" event to the main thread and waits for its response.
       *
       * The semantic of the expected response obtained from #postMessage is following:
       * true - the session has been refreshed - the request should be retried
       * false - the session couldn't be refreshed - just give up
       *
       * @param originalUrl
       * @param client
       * @private
       */
      _fireSessionExpiredEvent(originalUrl, client) {
        logger.info('session expire detected for', originalUrl);
        const msg = {
          method: SessionExpirePlugin.vbSessionExpired,
          args: [originalUrl],
        };
        return Utils.postMessage(client, msg)
          .then((result) => {
            if (result) {
              logger.info('SSO session has been successfully refreshed');
            } else {
              logger.warn('Couldn\'t refresh SSO session');
            }
            return result;
          })
          .catch((error) => {
            logger.error('An attempt to refresh SSO session has failed:', error);

            // got an error so there's no point retrying
            return false;
          });
      }

      /**
       *
       * @param response
       * @returns {boolean}
       */
      static isInvalidSession(response) {
        const wwwAuthHeader = response.headers.get('WWW-Authenticate');

        if (wwwAuthHeader && wwwAuthHeader.startsWith('Bearer')
          && wwwAuthHeader.includes('error="invalid_session')) {
          return true;
        }
        return false;
      }
    }

    /**
     * Name of the event that's being fired by this plugin to the main thread.
     * @type {string}
     */
    SessionExpirePlugin.vbSessionExpired = 'vbSessionExpired';

    return SessionExpirePlugin;
  });

