'use strict';

define('vbsw/private/plugins/headerTidyHandlerPlugin',['vbsw/api/fetchHandlerPlugin'], (FetchHandlerPlugin) => {
  const HEADERS = /^vb-.*/;

  /**
   * Handler plugin for tidying up headers.
   */
  class HeaderTidyHandlerPlugin extends FetchHandlerPlugin {
    /**
     * Remove intra plugin headers, to prevent COORS issues later
     */
    // eslint-disable-next-line class-methods-use-this
    handleRequestHook(request) {
      const headersToDelete = [];

      // On Firefox and IE, deleting the headers while iterating messes up the iterator. Work around
      // the issue by using an array, headersToDelete, to keep track of headers to delete.
      for (const header of request.headers.keys()) {
        // delete all "vb-*", except for "vb-proxy-*"
        if (HEADERS.test(header) && !header.startsWith(Constants.HEADER_PROXY_PREFIX)) {
          headersToDelete.push(header);
        }
      }

      headersToDelete.forEach((header) => {
        request.headers.delete(header);
      });

      return Promise.resolve();
    }
  }

  return HeaderTidyHandlerPlugin;
});

