'use strict';

define('vbsw/private/utils',['vbc/private/utils', 'vbc/private/pwa/pwaUtils'], (CommonUtils, PwaUtils) => {
  /**
   * Utils class
   *
   *
   *
   */
  class Utils extends CommonUtils {
    /**
     * Return a promise to load an array of resources.
     * Reject with error if there was an error or the resources don't exist.
     *
     * @param resources an array of paths to the resources to load
     * @returns {Promise} a promise to return an array of loaded resources
     */
    static getResources(resources) {
      return new Promise((resolve, reject) => {
        require(resources,
          (...loaded) => {
            resolve(loaded);
          }, reject);
      });
    }

    /**
     * If client is a string, i.e., a client id, use clients.get to resolve it. Otherwise, return it as is.
     *
     * @param client the client to resolve
     * @returns {*}
     */
    static resolveClient(client) {
      if (typeof client === 'string') {
        return self.clients.get(client);
      }
      return Promise.resolve(client);
    }

    /**
     * Invoke the given method on the fetch handler and post the response back to the client.
     *
     * If the target or method name are invalid, send a standard error response.
     *
     * @param target the target of the method invocation
     * @param methodInfo info containing method name, arguments and options
     * @param client the client associated with the caller
     */
    static invokeMethod(target, methodInfo, client) {
      const method = methodInfo.method;

      const nonce = (target && target.config && target.config.nonce) || '';

      if (!target || !target[method] || typeof target[method] !== 'function') {
        const msg = `unable to invoke method "${method}" on target ${target}`;
        console.error(msg);
        // return an 'error 'response
        Utils.postResponse(client, Promise.reject(new Error(msg)),
          `Service Worker (${nonce}): ${method}`);
        return;
      }

      const args = methodInfo.args || [];
      const options = methodInfo.options || {};

      // make the client available to the callee so it can be used to communicate back to the caller
      if (options.addClientToArgs) {
        args.push(client);
      }


      Utils.postResponse(client, target[method](...args),
        `Service Worker (${nonce}): ${method}`);
    }

    /**
     * Post the given message to the given client.
     *
     * @param client can either be a client instance or a client id that resolves to a client instance
     * @param message the message to post
     * @returns {Promise}
     */
    static postMessage(client, message) {
      // send a message to the service worker to install the plugins
      return new Promise((resolve, reject) => {
        const msgChannel = new MessageChannel();

        msgChannel.port1.onmessage = (event) => {
          console.log('Message received from client', event.data);
          const data = event.data;

          if (data.method) {
            // if the message is a method invocation, invoke it by posting it to the message handler
            // that can handle it and post the response back via message port 0
            Utils.postResponse(event.ports[0], Utils.postMessage(null, data), `${data.method}`);
          } else if (data.success) {
            resolve(data.result);
          } else {
            reject(JSON.parse(data.error));
          }
        };

        msgChannel.port1.onmessageerror = () => {
          reject('Failed to send message to client.');
        };

        Utils.resolveClient(client).then((resolvedClient) => {
          if (resolvedClient) {
            resolvedClient.postMessage(message, [msgChannel.port2]);
          } else {
            // if there's no resolved client, that means we are running in emulation or the message
            // is posted on the main thread, so use window to post the message
            window.postMessage(message, '*', [msgChannel.port2]);
          }
        })
        .catch(reject);
      });
    }

    /**
     * Post the result of the response back to the given client. Note that client in this case will always
     * be a message channel port.
     *
     * @param client the client to post the response to
     * @param response the response to be posted
     * @param logHeader a header for the log message
     */
    static postResponse(client, response, logHeader) {
      Promise.resolve(response).then((result) => {
        console.log(logHeader, 'invoked with result:', result);
        client.postMessage({
          success: true,
          result,
        });
      }).catch((error) => {
        console.log(logHeader, 'invoked with error:', error);
        client.postMessage({
          success: false,
          error: JSON.stringify(error, Utils.replaceErrors),
        });
      });
    }

    /**
     * utility function for JSON.stringify(Error), which normally just returns "{}"
     * @param key
     * @param value
     * @returns {*}
     * @private
     */
    static replaceErrors(key, value) {
      if (value instanceof Error) {
        const error = {};
        Object.getOwnPropertyNames(value).forEach(function (k) {
          error[k] = value[k];
        });
        return error;
      }
      return value;
    }


    /**
     * Extract the payload from the JWT token.
     *
     * @param jwt the JWT token
     * @returns {Object}
     */
    static extractJwtPayload(jwt) {
      try {
        // the JWT has has three parts separated by . and the payload is the second part
        let base64Payload = jwt.split('.')[1];

        // reverse url encoding
        base64Payload = base64Payload.replace(/-/g, '+').replace(/_/g, '/');

        // base64 decode the payload and parse it into a JSON object
        const payload = JSON.parse(atob(base64Payload));

        return payload;
      } catch (err) {
        // not a JWT token so simply return null
        return null;
      }
    }

    /**
     * Return the current Unix time in seconds.
     *
     * @param inMilli if false, return seconds, otherwise return milliseconds
     * @returns {number}
     */
    static getEpochTime(inMilli = false) {
      const time = new Date().getTime();

      return inMilli ? time : Math.round(time / 1000);
    }

    /**
     * Extract the expiration time and calculate the server skew from the token's payload.
     *
     * @param jwt the JWT token
     * @returns {Object}
     */
    static extractJwtExpiration(jwt) {
      const payload = Utils.extractJwtPayload(jwt);

      if (payload && payload.exp && payload.iat) {
        const currentTime = Utils.getEpochTime();

        // calculate the server time skew based on iat and the current time on the client machine
        const skew = currentTime - payload.iat;

        const expiration = {
          time: payload.exp,
          skew,
        };

        return expiration;
      }

      return null;
    }

    /**
     * Return true if a token has expired based on the extracted expiration time and server skew,
     * false otherwise.
     *
     * @param expiration expiration object returned from extractJwtExpiration
     * @param skewTolerance allowable clock skew tolerance
     * @returns {boolean}
     */
    static checkJwtExpiration(expiration, skewTolerance) {
      if (expiration) {
        const currentClientTime = Utils.getEpochTime();

        // the current server time is the current client time minus the clock skew between the
        // client and the server
        const currentServerTime = currentClientTime - expiration.skew;

        // If the current server time exceeds the expiration time, then the token has expired.
        if (currentServerTime >= expiration.time) {
          return true;
        }

        // Otherwise, if the current client time exceeds the expiration time by more than
        // the allowable clock skew tolerance, then the token has expired.
        if ((currentClientTime - expiration.time) >= skewTolerance) {
          return true;
        }
      }

      return false;
    }

    /**
     * Generates a sufficiently long ID to be virtually unique. This follows the UUID4
     * specification standard and is unique enough for client generated primary keys.
     * @static
     */
    static generateUID() {
      let d = Date.now();
      const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (d + (Math.random() * 16)) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
      return uuid;
    };

    //
    // Here to make the API public; but will be deprecated when cookie-store
    // becomes available
    //

    /**
     * @param client The client that is making the request
     * @param name The name of the cookie fetch
     * @returns {Promise} That returns the value of the cookie or undefined if not set
     */
    static getCookie(client, name) {
      // post a message to the main application to get the cookie
      const msg = {
        method: 'vbGetCookie',
        args: [name],
      };
      return Utils.postMessage(client, msg);
    }

    /**
     * @param client The client that is making the request
     * @param name The name of the cookie to remove
     * @returns {Promise} Returns resolve if cookie is deleted
     */
    static deleteCookie(client, name) {
      // post a message to the main application to get the cookie
      const msg = {
        method: 'vbDeleteCookie',
        args: [name],
      };
      return Utils.postMessage(client, msg);
    }

    /**
     * @param client The client that is making the request
     * @param name the name of the cookie
     * @param cookie Other values other than name/value are rendered as per
     * https://wicg.github.io/cookie-store/#set-cookie-algorithm
     * @returns {Promise} Resolved if the cookie is set
     */
    static setCookie(client, name, cookie) {
      // post a message to the main application to set the cookie
      const msg = {
        method: 'vbSetCookie',
        args: [name, cookie],
      };
      return Utils.postMessage(client, msg);
    }

    /**
     * create a regex object from the scope for url matching, but also optionally match
     * a possible matrix parameter for the profile at the end.
     * assume the last segment is the version - remove it, and match anything for that segment.
     *
     * this scope:
     *    "/ic/builder/some/path/1.0/"
     * will create this RegExp:
     *    "/ic/builder/some/path/[^/]+/"
     *
     * and will match the following:
     *   "/ic/builder/rt/blou38121App2/1.0/"
     *   "/ic/builder/rt/blou38121App2/1.0;profile=x/"
     *
     * example URLs that would match the created RegExp
     * These are not for the same app/scope, deliberately, for illustration of possible URL values:
     *   proxy:        /ic/builder/some/path/1.0;profile=x/services/...
     *   token relay:  /ic/builder/design/appForMB/37983kjdh83hb=dd?/services/auth/tokenrelay/crmRestApiLatestDescribe
     *   bo:           /ic/builder/rt/appForMB/1.0/resources/data/Test?limit=25&offset=0&fields=id
     *
     * @param s fetchHandler.scope
     * @param config fetchHandler config
     * @returns {string}
     */
    static getRegexForAppScope(s, config) {
      const scope = s || '/';
      // check if this is a hosted url
      if (scope.indexOf('ic/builder') >= 0) {
        const parts = scope.split('/');

        // need to remove '1.0/', which count as two parts
        let maxSegmentsToPop = 2;

        // Since new service worker is located at app root:
        // https://masterdev-vboci.integration.test.ocp.oc-test.com/ic/builder/rt/rx/1.0/mobileApps/abc/
        // two more segments need to be popped to get to:
        // https://masterdev-vboci.integration.test.ocp.oc-test.com/ic/builder/rt/rx/
        if (PwaUtils.isWebPwaConfig(config)) {
          maxSegmentsToPop = 4; // /, abc, mobileApps, 1.0
        }
        // this code assumes the last segment is the version string, and the scope ends with a slash.
        // remove the last segment, which should be a blank when it ends with a slash.
        let popped = 1;
        const lastSegment = parts.pop();
        while (!lastSegment && popped < maxSegmentsToPop && popped < parts.length) {
          parts.pop();
          popped += 1;
        }
        const prefix = parts.join('/');
        return `${prefix}/[^/]+/`;
      }
      // not hosted, just return (happens with samples)
      return scope;
    }

    /**
     * Generate the token url from the given baseUrl and serviceName.
     *
     * @param baseUrl the base url
     * @param serviceName the service name
     * @returns {string}
     */
    static getTokenRelayUrl(baseUrl, serviceName) {
      return `${baseUrl}/services/auth/1.1/tokenrelay/${serviceName}`;
    }

    /**
     * Generate the proxy url from the given baseUrl and serviceName.
     *
     * @param baseUrl the base url
     * @param serviceName the service name
     * @returns {string}
     */
    static getProxyUrl(baseUrl, serviceName) {
      // using new 1.1 proxy, which requires prefixed headers
      return `${baseUrl}/services/auth/1.1/proxy/${serviceName}/uri/`;
    }

    /**
     * Return true if the response is a 401 response and its WWW-Authenticate header indicate invalid
     * access token.
     *
     * @param response the response to check
     * @returns {boolean}
     */
    // eslint-disable-next-line class-methods-use-this
    static shouldRefreshAccessToken(response) {
      if (response.status === 401) {
        const wwwAuthHeader = response.headers.get('WWW-Authenticate');

        if (wwwAuthHeader && wwwAuthHeader.startsWith('Bearer')
          && wwwAuthHeader.includes('error="invalid_token')) {
          // Assuming bearer tokens, as per https://tools.ietf.org/html/rfc6750#section-3.1
          // Missing headers
          return true;
        }

        if (wwwAuthHeader && wwwAuthHeader.toLowerCase() === 'token') {
          // As per BUFP-42343 Salesforce doesn't implement the standard properly so
          // we are making an exception for a de-facto standard because of the size of the
          // API usage
          return true;
        }
      }

      return false;
    }
  }

  return Utils;
});

