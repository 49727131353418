'use strict';

define('vb/types/extendedType',['vb/private/log', 'vb/private/constants', 'vb/types/typeUtils'], (Log, Constants, TypeUtils) => {
  /**
   * shared logger for all FetchContext instances
   * @type {Log}
   */
  const LOGGER = Log.getLogger('/vb/types/ServiceDataProvider.FetchContext', undefined);

  /**
   * ExtendedType is the base class for all types used in VB (builtin and custom). A custom type that extends
   * from this class can be used as a VB type on a variable, and provides the ability for the type instance to store
   * its 'value' and state in in redux, and use VB framework features as well.
   *
   * VB Variables can have a type that points to a class. The runtime discovers this by detecting that the type
   * has a forward slash in the type name (i.e. my/Type). The type is assumed to be a require path and require loads
   * it..
   *
   * ExtendedTypes also have a value (defined by 'defaultValue' property) that can be used for configuration.
   * For example:
   *
   * <code>
   *  "myVariable": {
   *    "type": "my/Type",
   *    "defaultValue": {
   *      "myPropertyOne": "foo",
   *      "myPropertyTwo": {
   *        "foo: "bar",
   *        "hello": "{{ 'hello' + 'world' }}"
   *      }
   *    }
   *  }
   * </code>
   *
   * The value itself is created as a variable (and thus has all the semantics of variable, including variable change
   * (onValueChanged) events.
   *
   * The type of the value is defined via the 'getTypeDefinition' function, which returns the type as if it
   * were defined directly on the variable (in a typical page model).
   *
   * The value is accessible in expressions via '$scope.variables.theInstance.value' where $scope is
   * $page/$flow etc., and 'theInstance' is the instance of this custom type that is created.
   *
   * For this reason, this will overlay any local 'value' property, so be careful not to use that property internally!!
   * This should not be a problem, due to the use of get/setInternalState for internal state storage that can be
   * used for any internal state that you wish to track.
   *
   * As a convenience, if the type of this variable as defined in 'getTypeDefinition' is 'object', all root properties
   * of the values will be hoisted to the root variable - that is they will be accessible via
   * '$scope.variables.theInstance.property' in addition to '$scope.variables.theInstance.value.property'. If this is
   * not desired, return false from 'hoistValueObjectProperties'.
   *
   * Custom types should externalize all their internal state so that it can be captured in redux. To facilitate
   * this, use get/setInternalState convenience methods.
   *
   * A variable goes through various lifecycle stages and extended types will be notified of these stages via the
   * init, activate and dispose methods.
   * The framework calls various lifecycle methods on custom type variables, at various points in time. The 'init'
   * method is called when a variable of this type is being created in a scope. At the 'init' stage the defaultValue
   * of the variable is not fully determined because its value might depend on other variables being initialized.
   * The 'activate' method is called when this and other variables in the current scope have been created and its
   * initial (default) value determined. This method is called right before vbEnter event and the value of the variable
   * at this time will not account for any value assigned in the vbEnter action chains.
   * The 'dispose' method is called when the current scope is being torn down and all variables, including this
   * variable is being disposed. This would be a good time to cleanup state.
   *
   * To be notified of the variable's value changes the 'handlePropertyValueChanged' function is provided for
   * custom type authors to override.
   *
   * Additionally custom type implementations have the ability to fire an event using 'invokeEvent', providing a
   * name, payload, and can retrieve the exploded type structure given a type definition, using the 'getType'
   * method. Both these methods use a 'handler' property setup on the extended type (see vb/helpers/ExtendedTypeHandler
   * for the contract).
   *
   */
  /* eslint class-methods-use-this: ["error", { "exceptMethods": ["dispose", "isExtendedType","getWritableOptions",
   "getTypeDefinition", "hoistValueObjectProperties", "handlePropertyVariableChangeEvent"] }] */
  class ExtendedType {
    /**
     * Returns the type definition for the value of this custom type. This should match the type definition
     * syntax of the page model, and is essentially the values of the 'type' property of a variable.
     *
     * For example, to define a string:
     *
     * <code>
     *   return (
     *     {
     *       type: 'string'
     *     }
     *   );
     * </code>
     *
     * And an object:
     *
     * <code>
     *   return (
     *     {
     *       type: {
     *         someValue: 'string'
     *       }
     *     }
     *   );
     * </code>
     *
     * In special cases the type definition might be complex where the type definition of a property might need
     * to resolve its type. let's take an example, where the value of myExtendedType has 2 properties - data
     * and dataItemType,
     * - 'dataItemType' refers to the name of type defined under 'types' property in your page model and
     * - 'data' is  an dataItemType[]
     *
     * IOW, when I define a variable of myExtendedType in the page model, it will look like below -
     * {
     *   "variables": {
     *     "type": "vb/package/myExtendedType',
     *     "defaultValue": {
     *       "data": "someType[]",
     *       "dataItemType": "someType"
     *     }
     *   },
     *   "types": {
     *     "someType": {
     *       "name": "string",
     *       "age": "number"
     *     }
     *   }
     * }
     * Note: the module that loads the specific type will be defined under the imports section of the container. Example
     * {
     *   "imports": {
     *     "types": {
     *       "vb/package/myExtendedType": "my/requirePath/toMyExtendedType"
     *     }
     *   }
     * }
     *
     * In order to resolve the type of 'data' property to its final structure you may utilize the TypeUtils.getType()
     * method passing in the typeDef as { type: 'someType[]' } for the second parameter.
     *
     * So for example, the override of this method in myExtendedType would return,
     * return(
     *    {
     *      type: {
     *        data: TypeUtils.getUtils('dataTypeName', { type: 'someType[]' }, scopeResolver),
     *        itemType: 'string'
     *      },
     *      resolved: true,
     *    }
     *  );
     * Note: the property 'resolved' set to true tells the framework the type definition has been resolved.
     *
     * @param variableDef the declarative definition of this variable
     * @param {Object} scopeResolver the resolver object (application, page, ...)
     * @returns {*} A structure that defines the type of the value or null if no type exists for the value
     */
    constructor() {
      this.handler = null;
    }

    // eslint-disable-next-line no-unused-vars
    getTypeDefinition(variableDef, scopeResolver) {
      return null;
    }

    /**
     * If true and the type of the custom type is 'object', all root properties of the object will be hoisted
     * to the instance, so that they are a direct property of the instance (in addition to being accessible
     * via the 'value' property). When returning false from this method type authors must understand that reading
     * value of a property via an expresion will not work. Example, $page.variables.myType.fooProp will return
     * undefined, if fooProp is a supported property of the variable value.
     *
     * @returns {boolean}
     */
    hoistValueObjectProperties() {
      return true;
    }

    /**
     * Initialization code called when the variable in being created. At this time the variable is not fully created
     * and its 'value' is not setup/available. Neither is its internalState wired up.
     * The defaultValue is not its final value after full evaluation, so when overriding this method be cautious
     * about initializing the value of the variable as it might be premature.
     * Note: This method is deprecated in favor of activate, as the latter method is a more appropriate time in the
     * lifecycle of the variable to perform any initialization
     *
     * @param name of the variable
     * @param variableDef definition
     * @param defaultValue variable default value (not its fully resolved initial value)
     * @param container container this variable belongs to
     * @deprecated
     */
    // eslint-disable-next-line no-unused-vars
    init(name, variableDef, defaultValue, container) {
      this.setId(name);
    }

    /**
     * Called during the variable lifecycle when it is in active stage. Subclasses override this method to read the
     * (fully resolved) value of the type before the vbEnter event.
     */
    // eslint-disable-next-line class-methods-use-this
    activate() {
      // no op
    }

    /**
     * Called when a variable is being disposed, this method is noop but custom type implementors can override to
     * cleanup their internal state.
     */
    dispose() {}

    /**
     * Get the variable id as defined in the page model.
     *
     * @return {string} The id for this variable
     * @final
     */
    // eslint-disable-next-line no-unused-vars
    getId() {
      return this.id;
    }

    /**
     * Sets the variable id as defined in the page model.
     *
     * @final
     * @param id {string} The id for this variable
     */
    setId(id) {
      this.id = id;
    }

    /**
     * Gets the value (if a type is defined). It's important to note that the value returned from this call might
     * include properties that are resolved on demand, and so might still be ko computeds. In such cases it's
     * important to read the property value using ko methods. Example,
     * {code}
     * ko.isObservable(propValue) ? propValue() : propValue;
     * {code}
     *
     * @final
     * @returns {*}
     */
    getValue() {
      return this.value;
    }

    /**
     * Sets the value (if a type is defined).
     *
     * @final
     * @param value The value
     */
    setValue(value) {
      this.value = value;
    }

    /**
     * Gets internal state for this instance.
     *
     * @param name The name of the state to fetch
     * @returns {*} the state of the named property or the entire state
     */
    getInternalState(name) {
      return name ? TypeUtils.getPropertyValue(this.internalState, name) : this.internalState;
    }

    /**
     * Sets internal state for this instance.
     *
     * @param name The name of the state
     * @param value The value
     */
    setInternalState(name, value) {
      if (name) {
        const stateValue = Object.assign({}, this.internalState);
        TypeUtils.setPropertyValue(stateValue, name, value);
        // to force variable change on internalState mutate it directly.
        this.internalState = stateValue;
      } else {
        LOGGER.error('unable to set internal state property', name, 'to', value);
      }
    }

    /**
     * Called when a variable or one or more of its property has changed value.
     *
     * @param e Event payload holding the name, type, oldValue, value, diff properties. The diff is in the format
     * put out by the plugin - https://github.com/benjamine/jsondiffpatch
     */
    // eslint-disable-next-line no-unused-vars
    handlePropertyVariableChangeEvent(e) {}

    /**
     * Allows the mixin to inform that is has injected capabilities of being an extended type. An extended type
     * informs the framework that the type can be associated to a variable among other things.
     *
     * @returns {boolean} true
     */
    isExtendedType() {
      return true;
    }

    /**
     * Returns special keywords indicating whether (variable value) properties are writable or not.
     * - 'all', all properties and sub-properties can be set. (default)
     * - 'none', implying no properties within the variable are writable. The entire
     * variable needs to be updated each time, which is the behavior of assignVariables action
     * anyway. This also limits the ability for components to write values directly to properties.
     * @return {object}
     */
    getWritableOptions() {
      return { propertiesWritable: Constants.VariableWritablePropertyOptions.ALL };
    }

    /**
     * Returns the fully resolved type information for the provided type.
     *
     * @param type The name of the type to lookup, or a structure describing the type. Example if one of the
     * properties of the extended type is itself a type reference, it might be required to explode the type further
     * @param description A description of the type (to help with user debugging if the type is incorrect).
     * @returns {*} The normalized type
     * @final
     */
    getType(type, description = this.constructor.name) {
      const { handler } = this;
      return handler && handler.getType(type, description);
    }

    /**
     * Invoke an event on the container that this instance variable belongs to. This allows users of the custom type
     * to register a listener for the said event. Example, if the extended type invokes an event -
     * this. invokeEvent('myCustomTypeEvent', {some: 'payload' }), a user using the extended type can register a
     * listener in the page model like below:
     * {
     *   "eventListeners": {
     *     "myCustomEventType": {
     *       "chains": [{
     *         "chainId": "handleCustomEventChain",
     *         "parameters": {
     *           "payload": "{{ $event.some }}"
     *         }
     *       }]
     *     }
     *   }
     * }
     * @param name of the event
     * @param payload payload for the event
     * @returns {*|Promise}
     * @final
     */
    invokeEvent(name, payload) {
      const { handler } = this;
      return handler && handler.invokeEvent(name, payload);
    }
  }

  return ExtendedType;
});

