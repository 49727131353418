'use strict';

define('vb/private/events/transformPayloadEventBehavior',[
  'vb/private/events/transformEventBehavior',
], (TransformEventBehavior) => {

  /**
   * TransformPayloadEventBehavior, behavior = "transformPayload"
   */
  class TransformPayloadEventBehavior extends TransformEventBehavior {
    /**
     * For the transform behavior, the order is serial where base goes first before any possible extensions.
     * @returns {Generator<*, void, ?>}
     * @param container
     * @param eventModel
     * @param eventWrapper
     * @returns {Array<{ container: Container, fnc: function }>}
     * @override
     */
    curryListeners(container, eventModel, eventWrapper) {
      const functionWrappers = [];
      const fnc = container.invokeEvent.bind(container, eventWrapper.name, eventWrapper.coercedPayload);
      functionWrappers.push({
        container,
        fnc,
      });

      if (eventModel.isInterface) {
        let fncWrappers = [];
        if (container.extensionsArray) {
          container.extensionsArray.forEach((extension) => {
            fncWrappers = this.curryListeners(extension, eventModel, eventWrapper);
            Array.prototype.push.apply(functionWrappers, fncWrappers);
          });
        }
      }

      return functionWrappers;
    }
  }

  return TransformPayloadEventBehavior;
});

