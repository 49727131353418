/* eslint-disable space-before-function-paren,no-useless-concat,default-case,object-shorthand,max-classes-per-file */

'use strict';

define('vb/private/services/ramp/rampTransforms',[
  'vb/private/log',
  'urijs/URI',
  'vb/private/types/dataProviderConstants',
  'vb/private/services/ramp/expandableField',
  'vb/private/services/ramp/filterCriterionUtils'],
(Log, URI, DPConstants, ExpandableField, FilterCriterionUtils) => {
  const logger = Log.getLogger('/vb/private/services/ramp/rampTransforms');
  const COMMA_SEPARATOR = ',';
  const QUERY_AMPERSAND_OP = '&';
  const QUERY_QUESTION_MARK_OP = '?';

  /**
   * Property that defines the text filter attributes
   * @type {string}
   */
  const VB_TEXT_FILTER_ATTRS = 'vb-textFilterAttributes';

  /**
   * simple query function
   * we are skipping undefined and null, but URI.js adds query params without values
   * ('?foo&bar), which aren't strictly valid, but often have meaning. Since this is specific to
   * these transforms skipping both should be fine, but keep it in mind when making changes
   */
  function appendToUrl(url, name, value) {
    // skip undefined and null
    if (value !== undefined && value !== null) {
      const sep = url.indexOf(QUERY_QUESTION_MARK_OP) >= 0
        ? QUERY_AMPERSAND_OP : QUERY_QUESTION_MARK_OP;

      // bufp-26058
      // encoding just the value; encoding the whole query string would cause issues when
      // using URIjs to parse the url, and then using URI.parseQuery to parse the query portion
      const valueAsString = value.toString();
      // only add the query param if it has a non-blank value
      if (valueAsString) {
        return `${url}${sep}${name}=${URI.encodeQuery(valueAsString)}`;
      }
      logger.warn('skipping empty transform parameter:', name);
    }
    return url;
  }

  function queryParamExists(url, name) {
    const q = url.indexOf(QUERY_QUESTION_MARK_OP);
    if (q >= 0) {
      return (url.indexOf(`${QUERY_QUESTION_MARK_OP}${name}`) === q)
        || (url.indexOf(`${QUERY_AMPERSAND_OP}${name}`) > q);
    }
    return false;
  }

  function tagFunctionsAsBuiltIn(functionMap) {
    const fnMap = functionMap;
    Object.keys(fnMap || {}).forEach((key) => {
      // put a property on the function so we can identify this as built-in, after its bound
      fnMap[key].doesQueryEncoding = true;
    });
  }

  /**
   * Given a key locates a property with the key and returns the value. Otherwise null.
   * @param object
   * @param key
   * @returns {{value: *, key: *}|null}
   */
  function getKeyValueInJSON(object, key) {
    if (Object.prototype.hasOwnProperty.call(object, key) && object[key]) {
      return { key: key, value: object[key] };
    }
    let i;
    let o;
    // eslint-disable-next-line no-plusplus
    for (i = 0; i < Object.keys(object).length; i++) {
      if (typeof object[Object.keys(object)[i]] === 'object') {
        o = getKeyValueInJSON(object[Object.keys(object)[i]], key);
        if (o != null) {
          return o;
        }
      }
    }

    return null;
  }

  const RequestTransforms = (() => {
    /**
     * paginate
     * @param configuration
     * @param options
     * @param context a transforms context object that can be used by authors of transform
     * functions to store contextual information for the duration of the request.
     * @returns {*}
     */
    // eslint-disable-next-line no-underscore-dangle,no-unused-vars
    function _paginate(configuration, options, context) {
      const c = configuration;

      if (options) {
        let size = options.size;

        if (size < 0) {
          size = 1000; // reasonably large number
        }

        c.url = appendToUrl(c.url, 'limit', size);
        c.url = appendToUrl(c.url, 'offset', options.offset);
      }
      return c;
    }

    /**
     * The filter transform parses the text filter that may be part of the options and replaces
     * it with an appropriate attribute filter criterion using the attributes specified using vb-textFilterAttrs.
     *
     * Note: select-single provides a text filter in the form { text: 'someTextToSearch' }.
     * @param configuration
     * @param options
     * @param transformsContext
     *
     */
    function processTextFilter (configuration, options, transformsContext) {
      const tc = transformsContext;
      const textFilterAttributes = tc && tc[VB_TEXT_FILTER_ATTRS];
      let o = options;
      let textValue;
      let isCompound;

      // text filter criterion is either part of a compoung criterion or is by itself
      if (o && FilterCriterionUtils.isCompoundCriterion(o)) {
        // the first criteria is the text filter
        const textCriterion = o.criteria[0];
        textValue = textCriterion.text;
        isCompound = true;
      } else if (FilterCriterionUtils.isTextCriterion(o)) {
        textValue = o && o.text;
      }

      // allow '' values to perform a query
      if (textValue !== undefined && textValue !== null) {
        // choose the text fields that will be used in the text filtering and build a regular
        // attribute criterion
        const textFieldsToQuery = textFilterAttributes || [];
        const tfcrs = { op: '$or', criteria: [] };
        textFieldsToQuery.forEach((tf) => {
          const tqfc = { op: '$sw', attribute: tf, value: textValue };
          tfcrs.criteria.push(tqfc);
        });

        if (isCompound) {
          o.criteria[0] = tfcrs;
        } else {
          o = tfcrs;
        }
      }

      return o;
    }

    /**
     * filter transform that takes a filterCriterion object. The filter transform only supports
     * transforming structures that one of these 2 forms.
     *
     * Simple Attribute Criterion:
     * {op: '$eq', attribute: 'foo', value: 'bar'}
     *
     * Compound Criterion:
     * {
     *   op: '$or', // $and
     *   criteria: [
     *    {op: '$eq', attribute: 'foo', value: 'bar'},
     *    {op: '$ne', attribute: 'lucy', value: 'fred'},
     *    ...
     *  ]
     * }
     *
     * @param configuration
     * @param options the filterCriterion object that is a JSON structure based on the JET
     * FilterOperator API.
     * @param context a transforms context object that can be used by authors of transform
     * functions to store contextual information for the duration of the request.
     *
     * @see JET FilterOperator
     * @see JET AttributeFilterOperator
     * @see JET CompoundFilterOperator
     * @returns {*}
     * @private
     */
    // eslint-disable-next-line no-underscore-dangle,no-unused-vars
    function _generateQForFilterCriterion(configuration, options, context) {
      const c = configuration;
      const fc = processTextFilter(c, options, context);

      if (typeof fc === 'object' && Object.keys(fc).length > 0) {
        const q = FilterCriterionUtils.buildQueryExpression(c, fc);

        if (q) {
          c.url = appendToUrl(c.url, 'q', q);
        }
      }
      return c;
    }

    /**
     * filter transform that parses the deprecated filterCriteria array, where each item in
     * the array is a criterion with op, value and attribute properties, and where the op is a SCIM
     * operator. See https://tools.ietf.org/html/rfc7644#section-3.4.2.2.
     * @param configuration
     * @param options Array (deprecated) filterCriteria options that used SCIM operators.
     * @returns {*}
     * @private
     */
    // eslint-disable-next-line no-underscore-dangle
    function _generateQDeprecated(configuration, options) {
      const c = configuration;
      let q;

      function isValAcceptable(val, op) {
        return op === 'pr' ? true : !(val === undefined);
      }

      function isScimOperator(aop) {
        return (aop === 'eq'
          || aop === 'ne'
          || aop === 'gt'
          || aop === 'ge'
          || aop === 'lt'
          || aop === 'le'
          || aop === 'sw'
          || aop === 'ew'
          || aop === 'co'
          || aop === 'pr');
      }

      function scimOpToRamp(scimOp, value) {
        switch (scimOp) {
          case 'eq':
            return (value === null) ? 'is null' : `= ${FilterCriterionUtils.encodeValue(value)}`;
          case 'ne':
            return `!= ${FilterCriterionUtils.encodeValue(value)}`;
          case 'gt':
            return `> ${FilterCriterionUtils.encodeValue(value)}`;
          case 'ge':
            return `>= ${FilterCriterionUtils.encodeValue(value)}`;
          case 'lt':
            return `< ${FilterCriterionUtils.encodeValue(value)}`;
          case 'le':
            return `<= ${FilterCriterionUtils.encodeValue(value)}`;
          case 'sw':
            return `LIKE '${value}%'`;
          case 'ew':
            return `LIKE '%${value}'`;
          case 'co':
            return `LIKE '%${value}%'`;
          case 'pr':
            return 'is not null';
        }

        return scimOp;
      }

      if (options && Array.isArray(options) && options.length > 0) {
        options.forEach((criteria) => {
          if (criteria.attribute && isScimOperator(criteria.op) && isValAcceptable(criteria.value, criteria.op)) {
            if (q) {
              q += ' and ';
            } else {
              q = '';
            }
            const criteriaValue = FilterCriterionUtils.escapeSingleQuotes(criteria.value);
            q += `${criteria.attribute} ${scimOpToRamp(criteria.op, criteriaValue)}`;
          }
        });
      }

      if (q) {
        c.url = appendToUrl(c.url, 'q', q);
        // c.url = URI(c.url).addQuery({ q }).toString();
      }

      return c;
    }

    /**
     * filter transform. Builds filter expression query parameter using either the deprecated
     * filterCriteria array or filterCriterion object set on the options.
     *
     * @param configuration
     * @param options
     * @param transformsContext
     * @returns {*}
     */
    // eslint-disable-next-line no-underscore-dangle
    function _filter(configuration, options, transformsContext) {
      if (options && Array.isArray(options) && options.length > 0) {
        return _generateQDeprecated(configuration, options);
      }
      return _generateQForFilterCriterion(configuration, options, transformsContext);
    }

    /**
     * sort
     * @param configuration
     * @param options
     * @param context a transforms context object that can be used by authors of transform
     * functions to store contextual information for the duration of the request.
     * @returns {*}
     */
    // eslint-disable-next-line no-underscore-dangle,no-unused-vars
    function _sort(configuration, options, context) {
      const c = configuration;
      let orderBy;

      if (options && Array.isArray(options) && options.length > 0) {
        options.forEach((criteria) => {
          if (orderBy) {
            orderBy += COMMA_SEPARATOR;
          } else {
            orderBy = '';
          }
          let direction = '';
          if (criteria.direction) {
            direction = criteria.direction === 'ascending' ? ':asc' : ':desc';
          }

          orderBy += criteria.attribute + direction;
        });
      }

      if (orderBy) {
        c.url = appendToUrl(c.url, 'orderBy', orderBy);
      }

      return c;
    }

    /**
     * select
     * Example:
     *
     * Employee
     * - firstName
     * - lastName
     * - department
     *   - items[]
     *     - departmentName
     *     - location
     *        - items[]
     *          - locationName
     *
     * would result in this 'fields' query parameter:
     *
     *   fields=firstName,lastName;department:departmentName;department.location:locationName
     *
     * Another multi-level example:
     *   fields=PartyId;Address:PartyId,AddressId;Address.AddressPurpose:Purpose,AddressPurposeId
     *
     *   {
     *     "PartyId": 100000013637002,
     *         "Address": [
     *             {
     *                 "PartyId": 100000013637002,
     *                 "AddressId": 100000013637005,
     *                 "AddressPurpose": [
     *                     {
     *                         "Purpose": "SELL_TO",
     *                         "AddressPurposeId": 100000013637018
     *                     }
     *                 ]
     *             }
     *         ]
     *     }

     *
     * 'options' has two optional properties:
     * - type: a VB type
     * - fields: an array of fields, whose structure is defined by the dynamic UI components:
     *    type attrs = Array<string | { name: string, attributes?: attrs }>
     *
     * Both 'type' and 'fields' will be used when creating the "fields=" query
     *
     * @param configuration
     * @param options
     * @param context a transforms context object that can be used by authors of transform
     * functions to store contextual information for the duration of the request.
     */
    // eslint-disable-next-line no-underscore-dangle,no-unused-vars
    function _select(configuration, options, context) {
      // the options should contain a 'type' object, to override
      const c = configuration;

      // do nothing if its not a GET
      if (c.initConfig && c.initConfig.method !== 'GET') {
        return c;
      }

      // do nothing if there's already a '?fields='
      if (queryParamExists(c.url, 'fields')) {
        return c;
      }

      // if there's an 'items', use its type; otherwise, use the whole type
      const typeToInspect = (options && options.type && (options.type.items || options.type));

      // this is a structure defined by JET, that we will merge with the type fields, if any
      const additionalFields = (options && options.attributes);

      if ((typeToInspect && typeof typeToInspect === 'object') || additionalFields) {
        const ef = new ExpandableField(typeToInspect || {});
        ef.addComponentFetchAttributes(additionalFields);

        const paramValue = ef.toString();
        const paramName = ef.getQueryParameterName();

        if (paramValue) {
          c.url = appendToUrl(c.url, paramName, paramValue);
        }
      }
      return c;
    }

    /**
     * fetchByKeys is called when the current fetch call is a fetch to get one or more keys.
     * A RAMP getAll endpoint can be used for multikey lookup using 'q' param.
     * A getOne endpoint generally needs a single key to be used in the path param.
     * This transform is called by itself. The other transforms are called only when fetchFirst or fetchByOffset is
     * used.
     * @param {Object} configuration
     * @param configuration.fetchConfiguration configuration for the current fetch call
     * @param configuration.endpointDefinition metadata for the endpoint
     */
    // eslint-disable-next-line no-underscore-dangle
    function _fetchByKeys(configuration) {
      const c = configuration;
      const fetchConfig = c.fetchConfiguration;
      const fetchCall = fetchConfig.capability;
      const fetchKeys = fetchConfig.fetchParameters.keys;
      const endpointParams = c.endpointDefinition.parameters || {};
      const qParam = getKeyValueInJSON(endpointParams, 'q');
      // if there is a 'q' parameter then we are likely dealing with a getAll endpoint that supports fetching
      // multiple keys using the 'q'
      if (fetchCall === 'fetchByKeys' && qParam && qParam.key === 'q') {
        if (fetchKeys && fetchKeys instanceof Set && fetchKeys.size > 0) {
          const idAttribute = fetchConfig.context.keyAttributes || fetchConfig.context.idAttribute;
          if (idAttribute && typeof idAttribute === 'string') {
            const keyCriterion = { op: FilterCriterionUtils.getFilterOpsMap().OR, criteria: [] };
            fetchKeys.forEach((k) => {
              keyCriterion.criteria.push({
                op: FilterCriterionUtils.getFilterOpsMap().EQ,
                attribute: idAttribute,
                value: k,
              });
            });
            return _filter(configuration, keyCriterion);
          }
          logger.warn('The key attribute provided', idAttribute, 'requires that author build a custom fetchByKeys'
            + ' transforms function.');
        }
      }
      const limitParam = getKeyValueInJSON(endpointParams, 'limit');
      const offsetParam = getKeyValueInJSON(endpointParams, 'offset');
      if (fetchCall === 'fetchByKeys' && (!limitParam && !offsetParam && !qParam)) {
        // TODO we have a getOne endpoint, and we do nothing because the url path parameter should already be
        //  substituted if things are setup correctly. But in some rare cases the url path parameter is not
        //  substituted correctly (e.g., when containsKeys is called). We really don't have a clean way to determine
        //  where the key value needs to substituted in the path parameter because RAMP metadata does not give
        //  meaningful information today. So the only solution at the moment is to ask page authors to fixup URL using
        //  SDP#mergeTransformOptions. We are recommending a pattern for them
      }

      return c;
    }

    // wrap public methods
    return {
      filter: (configuration, options, transformsContext) => _filter(configuration, options, transformsContext),
      sort: (configuration, options) => _sort(configuration, options),
      paginate: (configuration, options) => _paginate(configuration, options),
      select: (configuration, options) => _select(configuration, options),
      fetchByKeys: (configuration) => _fetchByKeys(configuration),
    };
  })();

  /**
   * response
   */
  const ResponseTransforms = (() => {
    /**
     * paginate response transform
     * @param configuration the Response object
     * @param context a transforms context object that can be used by authors of transform
     * functions to access/store contextual information for the duration of the request.
     * @returns {{}}
     */
    // eslint-disable-next-line no-underscore-dangle,no-unused-vars
    function _paginateResponse(configuration, context) {
      const tr = {};

      if (configuration.body) {
        const rb = configuration.body;
        // report 0 results
        if (rb.totalResults >= 0) {
          tr.totalSize = rb.totalResults;
        }
        tr.hasMore = rb.hasMore;
      }

      return tr;
    }

    return {
      /**
       * paginate
       * @param configuration the Response object
       * @param context a transforms context object that can be used by authors of transform
       * functions to store contextual information for the duration of the request.
       * @returns {{}}
       */
      paginate: (configuration, context) => _paginateResponse(configuration, context),
    };
  })();

  /**
   * transforms pertaining to a service or endpoint and not tied to a request.
   *
   * @type {{capabilities: (function(*): {})}}
   */
  const MetadataTransforms = (() => {
    /**
     * Returns the capabilities as defined by DataProvider
     * @param configuration
     * @return {Object}
     * @private
     */
    // eslint-disable-next-line no-underscore-dangle
    function _getCapabilities(configuration) {
      const caps = {};
      const c = configuration;
      const epDef = c.endpointDefinition;
      const paramsDef = epDef && epDef.parameters;
      if (paramsDef) {
        let canSort;
        let canFilter;
        let canPaginate;
        let canOffset;
        const queryParamsDef = paramsDef.query || {};

        if (queryParamsDef) {
          const orderByDef = getKeyValueInJSON(queryParamsDef, 'orderBy');
          if (orderByDef) {
            const oVal = orderByDef.value;
            canSort = oVal && oVal.in === 'query' && oVal.name === 'orderBy';
          }
          const filterDef = getKeyValueInJSON(queryParamsDef, 'q');
          if (filterDef) {
            const oVal = filterDef.value;
            canFilter = oVal && oVal.in === 'query' && oVal.name === 'q';
          }
          const paginateDef = getKeyValueInJSON(queryParamsDef, 'limit');
          if (paginateDef) {
            const oVal = paginateDef.value;
            canPaginate = oVal && oVal.in === 'query' && oVal.name === 'limit';
          }
          /**
           * Typically getAll endpoint has these query params that getSingle endpoints don't
           *  "$ref": "#/components/parameters/limit"
           *  "$ref": "#/components/parameters/offset"
           *  "$ref": "#/components/parameters/totalResults"
           *  "$ref": "#/components/parameters/q"
           *  "$ref": "#/components/parameters/orderBy"
           *  In addition unclear if checking for GET method will break POST methods that are masquerading as
           *  GETAll endpoints. So it's probably safe to assume if it supports paginate, then it's a GETALL endpoint
           */
          if (canPaginate) {
            caps.fetchFirst = {
              implementation: 'iteration',
            };
            // if the offset query param is present then there is fetchByOffset
            const offsetDef = getKeyValueInJSON(queryParamsDef, 'offset');
            if (offsetDef) {
              const oVal = offsetDef.value;
              canOffset = oVal && oVal.in === 'query' && oVal.name === 'offset';
            }

            if (canOffset) {
              caps.fetchByOffset = {
                implementation: DPConstants.CapabilityValues.FETCH_BY_OFFSET_IMPLEMENTATION_RANDOM_ACCESS,
              };
            }
          }

          if (canSort) {
            caps.sort = {
              attributes: DPConstants.CapabilityValues.SORT_ATTRIBUTES_MULTIPLE,
            };
          }

          if (canFilter) {
            // TODO: we assume text filtering is always supported for now on getAll endpoints. Unclear what to check for
            caps.filter = {
              operators: Object.values(FilterCriterionUtils.getFilterOpsMap()),
              textFilter: true,
            };
            // to support fetching multiple keys
            caps.fetchByKeys = {
              implementation: 'lookup',
              multiKeyLookup: 'yes',
            };
          }
        }

        /**
         * getSingle endpoints will most certainly not have these params
         *  "$ref": "#/components/parameters/limit"
         *  "$ref": "#/components/parameters/offset"
         *  "$ref": "#/components/parameters/totalResults"
         *  "$ref": "#/components/parameters/q"
         *  "$ref": "#/components/parameters/orderBy"
         *  Additionally they typically have a path parameter(s) and method is GET
         */
        const pathParamsDef = paramsDef.path;
        if (!canFilter && !canSort && !canPaginate && epDef.method === 'GET' && pathParamsDef) {
          caps.fetchByKeys = {
            implementation: 'lookup',
            multiKeyLookup: 'no',
          };
        }
      }

      return caps;
    }

    // eslint-disable-next-line no-underscore-dangle
    const _requirements = () => ({
      usesResponsesMetadata: false,
    });

    return {
      capabilities: (configuration) => _getCapabilities(configuration),
      requirements: () => _requirements(),
    };
  })();

  tagFunctionsAsBuiltIn(RequestTransforms);
  tagFunctionsAsBuiltIn(ResponseTransforms);
  tagFunctionsAsBuiltIn(MetadataTransforms);

  return {
    request: RequestTransforms,
    response: ResponseTransforms,
    metadata: MetadataTransforms,
  };
});

