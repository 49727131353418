/* eslint-disable max-classes-per-file */

'use strict';

define('vb/private/types/capabilities/fetchFirst',[
  'vb/private/constants',
  'vb/private/types/dataProviderConstants',
  'vbc/private/logConfig',
  'vb/private/log',
  'vb/private/utils',
  'vb/private/types/capabilities/fetchContext',
  'vb/private/types/utils/dataProviderUtils',
  'vbc/private/monitorOptions',
  'vb/private/types/capabilities/noOpFetchFirst'],
(Constants, DPConstants, LogConfig, Log, Utils, FetchContext, DataProviderUtils, MonitorOptions, NoOpFetchFirst) => {
  /**
   * The upper limit of number of rows fetched during iteration.
   * Note: This is only used when size isn't provided and there is continuous iterating of
   * rows required - example with fetchByKeysIteration.
   * @type {number}
   */
  const DEFAULT_ITERATION_LIMIT = -1;

  // for list of supported fetch list parameters - oj.FetchListParameters
  // body is technically not a supported fetchFirst param, but this is needed for Elastic fetches
  // attributes support added in JET 6.1
  const FETCH_FIRST_PARAMS = [
    /**
     * Optional attributes (aka RT filtered fields) to include in the result. If specified,
     * then 'at least' these set of attributes will be included in each row in the data array
     * in the FetchListResult. If not specified then the default attributes will be included.
     * If the value is a primitive then this is ignored.
     * Expressions like "!" and "@default" are also supported. e.g. ['!lastName', '@default'] for
     * everything except 'lastName'. For only 'firstName' and 'lastName' we'd have ['firstName',
     * 'lastName']. Order does not matter when @default is used with field exclusions "!".
     * This can be nested. e.g. ['!lastName', '@default', {name: 'location', attributes:
   * ['address line 1', 'address line 2']}]
     *
     * Examples:
     * For a employee object with a department (1:1 relationship with employee):
     * 1. array of primitives
     * attributes: ['id', 'firstName', 'lastName'] // id, firstName, lastName
     * attributes: ['id', 'firstName', '!lastName', 'email'] // id, firstName, email only
     * attributes: ['id', 'firstName', '!lastName', '@default', 'email'] // all fields except
     *    lastName, which is more than the requested attributes
     * attributes: ['id', 'firstName', '!lastName', 'email', '@default',
     *   { name: 'dept', attributes: [ 'id', 'deptName' ] } ]
     *
     * @type {Array<string|FetchAttribute>|null}
     * @see http://jet.us.oracle.com/6.2.0/tsdocs/oj.FetchListParameters.html
     * @see http://jet.us.oracle.com/6.2.0/tsdocs/oj.FetchAttribute.html
     * @since 19.2.2
     */
    'attributes',

    /*
     * Optional number of rows to fetch starting from offset.
     * @type {number}
     */
    'size',

    /*
     * Optional sort criteria to apply.
     * @type {Array<SortCriterion>|null}
     */
    'sortCriteria',

    /*
     * Optional filter criterion to apply. The filter criterion would be composed of a supported
     * FilterOperator such as a AttributeFilterOperator or a CompoundFilterOperator.
     * @type {FilterOperator|null}
     */
    'filterCriterion',

    /*
     * fetchMetadata is not an officially supported fetchFirst param, but this is needed for
     * Elastic fetches and is temporary until JET updates its contracts. This is a preview
     * API introduced in 19.1.3 and not final.
     * @type {Object|null}
     * @since 19.1.3
     */
    'fetchMetadata',

    /*
     * The AbortSignal from AbortController. Optional. A signal associated with fetchFirst
     * so that this request can be aborted later.
     * @type {AbortSignal|null}
     */
    'signal',
  ];

  /**
   * Struct that stores state per iterator, for the duration of the iteration, on SDP
   * internalState.
   * @private
   */
  const INTERNAL_STATE_PROP = {
    /*
     Stores the filterCriterion provided by fetch*() call. Any previous state is cleared at
     this time and also when the filterCriterion property on variable mutates.
     */
    FILTER_CRITERION: 'filterCriterion',
    /*
     Stores the sortCriteria provided by fetchFirst call. Any previous state is cleared at
     this time and also when the sortCriteria property on variable mutates.
     */
    SORT_CRITERIA: 'sortCriteria',
    /*
     This is cleared on a REFRESH event or when fetchFirst() is called.
     */
    PAGING_CRITERIA: 'pagingCriteria',
    /*
     This is cleared on a REFRESH event or when fetchFirst() is called.
     */
    PAGINATE: 'paginate',
    /*
     * Preview API
     * stores the body merged from fetchParameters and SDP var.
     * @since 18.4.5
     */
    BODY: 'body',

    /*
     * stores the responseType that is determined by reconciling the 'attributes' (from the
     * fetch parameters) with the responseType configured on the SDP. The 'attributes'
     * are the 'select' fields being requested.
     * Primarily responseType is used to 'fix up' incomplete data in REST response and for
     * coercing response data to the type. Fix up is needed so UI components don't barf.
     * With dynUI when a complete responseType cannot be configured on the SDP, any fixing up
     * of missing data needs to be done by page authors, via body transforms for example.
     *
     * a. if 'attributes' is not provided, the responseType set on the SDP will be used as is
     * b. if 'attributes' is provided (dyn UI) then cached RESPONSE_TYPE is set to 'any'.
     *
     * Note: In some cases the attributes structure can differ from the true response type of the
     * data returned by the endpoint, and often dynamic components do not provide the complete
     * response type. Let's take an example:
     *
     * Let's say endpoint fetch returns
     * {
     *   "items": [{
     *     "id": 1,
     *     "account": "1111",
     *     "note": "Black one",
     *     "communicationObject": {
     *       "items": [{
     *         "name": "Soap",
     *         "id": "foo"
     *       }]
     *     }
     *   },
     *   {
     *     "id": 2,
     *     "account": "2222",
     *     "communicationObject": {
     *       "items": []
     *     }
     *   }]
     * }
     *
     * SDP responseType is incomplete (partial) and is set to
     * {
     *   "items": [{
     *     "id": 1,
     *     "account": "1111",
     *     "note": "Black one"
     *   }]
     * }
     *
     * or worse it's set to one of
     *
     * "any"
     * "any[]"
     * "object[]"
     * "object"
     * {
     *   "items": "any[]"
     * }
     *
     * And the attributes being requested via fetch call is specific, and can't be reconciled
     * with the responseType. Example,
     * [
     *   { "name": "id" }, { "name": “account" },
     *   {
     *     "name": "communicationObject",
     *     "attributes": [ { "name": "id" }, { "name": "name" } ]
     *   }
     * ]
     *
     * The cached responseType is set to: "any"
     *
     * We take this approach because there is no way for SDP to get proper type definition
     * for dynamic fields today.
     *
     */
    RESPONSE_TYPE: 'responseType',

    /**
     * stores the attributes provided by fetch call, that is used in the 'select' options of the
     * 'select' transform.
     *
     * a. if attributes is absent in the fetch parameters, then no attributes is cached
     * b. if attributes is provided via fetch call (dyn UI) then attributes being requested is cached.
     *
     */
    SELECT_ATTRIBUTES: 'selectAttributes',

    /*
     * stores the context object passed to the transforms functions for every req/response
     * iteration cycle.
     */
    TRANSFORMS_CONTEXT: 'transformsContext',
  };

  const LOGGER = Log.getLogger('/vb/types/ServiceDataProvider.AsyncIterator', [{
    name: 'custom',
    severity: Constants.Severity.INFO,
    style: LogConfig.FancyStyleByFeature.serviceDataProviderStart,
  }]);

  const isNullOrUndefined = (obj) => obj === undefined || obj === null;

  const UNDEFINED_FILTERCRITERION = { op: undefined, attribute: undefined, value: undefined };

  /**
   * An async iterable class that meets the following contract in Typescript notation.
   * interface AsyncIterable {
   *   [Symbol.asyncIterator]() : AsyncIterator;
   * }
   *
   * Symbol.asyncInterator is part of a stage 3 draft proposal. For details see here:
   * https://tc39.github.io/proposal-async-iteration/
   * @type FetchListAsyncIterable
   */
  class FetchListAsyncIterable {
    constructor(fetchContext) {
      this.asyncIter = fetchContext;

      this[Symbol.asyncIterator] = () => this.asyncIter;
    }
  }

  /**
   * A class that duck types oj.FetchListResult that is set on the IteratorResult which
   * essentially is of the form
   * interface oj.FetchListResult<T, K> {
 *   readonly fetchParameters: FetchListParameters,
 *   readonly data: T[],
 *   readonly metadata: ItemMetadata<K>[]
 * }
   */
  class FetchListResult {
    constructor(fetchParameters, data, metadata, response) {
      this.fetchParameters = fetchParameters;
      this.data = data;
      this.metadata = metadata;
      this.response = response; // response might contain additional data that CCAs will need.
    }
  }

  /**
   * An IteratorResult class, an instance of which is returned from a AsyncIterator.next call.
   *
   * interface IteratorResult {
   *   value: oj.FetchListResult<T, K>;
   *   done: boolean;
   * }
   * @class FetchListAsyncIterator
   */
  class FetchListAsyncIterator {
    constructor(value, done) {
      this.value = value;
      this.done = done;
    }
  }

  /**
   * Fetches data by iterating over a collection. This class represents an async iterator that is returned by invoking
   * fetchFirst()[Symbol.asyncIterator]() on the FetchListAsyncIterable. The contract for an iterator in
   * typescript notation looks like below:
   *
   * interface AsyncIterator {
     *   next() : Promise<IteratorResult>;
     * }
   *
   * Symbol.asyncInterator is a method that is being proposed as part of ES7 stage 3 draft
   * proposal. For details see here:
   * https://tc39.github.io/proposal-async-iteration/
   *
   * @returns {FetchListResult}
   * @constructor
   */
  /* eslint class-methods-use-this: ["error", { "exceptMethods": ["reconcilePaginateOptionsIterationLimit",
   "reconcilePaginateOptionsOffset", "reconcilePaginateOptionsSize"] }] */
  class FetchFirst extends FetchContext {
    constructor(sdp, params) {
      super(sdp, params);
      this.log = LOGGER; // use custom logger
      this.doneIterating = undefined; // whether iterator has reached the end of iteration.
    }

    /**
     * creates a fetchFirst capability instance, that will provide the
     * fetchFirst/asyncIterator.next methods.
     * @static
     */
    static createCapability(sdp, params) {
      return new FetchFirst(sdp, params);
    }

    /**
     *  prune options to just what supported on oj.FetchListParameters; sometimes caller
     *  provides an object with random properties.
     *
     * @param {object=} options Options to control fetch
     * @property {Array=} options.attributes Optional attributes to include in the result.
     * @property {number=} options.size Optional # of rows to fetch starting from offset. If fewer
     *                  than that number of rows exist, the fetch will succeed but be truncated
     * @property {Array=} options.sortCriteria
     * @property {Object=} options.filterCriterion
     * @property {AbortSignal=} options.signal The AbortSignal from AbortController.
     * A signal associated with fetchFirst so that this request can be aborted later.
     * @return {object=}
     */
    whiteListFetchOptions(options) {
      let o;
      if (options) {
        o = {};
        const fp = this.getFetchParams();
        fp.forEach((param) => {
          if (options[param] !== undefined) {
            // allow other falsey values
            o[param] = options[param];
          }
        });
      }
      return o;
    }

    /**
     * List of fetch parameters provided in the fetch call.
     * @returns {string[]}
     */
    getFetchParams() { // eslint-disable-line class-methods-use-this
      return FETCH_FIRST_PARAMS;
    }

    /**
     * The fetch capability this class implements by default. Subclasses must override this
     * method.
     * @returns {string}
     */
    getFetchCapability() { // eslint-disable-line class-methods-use-this
      return DPConstants.CapabilityType.FETCH_FIRST;
    }

    /**
     * Note that the AsyncIterable class takes a single traversal approach where the async iterator it returns is cached
     * and so traverses from where it left off. Generally the caller (i.e.) components cache the iterator and call
     * next() until it's done. Once done, callers have to call SDP.fetchFirst to get a new iterable and a new
     * iterator that starts from the beginning.
     * Re-traveral is supported by Javascript iterables like Set.
     * @return {FetchListAsyncIterable}
     */
    fetch() {
      return new FetchListAsyncIterable(this);
    }

    /**
     * A function that returns a promise for an IteratorResult object. This method is called
     * repeatedly and can be called even after iterator is done. In such cases we guard
     * against noop calls by returning immediately.
     * @returns {Promise} resolves with the result of the next call
     */
    next() {
      const hasMore = !(this.doneIterating === true);
      const { sdp } = this;
      const uniqueId = `${sdp.id} [${this.id}]`;

      if (hasMore === false) {
        const options = this.getFetchOptionsForResponse();
        const finalResult = new FetchListResult(options, [], []);
        const iteratorResult = new FetchListAsyncIterator(finalResult, !hasMore);
        // clear internal state at the last opportunity, and not earlier, even when we know there is no more data to be
        // returned. This is because final state (sort/filter criteria) needs to be still returned to caller!
        this.setInternalState();
        this.log.custom('ServiceDataProvider', uniqueId, 'next() on iterator skipped and is done. Returns result:',
          iteratorResult);

        return Promise.resolve(iteratorResult);
      }

      return Promise.resolve().then(() => {
        sdp.log.startFetch('ServiceDataProvider', uniqueId, 'next() called with options', this.fetchOptions,
          'and state:', this.sdpState);
        const mo = new MonitorOptions(MonitorOptions.SPAN_NAMES.SDP_FETCH_FIRST, uniqueId);
        return sdp.log.monitor(mo, (fetchTime) => FetchContext.prototype.fetch.call(this).then((result) => {
          const iteratorResult = this.buildFetchResult(result);

          // store away the hasMore result for this specific iterator, in internalState in
          // the following cases:
          // (1) when iteratorResult says we are done or
          // (2) when iterator hasMore is undefined, whether we have more results or not, or
          // (3) when iterator hasMore is false, because the data source is saying so
          if (iteratorResult.done
            || (typeof this.hasMore() === 'undefined' || !this.hasMore())) {
            this.markIteratorAsDone();
          }
          sdp.log.endFetch('ServiceDataProvider', uniqueId,
            'fetch succeeded with result:', iteratorResult, fetchTime());

          return (iteratorResult);
        }).catch((err) => {
          // on failure mark iterator as done
          this.markIteratorAsDone();

          FetchContext.logFetchError(this.getFetchCapability(), sdp, err, uniqueId, fetchTime(err));

          // fire a dataProviderNotification event so authors can handle error appropriately
          this.invokeNotificationEvent(Constants.MessageType.ERROR, err);
          if (!this.sdp.isDisconnected()) {
            throw (err);
          } else {
            // return a dummy result because the sdp variable has been disconnected
            return (new NoOpFetchFirst(this.fetchOptions)).next();
          }
        }));
      });
    }

    /**
     * Builds the final IteratorResult expected to be returned by this fetch context (i.e.
     * AsyncIterator).
     * Example components.
     * @param result
     * @returns {FetchListAsyncIterator}
     */
    buildFetchResult(result) {
      let finalResult;
      const options = this.getFetchOptionsForResponse();
      const jsonData = result.body;
      const { itemsPath } = this.sdpState.value;
      // get the array within the results, specified by 'itemsPath'
      const items = DataProviderUtils.getObjectByPath(jsonData, itemsPath);
      const resultMetadata = this.getItemsMetadata(items);
      const hasMore = this.hasMore();
      let done;

      if (typeof hasMore === 'undefined' || hasMore === false) {
        this.log.finer('iterator', this.id, 'returning last result set from fetch call');
        if (items && items.length > 0) {
          done = false;
          finalResult = new FetchListResult(options, items, resultMetadata, jsonData);
        } else {
          done = true;
          finalResult = new FetchListResult(options, [], [], jsonData);
        }
      } else {
        done = false;
        finalResult = new FetchListResult(options, items, resultMetadata, jsonData);
      }

      return new FetchListAsyncIterator(finalResult, done);
    }

    /**
     * Returns an Array of ItemMetadata objects.
     * @param items
     * @private
     */
    getItemsMetadata(items) {
      const idAttr = this.sdpState.value[this.sdp.getIdAttributeProperty()];
      const idHelper = DataProviderUtils.getIdAttributeHelper(idAttr);
      const keys = idHelper.getKeys(items); // keys will be null for no idAttribute or @index

      if (keys && idAttr) {
        if (keys.has(undefined) || keys.size !== items.length) {
          this.log.warn('The keys', keys, 'determined using the key attributes:', idAttr, 'are'
            + ' either not unique or are invalid for the data', items, '. Reverting to using'
            + ' @index as the key! Provide a valid idAttribute or ensure that the data is valid!');
        } else {
          return DataProviderUtils.createFetchByKeysResultItemMetadata(keys);
        }
      }

      this.log.finer(this.sdp.getIdAttributeProperty(), ' is not set. Using index of item as key'
        + ' to build items metadata');

      const pc = this.getInternalState(INTERNAL_STATE_PROP.PAGING_CRITERIA) || {};
      const offset = pc && pc.offset >= 0 ? pc.offset : FetchContext.DEFAULT_OFFSET;
      const indices = idHelper.getIndices(items, offset);
      return DataProviderUtils.createFetchByKeysResultItemMetadata(indices);
      // return indices.map((index) => new FetchListResultItemMetadata(index));
    }

    /**
     * Returns the transforms context object that is part of the snapshot state of the SDP. For
     * the very first fetch call, this value is merged with fetchMetadata passed in via fetch
     * options (parameters).
     *
     * Transforms authors can change the value of the transformsContext so we need to make
     * sure to pass it through to the subsequent next() call.
     * @returns {{}}
     */
    getTransformsContext() {
      const fOpts = this.fetchOptions || {};
      const sdpValue = this.sdpState.value;
      const sdpTransformsContext = Object.assign({}, sdpValue.transformsContext);
      if (fOpts.fetchMetadata) {
        sdpTransformsContext.fetchMetadata = fOpts.fetchMetadata;
      }
      return sdpTransformsContext;
    }

    /**
     *
     * @returns {boolean} true if there is more results to fetch; false if there are no more
     * results or undefined if there is no information.
     */
    hasMore() {
      const pagingInfo = this.getInternalState(FetchContext.RESPONSE_TRANSFORM_TYPE.PAGINATE);
      const hasMore = pagingInfo && pagingInfo.hasMore;
      return typeof hasMore === 'boolean' ? hasMore : undefined;
    }

    /**
     * Called only when iteration is done.
     * @private
     */
    markIteratorAsDone() {
      this.doneIterating = true;
      this.log.finer('iterator', this.id, '\'done\' flag:', this.doneIterating);
    }

    /**
     * Overridden to store filterCriterion from caller to be stored in SDP internalState for
     * subsequent iterations.
     * @returns {*}
     */
    getFilterOptions() {
      let filterCriteria;
      const sdpValue = this.sdpState.value;
      const sdpDefaultValue = this.sdpState.defaultValue;

      if (FetchContext.usePropValue('filterCriteria', sdpValue)
        && sdpDefaultValue.filterCriteria) {
        filterCriteria = sdpValue.filterCriteria || [];
        return filterCriteria;
      }
      // if filterCriterion is provided by caller always use it and cache on iterator,
      // else use the SDP configured value if allowed.

      let filterCriterion;
      const fetchOptionsFC = this.fetchOptions ? this.fetchOptions.filterCriterion : undefined;
      const cachedFilterCriterion = this.getInternalState(INTERNAL_STATE_PROP.FILTER_CRITERION) || {};
      const fcInCache = (Object.keys(cachedFilterCriterion).length > 0);
      const sdpFC = sdpValue.filterCriterion;
      // there are 2 cases to consider:
      // Case 1: implicit SDP fetch case
      // - when fetch parameters (aka fetchOptions) does not have a filterCriterion then return
      // the configured FC, if a criterion has not been previously cached.
      // - if otoh, fetch parameters includes a filterCriterion, then combine this with the FC
      // configured on SDP and cache it.
      // Case 2: SDP with external fetch chain
      // - filterCriterion configured on the SDP is ignored.
      // - if fetch parameters does not have a filterCriterion then cache nothing
      // - if it does then cache it; also see reconcileFilterOptions
      // Note: Subsequent iterations only use the cached criterion
      if (FetchContext.usePropValue('filterCriterion', sdpValue)) {
        if (!fetchOptionsFC || (fetchOptionsFC && Object.keys(fetchOptionsFC).length === 0)) {
          filterCriterion = fcInCache ? cachedFilterCriterion : (sdpFC || {});
        } else {
          filterCriterion = fcInCache ? cachedFilterCriterion
            : DataProviderUtils.combineFilterCriterionFromMultipleSources(fetchOptionsFC, sdpFC);
        }
        if (!fcInCache) {
          this.setInternalState(INTERNAL_STATE_PROP.FILTER_CRITERION, filterCriterion);
        }
      } else {
        // delay storing in cache until we reconcile this with ones coming from restAction. see
        // reconcileFilterOptions
        filterCriterion = fcInCache ? cachedFilterCriterion : fetchOptionsFC;
      }

      return filterCriterion;
    }

    /**
     * builds the paginate options using the SDP state, cached state and fetch call options.
     * @returns {{offset: *, size: *, pagingState: null}}
     */
    getPaginateOptions() {
      // (1) Build pagingCriteria options from fetch call and SDP configuration
      const size = this.getPaginateOptionsSize();
      const offset = this.getPaginateOptionsOffset(size);
      const iterationLimit = this.getPaginateOptionsIterationLimit();

      // cache pagingCriteria
      const transformKey = FetchContext.RESPONSE_TRANSFORM_TYPE.PAGINATE;
      // also pass along any pagingState that user may have set in the previous paginate response.
      // all paging state is cleared in resetInternalState
      const pagingState = (typeof this.getInternalState(transformKey) === 'object')
        ? this.getInternalState(transformKey).pagingState : null;
      const pagingCriteria = {
        offset, size, iterationLimit, pagingState,
      };

      this.setInternalState(INTERNAL_STATE_PROP.PAGING_CRITERIA, pagingCriteria);
      this.log.finer('calling rest with new pagingCriteria:', pagingCriteria);
      return pagingCriteria;
    }

    /**
     * returns the iterationLimit to use. This capability does not use this property.
     * @returns {*}
     */
    getPaginateOptionsIterationLimit() {
      const sdpValue = this.sdpState.value;
      let variablePCIterationLimit;
      if (FetchContext.usePropValue('pagingCriteria', sdpValue)) {
        // use variable value otherwise use defaults.
        const variablePC = sdpValue.pagingCriteria || {};
        variablePCIterationLimit = variablePC.iterationLimit || DEFAULT_ITERATION_LIMIT;
      }
      return variablePCIterationLimit; // can be undefined for externalized fetch
    }

    /**
     * get the size for the paginate transform options. The size is determined as follows:
     * - if size >= 0 provided by fetch call, that gets used
     * - if size is -1 provided by fetch call (implies fetch unlimited rows) then the variable is
     * checked for maxSize. If it is set that is used. Otherwise -1 is used.
     * - if size is not provided by fetch call then the size configured on the SDP variable is
     * used. For external fetches this can be undefined until a later time when this same
     * method is called from reconcileTransformOptions.
     *
     * Subclasses can override this method to have custom behavior for size.
     * @returns {*} size
     */
    getPaginateOptionsSize() {
      const fetchOpts = this.fetchOptions || {};
      const sdpValue = this.sdpState.value;
      const cachedPC = this.getInternalState(INTERNAL_STATE_PROP.PAGING_CRITERIA) || {};

      let variablePCSize;
      let variablePCMaxSize;
      if (FetchContext.usePropValue('pagingCriteria', sdpValue)) {
        // use variable value otherwise use defaults.
        const variablePC = sdpValue.pagingCriteria || {};
        variablePCSize = variablePC.size || FetchContext.DEFAULT_SIZE;
        variablePCMaxSize = variablePC.maxSize;
      }
      let size;
      if (fetchOpts.size >= 0) {
        // for positive size provided by fetch() call if nothing is in cache use it as it wins
        size = cachedPC.size > 0 ? cachedPC.size : fetchOpts.size;
      } else if (fetchOpts.size === -1) {
        // for -1 size if it was determined before and cached in internal state, use it (as
        // it's likely to a positive number)
        if (cachedPC.size > 0) {
          ({ size } = cachedPC);
        } else {
          // see if a maxSize is configured on SDP, if not use -1
          size = variablePCMaxSize || fetchOpts.size;
        }
      } else {
        size = variablePCSize; // can be undefined for externalized fetch
      }

      return size;
    }

    /**
     * get the offset for the paginate transform options.
     * @param size the size used to calculate new offset
     * @returns {*} offset
     */
    getPaginateOptionsOffset(size) {
      let variablePCOffset;
      const fetchOpts = this.fetchOptions || {};
      const sdpValue = this.sdpState.value;

      if (FetchContext.usePropValue('pagingCriteria', sdpValue)) {
        // use variable value otherwise use defaults.
        const variablePC = sdpValue.pagingCriteria || {};
        variablePCOffset = variablePC.offset || FetchContext.DEFAULT_OFFSET;
      }

      let offset;
      const cachedPagingCriteria = this.getInternalState(INTERNAL_STATE_PROP.PAGING_CRITERIA) || {};

      if (fetchOpts.offset === undefined && cachedPagingCriteria.offset >= 0 && size >= 0) {
        // use offset cached from a previous fetch iteration only if offset is not passed in;
        // and a positive size was set
        offset = cachedPagingCriteria.offset + size;
      } else if (fetchOpts.offset >= 0) {
        // caller has set offset in fetch() call, use it
        ({ offset } = fetchOpts);
      } else {
        offset = variablePCOffset; // can be undefined for externalized fetch
      }

      return offset;
    }

    /**
     * returns the responseType for the current fetch context stored in internal state.
     * @overrides
     */
    getResponseType() {
      let cachedRType = this.getInternalState(INTERNAL_STATE_PROP.RESPONSE_TYPE);
      if (!cachedRType) {
        cachedRType = super.getResponseType();
        this.setInternalState(INTERNAL_STATE_PROP.RESPONSE_TYPE, cachedRType);
      }

      return cachedRType;
    }

    /**
     * Returns the select options that has a type structure of fields and optional attributes
     * that need to be fetched based on (i) the attributes provided via the fetch call and the (ii)
     * responseType set on the SDP configuration.
     *
     * Regardless of whether a responseType is set on the SDP, when attributes are specified,
     *   - (a) contextual select options are built using the attributes ignoring responseType
     *   - (b) contextual SDP responseType is set to 'any', ignoring configured responseType. This
     *   is because if responseType is present SDP automatically coerces response to the type,
     *   which is not desirable as we only have partial structure and don't have typeDef
     *   for missing attributes.
     *
     * The final responseTypes are stored in the internalState of iterator.
     * @returns {*} select options type structure
     */
    getSelectOptions() {
      const fetchOpts = this.fetchOptions || {};
      let selectOptions;
      const cachedRType = this.getResponseType();
      const cachedAttributes = this.getInternalState(INTERNAL_STATE_PROP.SELECT_ATTRIBUTES);
      let finalResponseType;
      let finalSOAttrs;

      if (!cachedAttributes && (fetchOpts.attributes && fetchOpts.attributes.length > 0)) {
        finalSOAttrs = fetchOpts.attributes;
        finalResponseType = DPConstants.DEFAULT_ANY_TYPE;
        this.setInternalState(INTERNAL_STATE_PROP.SELECT_ATTRIBUTES, finalSOAttrs);
        this.setInternalState(INTERNAL_STATE_PROP.RESPONSE_TYPE, finalResponseType);
      } else {
        finalResponseType = cachedRType;
        finalSOAttrs = cachedAttributes;
      }

      if (finalResponseType) {
        selectOptions = {
          type: finalResponseType,
          attributes: finalSOAttrs,
        };
      }

      return selectOptions;
    }

    getSortOptions() {
      let sortCriteria;
      const callerSC = this.fetchOptions ? this.fetchOptions.sortCriteria : undefined;
      const sdpValue = this.sdpState.value;
      const cachedSortCriteria = this.getInternalState(INTERNAL_STATE_PROP.SORT_CRITERIA) || [];
      const scInCache = cachedSortCriteria.length > 0;

      if (FetchContext.usePropValue('sortCriteria', sdpValue)
        && (!callerSC || callerSC.length === 0)) {
        sortCriteria = scInCache ? cachedSortCriteria : (sdpValue.sortCriteria || []);
      } else if (callerSC && callerSC.length > 0) {
        sortCriteria = scInCache ? cachedSortCriteria : callerSC;
        this.setInternalState(INTERNAL_STATE_PROP.SORT_CRITERIA, sortCriteria);
      }
      return sortCriteria;
    }

    /**
     * Uses either the cached body in internal state, when present, or use the body set via
     * configuration. At the start of a new fetch iterator we cache the body set via
     * configuration.
     * @returns {*}
     */
    getBodyOptions() {
      let body;
      const sdpValue = this.sdpState.value;
      const cachedBody = this.getInternalState(INTERNAL_STATE_PROP.BODY);

      if (FetchContext.usePropValue('body', sdpValue) && !cachedBody) {
        ({ body } = sdpValue);
        this.setInternalState(INTERNAL_STATE_PROP.BODY, body);
      } else {
        body = cachedBody;
      }
      return body;
    }

    /**
     * Reconcile transform options determined by the fetch capability, with options from
     * other sources. At the moment the RestAction is the only other source this could come from.
     *
     * @param transformOptions transform options as determined from fetch call and SDP defaults.
     * @param restTransformOptions transform options from rest
     *
     * @return {*} reconciled options
     */
    reconcileTransformOptions(transformOptions, restTransformOptions) {
      const reconciledOptions = super.reconcileTransformOptions(transformOptions, restTransformOptions);

      // (1) fix up pagination options employing simple heuristics to reconcile with options
      // coming from other sources
      const tpo = transformOptions.paginate;
      const otpo = restTransformOptions.paginate;
      const recpo = this.reconcilePaginateOptions(tpo, otpo);

      if (recpo) {
        reconciledOptions.paginate = recpo;
      }

      // (2) fix up filterCriterion from caller with one from rest using simple AND combining
      const tofc = transformOptions.filter;
      const rtofc = restTransformOptions.filter;
      const retoFC = this.reconcileFilterOptions(tofc, rtofc);

      if (retoFC) {
        reconciledOptions.filter = retoFC;
      }

      // (3) sort, query options can be arbitrarily complex and it's not clear what simple heuristic can be used to
      // merge. Instead page authors can use the mergeTransformOptions property to decide how to merge.

      return reconciledOptions;
    }

    /**
     * Reconcile the filterCriterion by AND-ing the filter options determined so far with the one
     * configured on the REST configuration. This is the first time we get to combine the
     * filter options set on the RESTAction configuration with what SDP has determined so far.
     * @param filterOptions
     * @param restFilterOptions
     */
    reconcileFilterOptions(filterOptions, restFilterOptions) {
      const cachedFC = this.getInternalState(INTERNAL_STATE_PROP.FILTER_CRITERION);
      const fcInCache = (cachedFC && Object.keys(cachedFC).length >= 0);

      if (fcInCache) {
        return cachedFC;
      }

      let filterCriterion;
      const hasFilterOptions = filterOptions && Object.keys(filterOptions).length > 0;
      const hasRestFilterOptions = restFilterOptions && Object.keys(restFilterOptions).length > 0;

      if (hasRestFilterOptions) {
        // we need to update the cache appropriately but first check if we need to combine with
        // filter options coming from caller
        if (hasFilterOptions) {
          filterCriterion = DataProviderUtils.combineFilterCriterionFromMultipleSources(filterOptions,
            restFilterOptions);
        } else {
          filterCriterion = restFilterOptions;
        }
      } else {
        filterCriterion = hasFilterOptions ? filterOptions : UNDEFINED_FILTERCRITERION;
      }

      this.setInternalState(INTERNAL_STATE_PROP.FILTER_CRITERION, filterCriterion);
      // refetch from internalState again because often value is cloned
      return this.getInternalState(INTERNAL_STATE_PROP.FILTER_CRITERION);
    }

    /**
     * Reconcile the paginate options coming from 2 sources.
     * @param paginateOptions - paginate options determined from fetch call and SDP defaults.
     * @param restPaginateOptions - paginate options as defined on the RestAction. For
     * externalized fetch this is relevant to consider in the final paginate options.
     *
     * @returns {*} the merged result
     */
    reconcilePaginateOptions(paginateOptions, restPaginateOptions) {
      let cpo = this.getInternalState(INTERNAL_STATE_PROP.PAGING_CRITERIA) || {};
      let updateCache = false;

      const changedSize = this.reconcilePaginateOptionsSize(paginateOptions, restPaginateOptions);
      if (!isNullOrUndefined(changedSize)
        && (!isNullOrUndefined(cpo.size) || changedSize !== cpo.size)) {
        cpo.size = changedSize;
        updateCache = true;
      }

      const changedOffset = this.reconcilePaginateOptionsOffset(paginateOptions, restPaginateOptions);
      if (!isNullOrUndefined(changedOffset)
        && (!isNullOrUndefined(cpo.offset) || changedOffset !== cpo.offset)) {
        cpo.offset = changedOffset;
        updateCache = true;
      }

      const changedIterationLimit = this.reconcilePaginateOptionsIterationLimit(paginateOptions, restPaginateOptions);
      if (!isNullOrUndefined(changedIterationLimit)
        && (!isNullOrUndefined(cpo.iterationLimit) || changedIterationLimit !== cpo.iterationLimit)) {
        cpo.iterationLimit = changedIterationLimit;
        updateCache = true;
      }

      if (updateCache) {
        // anytime a variable property is directly mutated it's important to re-get the value
        // because old references get stale
        cpo = this.getInternalState(INTERNAL_STATE_PROP.PAGING_CRITERIA);
        // any time offset or size is updated when reconciling with other options update
        // cached pagingCriteria. This is needed for the next iteration.
        this.setInternalState(INTERNAL_STATE_PROP.PAGING_CRITERIA, cpo);
        this.log.finer('calling rest with updated pagingCriteria:', cpo);
      }

      return cpo;
    }

    /**
     * returns a valid size -1, 0 or positive number if size was changed. Otherwise undefined.
     * @param paginateOptions
     * @param restPaginateOptions
     * @returns {*} changed size or undefined if there is no change
     */
    reconcilePaginateOptionsSize(paginateOptions, restPaginateOptions) { // eslint-disable-line class-methods-use-this
      let changed;
      const tpo = paginateOptions;
      const rtpo = restPaginateOptions;

      // if size is not set on paginateOptions then,
      // - use size set on rest,
      // - otherwise use default size.
      // if size is -1 then, caller explicitly wants unlimited rows
      // - use maxSize over size from rest if present.
      if (!tpo.size && tpo.size !== 0) {
        changed = (rtpo && rtpo.size && rtpo.size >= 0) ? rtpo.size : FetchContext.DEFAULT_SIZE;
      } else if (tpo.size === -1 && (rtpo && rtpo.maxSize > 0)) {
        changed = rtpo.maxSize;
      }

      // size: -1 is problematic because starting in JET 6.0 iterator next() is called
      // repeatedly with size: -1 by listview so it fetches until done. If we don't set a
      // size, even though transforms sets a default size the value cached in SDP internal
      // state is -1. this causes an infinite fetch loop
      if (!changed && tpo.size === -1) {
        changed = FetchContext.DEFAULT_MAX_SIZE;
      }

      return changed;
    }

    /**
     * returns a valid offset 0 or positive number if changed. Otherwise undefined.
     * @param paginateOptions
     * @param restPaginateOptions
     * @returns {*} changed offset or undefined if there is no change
     */
    reconcilePaginateOptionsOffset(paginateOptions, restPaginateOptions) {
      let changed;
      const tpo = paginateOptions;
      const rtpo = restPaginateOptions;

      // if offset is not set on paginateOptions then,
      // - use offset set on rest options,
      // - otherwise use default offset.
      if (!tpo.offset && tpo.offset !== 0) {
        changed = rtpo && rtpo.offset >= 0 ? rtpo.offset : FetchContext.DEFAULT_OFFSET;
      }

      return changed;
    }

    reconcilePaginateOptionsIterationLimit(paginateOptions, restPaginateOptions) {
      let changed;
      const tpo = paginateOptions;
      const rtpo = restPaginateOptions;

      // if iterationLimit is not set on paginateOptions then,
      // - use iterationLimit set on rest options,
      // - otherwise use default iterationLimit -1.
      if (!tpo.iterationLimit) {
        changed = rtpo && rtpo.iterationLimit > 0 ? rtpo.iterationLimit : DEFAULT_ITERATION_LIMIT;
      }

      return changed;
    }

    /**
     * Processes the response transform results and looks for known properties (like
     * PAGINATE), and saves off the transform results into the internal state of the variable
     * instance.
     * The 'paginate' response transform result has totalSize, hasMore and pagingState.
     * pagingState, if returned from a response transform function, is held in the internal
     * state and then later passed to the subsequent request transform functions.
     * @param transformResults
     * @return Promise that resolves when the responsetransforms has been processed
     * @private
     */
    processResponseTransforms(transformResults) {
      try {
        // BUFP-32494, when a page that has outstanding REST requests (initiated by SDP) is
        // navigated away by user, the SDP variables are gone. This code needs to guard
        // against this from outstanding RestHelper response transforms processing calls
        if (transformResults && Object.keys(transformResults).length > 0) {
          return super.processResponseTransforms(transformResults).then(() => {
            // store everything except body in internal state
            Object.values(FetchContext.RESPONSE_TRANSFORM_TYPE).forEach((transformKey) => {
              if (transformKey === FetchContext.RESPONSE_TRANSFORM_TYPE.PAGINATE) {
                this.setInternalState(transformKey, transformResults[transformKey]);
              }
            });

            // set the totalSize on the SDP property. This is the canonical size of the endpoint
            // when no search / filter criteria is applied. IOW this value is meant to be the same
            // every time a fetch is called. it's ok to set the totalSize on the SDP instance for
            // this reason.
            // Only set size when there is a change - this is because components call fetch
            // repeatedly for scrolling and the same value for totalSize gets set, causing an
            // unnecessary variable writes and queuing of the event (only to be discarded when
            // event is about to fire - variable.js)
            const ts = this.totalSize();

            return this.sdp.getTotalSize().then((sdpSize) => {
              if (ts !== sdpSize) {
                this.sdp.totalSize = ts;
                // update the live SDP instance with the totalSize - this is the only time the
                // actual SDP value is directly mutated by VB. All other times mutations to SDP
                // variable is done by page author.
                // Also update the snapshot state to keep it in sync
                this.sdpState.value.totalSize = ts;
                this.sdp.setTotalSize(ts);
                const uniqueId = `${this.sdp.id} [${this.id}]`;
                this.log.finer('ServiceDataProvider', uniqueId, 'canonical totalSize updated:', ts);
              }
            });
          });
        }
        return Promise.resolve();
      } catch (e) {
        // do nothing
        return Promise.resolve();
      }
    }

    /**
     * Return the canonical total size of data available as provided by paginate response transform.
     *
     * @returns {number} total size of data
     * @instance
     */
    totalSize() {
      const pagingInfo = this.getInternalState(FetchContext.RESPONSE_TRANSFORM_TYPE.PAGINATE);
      // when server explicitly states there are no records then return that instead of the unknown size
      return (pagingInfo && pagingInfo.totalSize >= 0) ? pagingInfo.totalSize : FetchContext.DEFAULT_TOTAL_SIZE;
    }

    /**
     * Return the iterationLimit set on SDP fetch context or external context.
     *
     * @returns {number} -1 or a positive number
     * @instance
     */
    getIterationLimit() {
      const pagingOptions = this.getInternalState(INTERNAL_STATE_PROP.PAGING_CRITERIA);
      return (pagingOptions && pagingOptions.iterationLimit) || DEFAULT_ITERATION_LIMIT;
    }
  }

  return FetchFirst;
});

