'use strict';

define('vb/services/transforms/serviceTransformsConstants',[], () => {
  const deepFreeze = (obj) => {
    Object.keys(obj).forEach((prop) => {
      if (typeof obj[prop] === 'object') {
        deepFreeze(obj[prop]);
      }
    });
    return Object.freeze(obj);
  };

  const TransformsConstants = {};

  /**
   * List of top level properties supported on fetchByKeys fetch parameters by JET.
   * @type {string[]}
   */
  TransformsConstants.FetchParamsForFetchByKeys = ['attributes', 'keys'];

  /**
   * List of top level properties supported on fetchFirst fetch parameters by JET.
   * @type {string[]}
   */
  TransformsConstants.FetchParamsForFetchFirstCommon = ['attributes', 'filterCriterion', 'size', 'sortCriteria'];

  /**
   * List of top level properties supported on fetchFirst fetch parameters that also includes the Elastic parameter.
   * @type {string[]}
   */
  TransformsConstants.FetchParamsForFetchFirst = [
    ...TransformsConstants.FetchParamsForFetchFirstCommon, 'fetchMetadata'];

  /**
   * List of top level properties supported on fetchByOffset fetch parameters by JET.
   * @type {string[]}
   */
  TransformsConstants.FetchParamsForFetchByOffset = [...TransformsConstants.FetchParamsForFetchFirstCommon, 'offset'];

  // Every other top-level property is a primitive (size) or a Collection of primitives (keys) or Object literal.
  // Properties that might be instances of specific types are listed below

  /**
   * list of all allowed top level properties on 'filterCriterion' fetchParameter. A combination of these
   * properties will be available based on the type of filterCriterion. For details refer to the JSDOC references
   * listed below. These properties are passed to all transforms function, via the configuration.fetchParameters.
   * Components can provide first class instances at various levels that are a combination of the listed properties (in
   * addition to methods).
   * Only the properties listed here will be extracted before cloning to send to the transforms function.
   * @see https://jet.oraclecorp.com/jsdocs/DataFilter.html#Filter
   */
  TransformsConstants.filterCriterion = {
    properties: [
      'collationOptions', // only supported by boss transforms
      'op',
      'attribute',
      'value', // can be a string or object with { attribute-name: value }
      'criteria',
      'criterion', // only supported by boss transforms
      'text',
      'matchBy'],
    propertyTypes: {
      collationOptions: 'filterCriterion.collationOptions',
      criteria: 'filterCriterion', // array of objects with props similar to filterCriterion
      criterion: 'filterCriterion', // object with prop similar to filterCriterion
    },
  };

  TransformsConstants['filterCriterion.collationOptions'] = {
    properties: [
      'sensitivity', // only supported by boss transforms
    ],
  };

  /**
   * list of all allowed to level properties on 'attributes' fetchParameter. These properties are passed to all
   * transforms function, via the configuration.fetchParameters.
   * Components can send an Array<String> or Array<FetchAttribute> that may have one or more of the properties
   * listed, and can include methods as well.
   * Only the properties listed here will be extracted before cloning to send to the transforms function.
   * @see https://jet.oraclecorp.com/jsdocs/FetchAttribute.html
   */
  TransformsConstants.attributes = {
    properties: ['name', 'attributes'],
    propertyTypes: {
      attributes: 'attributes',
    },
  };

  /**
   * list of all allowed properties on 'sortCriteria' fetchParameter. These properties are allowed in each item of
   * sortCriteria. This is passed to all transforms function, via the configuration.fetchParameters.
   * Components can send an Array<SortCriterion> where each item that may have one or more of the properties listed,
   * and can include methods as well.
   * Only the properties listed here will be extracted before cloning to send to the transforms function.
   * @see https://jet.oraclecorp.com/jsdocs/SortCriterion.html
   */
  TransformsConstants.sortCriteria = {
    properties: ['attribute', 'direction'],
  };

  TransformsConstants.FetchParamTypes = {
    fetchByKeys: 'FetchParamsForFetchByKeys',
    fetchFirst: 'FetchParamsForFetchFirst',
    fetchByOffset: 'FetchParamsForFetchByOffset',
  };

  /**
   * List of top level properties supported on fetchFirst transforms options. Transforms options are setup
   * specifically for transforms functions and are available in its entirety using
   * fetchConfiguration.transformsOptions and also as the specific options for each transforms function. Example for a
   * filter transform function with this signature
   *   filter(config, options, transformsContext)
   * - config.fetchConfiguration.transformsOptions will return the map of all transforms options by the below key
   * - options will just be the filter transforms options
   * @type {string[]}
   */
  TransformsConstants.TransformsOptionsForFetchCommon = ['filter', 'keys', 'paginate', 'query', 'select', 'sort'];

  /**
   * list of all allowed top level properties on 'filter' transforms options. A combination of these
   * properties will be available based on the type of filterCriterion. For details refer to the JSDOC references
   * listed below.
   * Components can provide first class instances at various levels that are a combination of the listed properties (in
   * addition to methods).
   * Only the properties listed here will be extracted and cloned into the options, before provided to transforms
   * function.
   * @see https://jet.oraclecorp.com/jsdocs/DataFilter.html#Filter
   */
  TransformsConstants.filter = {
    properties: [
      'collationOptions', // only supported by boss transforms
      'op',
      'attribute',
      'value', // can be a string or object with { attribute-name: value }
      'criteria',
      'criterion', // only supported by boss transforms
      'text',
      'matchBy'],
    propertyTypes: {
      collationOptions: 'filter.collationOptions',
      criteria: 'filter', // array of objects with props similar to filterCriterion
      criterion: 'filter', // object with prop similar to filterCriterion
    },
  };

  TransformsConstants['filter.collationOptions'] = {
    properties: [
      'sensitivity', // only supported by boss transforms
    ],
  };

  /**
   * List of properties allowed with paginate transforms options
   * @type {{properties: string[]}}
   */
  TransformsConstants.paginate = {
    properties: ['iterationLimit', 'offset', 'pagingState', 'size'],
  };

  /**
   * List of properties allowed with select transforms options
   * @type {{propertyTypes: {attributes: string}, properties: string[]}}
   */
  TransformsConstants.select = {
    properties: ['type', 'attributes'],
    propertyTypes: {
      attributes: 'select.attributes',
    },
  };

  TransformsConstants['select.attributes'] = {
    properties: ['name', 'attributes'],
    propertyTypes: {
      attributes: 'select.attributes',
    },
  };

  /**
   * List of properties allowed on sort transforms options
   * @type {{properties: string[]}}
   */
  TransformsConstants.sort = {
    properties: ['attribute', 'direction'],
  };

  return deepFreeze(TransformsConstants);
});

