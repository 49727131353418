'use strict';

define('vb/private/stateManagement/context/fragmentContext',[
  'vb/private/constants',
  'vb/private/stateManagement/context/containerContext',
  'vb/private/stateManagement/context/fragmentBaseContext',
  'vb/helpers/componentFinder'],
(Constants, ContainerContext, FragmentBaseContext, ComponentFinder) => {
  /**
   * set of properties to expose in $fragment
   */
  class FragmentContext extends ContainerContext {
    /**
    * @param {Fragment} frag fragment instance
    */
    constructor(frag) {
      super(frag);

      /**
       * $fragment.info
       */
      const propDescriptors = {
        [Constants.INFO_CONTEXT]: {
          get() {
            return frag.scope.variableNamespaces[Constants.VariableNamespace.BUILTIN][Constants.INFO_CONTEXT];
          },
          enumerable: true,
          configurable: true,
        },
      };

      const accessors = {
        [Constants.COMPONENTS_CONTEXT]: () => ComponentFinder, // VBS-25776, cannot remove this because FA uses it
      };

      Object.keys(accessors).forEach((accessorName) => {
        propDescriptors[accessorName] = {
          get: accessors[accessorName],
          enumerable: true,
          configurable: true,
        };
      });

      Object.defineProperties(this, propDescriptors);
    }

    static get BaseContextType() {
      return FragmentBaseContext;
    }

    /**
     * see ContainerContext. Returns all available contexts within a fragment. At this point it's just what the
     * containerContext provides in addition to $fragment
     *
     * @param fragment
     * @returns {{$application: *, $variables, $metadata, $fragment}}
     */
    static getAvailableContexts(fragment) {
      const availableContexts = super.getAvailableContexts(fragment);

      Object.defineProperties(availableContexts, {
        // everything from here down used to only be created when the Page's Scope was created
        // now, we create the object once, up front, and rely on getters to allow deferred assignment
        // of the expressionContext.  But, because Expression reads all the values, regardless of what is used
        // in the current expression, the properties may be read before expressionContext is created,
        // so we guard against expressionContext being null.  Should not be a problem,
        // since the null pre-Scope values cannot be meaningfully referenced anyway.
        $fragment: {
          enumerable: true,
          configurable: true,
          get: () => fragment.expressionContext,
        },
      });

      // we need this because parent is going through package
      if (fragment.extension) {
        // Remove properties added by container context that should not be part of the fragment context.
        // $application in package refers to appUI that fragment cannot access
        if (fragment.extension.id !== Constants.ExtensionFolders.BASE) {
          delete availableContexts.$application;
        }
      }

      // this is needed to allow fragment event to bubble up to oj-vb-fragment CCA. '$dispatchEvent' is a mechanism
      // setup by dynamic components to be notified of events fired from layout. The same mechanism is used with
      // fragment. When this method is called we are in the context of the fragment, and the event is fired on
      // element which is rendered on parent context
      Object.defineProperty(availableContexts, Constants.ContextNameInternal.DISPATCH_EVENT, {
        enumerable: true,
        configurable: true,
        value: (event) => {
          // for some reason dispatching event on element from the inner context is required.
          const elem = document.getElementById(fragment.id);
          elem.dispatchEvent(event);
        },
      });

      return availableContexts;
    }
  }

  return FragmentContext;
});

