'use strict';

define('vb/private/services/boss/metadataTransforms',[
  'vb/private/services/boss/transformsUtils',
  'vb/private/types/dataProviderConstants',
  'vb/private/services/boss/bossTransformsConstants'],
(TransformsUtils, DPConstants, BTConstants) => {
  /**
   * Metadata Transforms pertaining to a service or endpoint.
   */
  class MetadataTransforms {
    /**
     * Returns the capabilities
     *
     * @param configuration
     * @return {{}}
     * @private
     */
    static capabilities(configuration) {
      const caps = {};

      const epDef = configuration.endpointDefinition;
      const responses = epDef.responses;

      // Get 'properties' from responses
      const schemaProperties = TransformsUtils.getPropertiesFromResponse(responses);
      const [properties] = (schemaProperties
          && TransformsUtils.searchProperty(schemaProperties, BTConstants.Common.PROPERTIES)) || [{}];

      // Component parameters
      const paramsDef = epDef && epDef.componentParameters;
      if (paramsDef) {
        // Read 'sortBy' property from the component parameters
        const sortByDef = TransformsUtils.getKeyValue(paramsDef, BTConstants.CapabilityType.SORT_BY);
        // Check if sort is supported by the endpoint
        if (sortByDef) {
          const oVal = sortByDef.value;
          if (oVal && oVal.in === BTConstants.CapabilityValues.IN_QUERY) {
            // Include all the fields that are sortable in 'attributes'
            caps.sort = {
              attributes: Object.keys(properties)
                .filter((e) => !(properties[e][BTConstants.CapabilityValues.ATTRIBUTE_X_SORTABLE_PROP] === false)),
            };
          }
        }

        // Read 'filter' property from the Component parameters
        const filterDef = TransformsUtils.getKeyValue(paramsDef, BTConstants.CapabilityType.FILTER);
        // Check if filter is supported by the endpoint
        if (filterDef) {
          const oVal = filterDef.value;
          if (oVal && oVal.in === BTConstants.CapabilityValues.IN_QUERY) {
            // Include all the fields that are searchable in 'attributes'
            caps.filter = {
              operators: DPConstants.CapabilityValues.FILTER_OPERATORS_OPS,
              textFilter: false,
              attributes: Object.keys(properties)
                .filter((e) => !(properties[e][BTConstants.CapabilityValues.ATTRIBUTE_X_SEARCHABLE_PROP] === false)),
            };
            // To support fetching multiple keys
            caps.fetchByKeys = {
              implementation: BTConstants.CapabilityValues.FETCH_BY_KEYS_IMPLEMENTATION_LOOKUP,
              multiKeyLookup: BTConstants.CapabilityValues.FETCH_BY_KEYS_MULTI_KEY_LOOKUP_YES,
            };
          }
        }

        // Read 'limit' property from the Component parameters
        const paginateDef = TransformsUtils.getKeyValue(paramsDef, BTConstants.CapabilityType.LIMIT);
        // Check if paginate is supported by the endpoint
        if (paginateDef) {
          const oVal = paginateDef.value;
          if (oVal && oVal.in === BTConstants.CapabilityValues.IN_QUERY) {
            caps.fetchFirst = {
              implementation: BTConstants.CapabilityValues.FETCH_FIRST_IMPLEMENTATION_ITERATION,
            };
            // if the offset query param is present then there is fetchByOffset
            const offsetDef = TransformsUtils.getKeyValue(paramsDef, BTConstants.CapabilityType.OFFSET);
            if (offsetDef) {
              const offsetVal = offsetDef.value;
              if (offsetVal && offsetVal.in === BTConstants.CapabilityValues.IN_QUERY) {
                caps.fetchByOffset = {
                  implementation: DPConstants.CapabilityValues.FETCH_BY_OFFSET_IMPLEMENTATION_RANDOM_ACCESS,
                };
              }
            }
          }
        }
      }

      return caps;
    }

    static requirements() {
      return {
        usesResponsesMetadata: true,
      };
    }

    /**
     * A function to create a Metadata transform object
     *
     * @returns {{capabilities: *, requirements: *}} an object representing the Metadata transforms
     */
    static toObject() {
      return {
        capabilities: MetadataTransforms.capabilities,
        requirements: MetadataTransforms.requirements,
      };
    }
  }

  return MetadataTransforms;
});

