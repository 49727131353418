'use strict';

define('vb/private/action/actionChainUtils',['vb/private/constants', 'vb/private/log', 'vbc/private/logConfig'],
  (Constants, Log, LogConfig) => {
    class ActionChainUtils {
      static getLogger() {
        return Log.getLogger('/vb/action/actionChain', [
          // Register custom loggers
          {
            name: 'startChain',
            severity: Constants.Severity.INFO,
            style: LogConfig.FancyStyleByFeature.actionChainStart,
          },
          {
            name: 'endChain',
            severity: Constants.Severity.INFO,
            style: LogConfig.FancyStyleByFeature.actionChainEnd,
          },
        ]);
      }

      /**
       * Returns the internal context object nested in the internal context of the executionContext
       *
       * @param executionContext execution context for the action chain
       * @returns {Object}
       */
      static getInternalContext(executionContext) {
        return executionContext[Constants.CHAIN_INTERNAL_CONTEXT];
      }

      /**
       * Return a writable (unproxied) version of the executionContext.
       *
       * @param executionContext execution context for the action chain
       * @returns {Object}
       */
      static getWritableExecutionContext(executionContext) {
        return this.getInternalContext(executionContext).writableContext || executionContext;
      }

      /**
       * Returns the debug stream from the internal context of the executionContext
       *
       * @param executionContext execution context for the action chain
       * @returns {ActionChainDebugStream}
       */
      static getDebugStream(executionContext) {
        return this.getInternalContext(executionContext).debugStream;
      }

      /**
       * Returns true when the container is inactive.
       *
       * @param containerLifecycleState lifecycle state of the container
       * @returns {boolean}
       */
      static isContainerInactive(containerLifecycleState) {
        return containerLifecycleState === Constants.ContainerState.EXITED ||
          containerLifecycleState === Constants.ContainerState.DISPOSED;
      }
    }

    return ActionChainUtils;
  });

