'use strict';

define('vb/extensions/dynamic/private/models/dataDescription',['vb/extensions/dynamic/private/models/layoutResource'], (LayoutResource) => {
  /**
   * DataDescription is used to load data-description related files.
   */
  class DataDescription extends LayoutResource {
    /**
     * DataDescription constructor
     *
     * @param container the container that contains this layout resource
     * @param extension the extension from which to load the resource
     * @param {String} path the absolute path to the layout resource, e.g., vb/extA/dynamicLayouts/self/employee
     * @param {Object} resourceDescriptor resource descriptor
     * @param {String} className the class name
     */
    constructor(container, extension, path, resourceDescriptor, className = 'DataDescription') {
      super(container, extension, path, resourceDescriptor, className);
    }

    /**
     * The data-description.json is loaded separately from the rest of the layout resources
     * so we override descriptorLoader to simply return an empty descriptor.
     *
     * @param resourceLocator
     * @returns {Promise}
     */
    // eslint-disable-next-line class-methods-use-this,no-unused-vars
    descriptorLoader(resourceLocator) {
      return Promise.resolve({});
    }
  }

  return DataDescription;
});

