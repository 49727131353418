'use strict';

define('vb/private/stateManagement/context/flowBaseContext',[
  'vb/private/stateManagement/context/baseContext',
  'vb/private/constants'],
(BaseContext, Constants) => {
  /**
   * set of properties to expose in $base for flow extension
   */
  class FlowBaseContext extends BaseContext {
    constructor(flow) {
      super(flow);

      const flowContext = flow.expressionContext;

      const propDescriptors = {};

      [Constants.CURRENT_PAGE_VARIABLE, Constants.INFO_CONTEXT, Constants.PATH_VARIABLE].forEach((property) => {
        propDescriptors[property] = {
          get() {
            return flowContext[property];
          },
          enumerable: true,
          configurable: true,
        };
      });

      Object.defineProperties(this, propDescriptors);
    }

    /**
     * $base.flow
     */
    get flow() {
      return this;
    }
  }

  return FlowBaseContext;
});

