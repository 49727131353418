'use strict';

/**
 * the versions.json is generated into generated/vb folder, and requires "writefile:versionInfo" grunt task to generate.
 */
define('vb/versions',['text!vb/versions/resources/versions.json'], (versions) => {
  const vbVersions = JSON.parse(versions);

  globalThis.vb = globalThis.vb || {};
  Object.assign(globalThis.vb, vbVersions.visualDevelopmentPlatform);
  globalThis.vb.jet = vbVersions.jet;
  globalThis.vb.workbox = vbVersions.workbox;

  return vbVersions;
});

